import { Box, Grid, MenuItem, Select, TextField, Modal, Button, Autocomplete, Checkbox } from "@mui/material";
import React, { useState } from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
    phelboLoginId: any;
    setPhelboLoginId: any;
    phelboLoginPassword: any;
    setPhelboLoginPassword: any;
    manualAddLoginId: any;
    setManualAddLoginId: any;
    manualAddLoginPassword: any;
    setManualAddLoginPassword: any;
    // error: any;
    // setError: any;
}
const AssignPhelbo: React.FC<Props> = ({
    phelboLoginId,
    setPhelboLoginId,
    phelboLoginPassword,
    setPhelboLoginPassword,
    manualAddLoginId,
    setManualAddLoginId,
    manualAddLoginPassword,
    setManualAddLoginPassword
    // error,
    // setError,
}) => {
    const [open, setOpen] = useState<any>(false)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const handleSelectChange = (e: any) => {
        setPhelboLoginId(e.target.value)
        if (e.target.value == 'Manually') {
            setOpen(true)
        }
        else {
            setOpen(false)
        }
    }
    const handleLoginIdSubmit = () => {
        setPhelboLoginId('')
        setOpen(false)
    }
    const options=[
 {
    label:'Option1',value:'Option1'
 },
 {
    label:'Option2',value:'Option2'
 }
    ]
    return (
        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            {/* <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={{ position: 'relative', top: '40%', left: '35%', width: 400, height: 200, backgroundColor: 'white', borderRadius: '10px', boxShadow: '2px 2px 12px 2px #00000026', border: '0px', padding: '10px' }}>
                  <form onSubmit={handleLoginIdSubmit}>
                  <TextField
                        className="input"
                        name="assignPhelboid"
                        type="text"
                        label="Login Id"
                        value={manualAddLoginId}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        // helperText={error} // error message
                        // error={!!error}
                        onChange={(e) => setManualAddLoginId(e.target.value)}
                        required
                    />
                    <Button type="submit" variant="contained">SAVE</Button>
                  </form>
                </Box>
            </Modal> */}
            <h4>Assign Phelbo</h4>
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                spacing={3}
                style={{ margin: "1rem auto", background: "#eff" }}
            >
                <Grid item md={12}>
                    <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let packs: any = [];
                                newValue.map((val: any) => {
                                    let obj = JSON.parse(
                                        JSON.stringify(val, null, " ")
                                    );
                                    packs.push(obj);
                                });
                                let len = packs.length;
                                // handlePackage(index, packs, packs[len - 1]);
                            }
                            // setCValue("")
                        }}
                        options={options}
                        // options={packageDetails && packageDetails?.results || []}
                        disableCloseOnSelect
                        // getOptionLabel={(option: any) => option?.test_name}
                        // onInputChange={(event, newInputValue) => {
                        //     clearTimeout(timer.current);
                        //     setPincode(newInputValue);
                        //     if(newInputValue!=="")
                        //     getPackageDetails(`?pincode=${newInputValue}`)

                        // }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {/* {option.test_name} */}
                            </li>
                        )}
                        style={{ width: "100%", margin: "0" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Phelbo Name"
                            />
                        )}
                    />
                    {/* <Select
                        className="input"
                        variant="outlined"
                        style={{ width: "100%", margin: "0" }}
                        value={phelboLoginId}
                        onChange={handleSelectChange}
                    >
                        <MenuItem disabled>
                        select Phelbo
                        </MenuItem>
                        <MenuItem value="Random">
                            Random
                        </MenuItem>
                        <MenuItem value="Manually">
                            Manually
                        </MenuItem>
                    </Select> */}
                </Grid>
                <Grid item md={6}>
                    {/* <Select
                        className="input"
                        variant="outlined"
                        style={{ width: "100%", margin: "0" }}
                        value={phelboLoginId}
                        onChange={handleSelectChange}
                    >

                        <MenuItem value="Random">
                            Random
                        </MenuItem>
                        <MenuItem value="Manually">
                            Manually
                        </MenuItem>
                    </Select> */}

                    <TextField
                        className="input"
                        name="assignPhelboid"
                        type="text"
                        label="Login Id"
                        value={phelboLoginId}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        // helperText={error} // error message
                        // error={!!error}
                        onChange={(e) => setPhelboLoginId(e.target.value)}
                        required
                        disabled
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        className="input"
                        name="assignPhelbopassword"
                        type="text"
                        label="Password"
                        value={phelboLoginPassword}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        // helperText={error} // error message
                        // error={!!error}
                        onChange={(e) => setPhelboLoginPassword(e.target.value)}
                        required
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default AssignPhelbo;