import { Box, Grid, MenuItem, Select, TextField, Modal, Button, Autocomplete, Checkbox } from "@mui/material";
import React, { useState } from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
    phelboLoginId: any;
    setPhelboLoginId: any;
    phelboLoginPassword: any;
    setPhelboLoginPassword: any;
    manualAddLoginId: any;
    setManualAddLoginId: any;
    manualAddLoginPassword: any;
    setManualAddLoginPassword: any;
    phelboId: any;
    setPhelboId: any;
    getPhleboDetails: any;
    phleboDetailsList: any;
    // error: any;
    // setError: any;
}
const AssignPhelbo: React.FC<Props> = ({
    phelboLoginId,
    setPhelboLoginId,
    phelboLoginPassword,
    setPhelboLoginPassword,
    manualAddLoginId,
    setManualAddLoginId,
    manualAddLoginPassword,
    setManualAddLoginPassword,
    phelboId,
    setPhelboId,
    getPhleboDetails,
    phleboDetailsList,
    // error,
    // setError,
}) => {
    const [open, setOpen] = useState<any>(false)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const handleSelectChange = (e: any) => {
        setPhelboLoginId(e.target.value)
        if (e.target.value == 'Manually') {
            setOpen(true)
        }
        else {
            setOpen(false)
        }
    }
    const handleLoginIdSubmit = () => {
        setPhelboLoginId('')
        setOpen(false)
    }
    const handlePhleboId = (e: any, value: any) => {
        setPhelboId(value)
    }
    return (
        <Grid container >
            <h4>Assign Phelbo</h4>
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                spacing={2}
                paddingRight={2}
                paddingBottom={2}
                style={{ margin: "1rem auto", background: "#eff" }}
            >
                <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={phleboDetailsList && phleboDetailsList?.results || ''}
                        // getOptionLabel={(option: any) => option?.fullname || ""}
                        getOptionLabel={(option: any) =>
                            option?.free === false ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <div>{option?.fullname}</div>
                                    <div style={{ marginLeft: '15px', color: 'red', textAlign: 'end' }}>Not Available</div>
                                </div> :
                                option?.fullname}
                        getOptionDisabled={(option: any) => option?.free === false}
                        onChange={(e: any, value: any) => handlePhleboId(e, value)}
                        style={{ width: "100%", margin: "0" }}
                        renderInput={(params) =>
                            <TextField
                                className="input"
                                {...params}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Phlebo Name"
                                required
                            />}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>

                    <TextField
                        className="input"
                        name="assignPhelboid"
                        type="text"
                        label="Login Id"
                        value={phelboLoginId}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        // helperText={error} // error message
                        // error={!!error}
                        onChange={(e) => setPhelboLoginId(e.target.value)}
                        required
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        className="input"
                        name="assignPhelbopassword"
                        type="text"
                        label="Password"
                        value={phelboLoginPassword}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        // helperText={error} // error message
                        // error={!!error}
                        onChange={(e) => setPhelboLoginPassword(e.target.value)}
                        required
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default AssignPhelbo;