import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import { createTheme } from "@mui/material";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const theme = createTheme();
const useStyles = makeStyles({
    hoveItem: {
        cursor: "pointer",
      },
})
interface Props{
formShow:any;
setFormShow:any
}
const DesktopHeaderPage:React.FC<Props> = ({
    formShow,
    setFormShow
}) => {
    const classes = useStyles();
    return (
        <h6
            className={classes.hoveItem}
            style={{
                fontWeight: "bold",
                background: "#0A1929",
                color: "#fff",
                padding: "5px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "  20px",
            }}
            onClick={() => setFormShow(formShow ? false : true)}
        >
            {formShow ? <ExpandLessIcon /> : <ExpandMoreIcon />}
           MANAGE USERS
        </h6>
    )
}
export default DesktopHeaderPage