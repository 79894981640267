import React from "react";
import CustomerExecutiveDashboardLeft from "../../CustomerExecutive/dashboard-left";
import CustomerExecutiveDashboardRight from "../../CustomerExecutive/dashboard-right";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const PhleboAdminDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <CustomerExecutiveDashboardLeft />
            <CustomerExecutiveDashboardRight>{children && children}</CustomerExecutiveDashboardRight>
        </div>
    );
};

export default PhleboAdminDash;

