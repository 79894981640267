import React, { useState, useEffect, useReducer } from 'react'
import { makeStyles } from '@mui/styles';
import { Button, Chip, Paper, NativeSelect, MenuItem, Modal, Box, Select, TextField, Grid, InputLabel } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createTheme } from '@mui/material/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { updateBooking } from '../../action/adminAction';
import CommentIcon from '@mui/icons-material/Comment';
import { IconButton } from '@material-ui/core';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 500,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '2px 2px 12px 2px #00000026',
    borderRadius: '10px',
    p: 4,
};

const commentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '500px',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '2px 2px 12px 2px #00000026',
    borderRadius: '10px',
    p: 4,
};
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    paper: {
        marginTop: "2rem",
        width: "100%",
    },
    tableHeadCell: {
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
    },
    table: {
        maxWidth: "100%",
    },
    tableHead: {
        background: "rgb(146, 74, 145)!important",
    },
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0A1929",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
interface Props {
    bookingList: any;
    updateBooking: any;
    getBookingComments: any;
    comments: any;
    createComments: any;
}

const BookingTable: React.FC<Props> = ({
    bookingList,
    updateBooking,
    getBookingComments,
    comments,
    createComments
}) => {
    const classes = useStyles();
    const [pickingStatus, setPickingStatus] = useState<any>("")
    const [open, setOpen] = React.useState(false);
    const [bookingData, setBookingData] = useState<any>({})
    const [bookingStatus, setBookingStatus] = useState<any>("")
    const [openBookingStatus, setOpenBookingStatus] = useState(false)
    const [reportStatus, setReportStatus] = useState<any>("")
    const [openReportStatus, setOpenReportStatus] = useState(false)
    const [openComments, setOpenComments] = useState<any>(false)
    const [newComment, setNewComment] = useState<any>('')
    const handleClose = () => setOpen(false);
    const handleCloseBookingStatus = () => setOpenBookingStatus(false);
    const handleCloseReportStatus = () => setOpenReportStatus(false);

    useEffect(() => {
        setBookingData(bookingData)
    }, [bookingData])

    // useEffect(() => {
    //     if (bookingData !== '') {
    //         getBookingComments(bookingData)
    //     }
    // }, [bookingData])

    const handleSubmit = async (e: any) => {
        setPickingStatus(e.target.value)
        setOpen(true)
    }

    const handleBookingStatus = async (e: any) => {
        setBookingStatus(e.target.value)
        setOpenBookingStatus(true)
    }

    const handleReportStatus = async (e: any) => {
        setReportStatus(e.target.value)
        setOpenReportStatus(true)
    }

    const changePickUpStatus = async () => {
        const body = {
            pickup_status: pickingStatus,
        }
        await updateBooking(body, bookingData)
        window.location.reload()
        setOpen(false)
    }

    const changeBookingStatus = async () => {
        const body = {
            booking_status: bookingStatus,
        }
        await updateBooking(body, bookingData)
        window.location.reload()
        setOpenBookingStatus(false)
    }

    const changeReportStatus = async () => {
        const body = {
            report_status: reportStatus,
        }
        await updateBooking(body, bookingData)
        window.location.reload()
        setOpenReportStatus(false)
    }

    const createBookingComments = async () => {
        const body = {
            comment: newComment
        }
        await createComments(body, bookingData)
        window.location.reload()
        setNewComment('')
    }

    const handleOpenComments = () => {
        setOpenComments(true)
    }
    const handleCloseComments = () => {
        setOpenComments(false)
    }
    const handleSubmitModal = async (data: any) => {
        handleOpenComments()
        if (data !== '') {
            getBookingComments(data)
        }
    }

    return (
        <div style={{ width: "100%" }} className="data-table">
            <TableContainer className={classes.tableContainer}
                component={Paper}
                style={{ maxHeight: "550px" }}
            >
                <Table stickyHeader aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                        <StyledTableRow>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Action</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>View</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Booking Id</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Lead ID</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}> Name</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Age</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Gender</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Mobile Number</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Booking Date</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Collection Date</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Collection Time</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Booking Status</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Pickup Status</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Report Status</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Comments</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {bookingList && bookingList?.results && bookingList?.results.length > 0 && bookingList?.results.map((data: any, index: any) => {
                            return (
                                <>
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="center">
                                            <Link
                                                to={`/dashboard/admin/editbooking/${data?.booking_id}`}
                                                style={{ textDecoration: "none" }}
                                            >
                                                <>
                                                    <Button variant="contained" color='success'>Edit</Button>
                                                </>
                                            </Link>

                                        </StyledTableCell>
                                        <StyledTableCell align="center">

                                            <Link
                                                to={`/dashboard/admin/viewbooking/${data?.booking_id}`}
                                                style={{ textDecoration: "none" }}
                                            >
                                                <Button variant="contained">View</Button>
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{data?.booking_id}</StyledTableCell>
                                        <StyledTableCell align="center">{data?.lead_id}</StyledTableCell>
                                        <StyledTableCell align="center">{data?.customer_name}</StyledTableCell>
                                        <StyledTableCell align="center">{data?.age}</StyledTableCell>
                                        <StyledTableCell align="center">{data?.gender}</StyledTableCell>
                                        <StyledTableCell align="center">{data?.mobile_no}</StyledTableCell>
                                        <StyledTableCell align="center">{moment(data?.booking_date).format("DD-MM-YYYY")}</StyledTableCell>
                                        <StyledTableCell align="center">  {moment.utc(data?.collection_date).format('MM-DD-YYYY')}</StyledTableCell>
                                        <StyledTableCell align="center"> {`${data?.time_slot?.start_time} - ${data?.time_slot?.end_time}`}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Select
                                                defaultValue={data?.booking_status}
                                                onChange={((e) => handleBookingStatus(e))}
                                                onOpen={(() => setBookingData(data?.booking_id))}
                                                id="demo-simple-select"
                                                size="small"
                                                sx={{ borderRadius: '25px', width: '120px', fontSize: '13px', padding: '0px', color: 'white', backgroundColor: data.booking_status === "PENDING" ? "#1976d2" : data.booking_status === "VERIFIED" ? "#2e7d32" : "red" }}
                                                color={data.booking_status || bookingStatus === "PENDING" ? "primary" : data.booking_status === "VERIFIED" ? "success" : "error"}
                                            >
                                                <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                                <MenuItem value={"VERIFIED"}>VERIFIED </MenuItem>
                                            </Select>

                                            {/* <Chip label={data?.booking_status} color={data.booking_status === "PENDING" ? "primary" : data.booking_status === "CONFIRM" ? "success" : "error"} /> */}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Select
                                                defaultValue={data?.pickup_status}
                                                onChange={((e) => handleSubmit(e))}
                                                onOpen={(() => setBookingData(data?.booking_id))}
                                                id="demo-simple-select"
                                                size="small"
                                                sx={{ borderRadius: '25px', width: '120px', fontSize: '13px', padding: '0px', color: 'white', backgroundColor: data.pickup_status === "PENDING" ? "#1976d2" : data.pickup_status === "CONFIRM" ? "#2e7d32" : "red" }}
                                                color={data.pickup_status === "PENDING" ? "primary" : data.pickup_status === "CONFIRM" ? "success" : "error"}
                                            >

                                                <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                                <MenuItem value={"HOLD"}>HOLD</MenuItem>
                                                <MenuItem value={"CONFIRM"}>CONFIRM</MenuItem>
                                            </Select>

                                            {/* {data?.booking_id} */}
                                            {/* <Chip label={data?.pickup_status} color={data.pickup_status==="PENDING"?"primary":data.pickup_status==="CONFIRM"?"success":"error"} />  */}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Select
                                                defaultValue={data?.report_status}
                                                onChange={((e) => handleReportStatus(e))}
                                                onOpen={(() => setBookingData(data?.booking_id))}
                                                id="demo-simple-select"
                                                size="small"
                                                sx={{ borderRadius: '25px', width: '120px', fontSize: '13px', padding: '0px', color: 'white', backgroundColor: data.report_status === "PENDING" ? "#1976d2" : data.report_status === "SHARED" ? "#2e7d32" : "red" }}
                                                color={data.report_status === "PENDING" ? "primary" : data.report_status === "SHARED" ? "success" : "error"}
                                            >

                                                <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                                <MenuItem value={"SENT"}>SENT</MenuItem>
                                                <MenuItem value={"SHARED"}>SHARED</MenuItem>
                                            </Select>

                                            {/* <Chip label={data?.report_status} color={data.report_status === "PENDING" ? "primary" : data.report_status === "CONFIRM" ? "success" : "error"} /> */}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <CommentIcon
                                                // onOpen={(() => setBookingData(data?.booking_id))}
                                                onClick={() => handleSubmitModal(data?.booking_id)}
                                            />

                                        </StyledTableCell>
                                    </StyledTableRow>
                                </>
                            )
                        })}
                        {bookingList && bookingList?.results && bookingList?.results.length > 0
                            ? "" :
                            <div style={{ width: '100%', textAlign: 'center', fontSize: '20px', fontWeight: '600', padding: '15px' }}>
                                No Booking is added yet
                            </div>
                        }
                    </TableBody >
                </Table>
            </TableContainer>

            <Modal open={openBookingStatus} onClose={handleCloseBookingStatus}>
                <Box sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item padding={2} justifyContent="center" xs={12} sm={12} md={12} lg={12}>
                            Are you sure, you want to change the Booking Status?
                        </Grid>
                        <Grid item container padding={2} justifyContent="center" xs={12} sm={12} md={9} lg={9}>
                            <Button onClick={changeBookingStatus} color='success' variant="contained" sx={{ width: '100%' }}>Confirm Booking Status</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item padding={2} justifyContent="center" xs={12} sm={12} md={12} lg={12}>
                            Are you sure, you want to change the Pending Status?
                        </Grid>
                        <Grid item container padding={2} justifyContent="center" xs={12} sm={12} md={9} lg={9}>
                            <Button onClick={changePickUpStatus} color='success' variant="contained" sx={{ width: '100%' }}>Confirm Pending Status</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal open={openReportStatus} onClose={handleCloseReportStatus}>
                <Box sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item padding={2} justifyContent="center" xs={12} sm={12} md={12} lg={12}>
                            Are you sure, you want to change the Report Status?
                        </Grid>
                        <Grid item container padding={2} justifyContent="center" xs={12} sm={12} md={9} lg={9}>
                            <Button onClick={changeReportStatus} color='success' variant="contained" sx={{ width: '100%' }}>Confirm Report Status</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openComments}
                onClose={handleCloseComments}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-width'>
                    <Box sx={commentStyle}>
                        <div style={{ height: '250px', overflowY: 'scroll' }}>
                            {
                                comments && comments.comments && comments.comments.map((data: any) =>
                                    <>
                                        <Grid container>
                                            <Grid item md={12} lg={12} sx={{ color: '#b6b6b6', fontSize: '11px' }}>
                                                <ul>
                                                    <li>{`${data.messgae} on ${new Date(data.updated_at).toLocaleDateString()} at ${new Date(data.updated_at).toLocaleTimeString()}`}</li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            }
                        </div>
                        <Grid container paddingTop={4}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <InputLabel>Add New Comment</InputLabel>
                                <TextField required sx={{ width: '100%' }} color="success" value={newComment} onChange={((e) => setNewComment(e.target.value))} />
                            </Grid>
                            <Grid item container padding={2} justifyContent="center" xs={12} sm={12} md={12} lg={12} >
                                <Button sx={{ width: '150px' }} onClick={createBookingComments} variant="contained" color='success'>Save</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Modal>
        </div>
    )
}

export default BookingTable