import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { getCustomerDetailsById, getCampDetails } from "../../../action/phleboAction";
import { useParams } from "react-router-dom";
import LoaderCustom from "../../../../components/loader/Loader";
import Loader from "../../../../components/loader";
import moment from "moment";
import './index.sass'
const theme = createTheme();
const useStyles = makeStyles({
  root: {
    flexGrow: 0,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    width: "80%",
    flexGrow: 21,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(3),
  },
})
interface Props {
  customerList: any;
  campList: any;
  getCustomerDetailsById: any;
  getCampDetails: any;
}
const ViewCamp: React.FC<Props> = ({
  customerList,
  campList,
  getCustomerDetailsById,
  getCampDetails,
}) => {
  const params = useParams();
  const classes = useStyles();
  const [campData, setCampData] = useState<any>({})
  const [url, setCampId] = useState<any>("")
  useEffect(() => {
    getCampDetails()
  }, [])

  useEffect(() => {
    const Ids = campList && campList?.results &&
      campList?.results.length > 0 && campList?.results.map((data: any, index: any) =>
        setCampId(data?.camp_code)
      )
  })
  // useEffect(() => {
  //   if (params.id !== "")
  //     getBookingById(`?booking_id=${params.id}`)
  // }, [params])
  // useEffect(() => {
  //   if (url && params.id !== "") {
  //     getCustomerDetailsById(`/${url}?_id=${params.id}`)
  //   }
  // }, [params, url])
  // useEffect(() => {
  //   if (customerList && customerList?.results && customerList?.results.length > 0) {
  //     setCampData(customerList?.results[0])
  //   }
  // }, [customerList])
  useEffect(() => {
    if(url && params.id !== "")
    getCustomerDetailsById(`/${url}?_id=${params.id}`)
  }, [params,url])
  useEffect(() => {
    if (customerList && customerList?.results && customerList?.results.length > 0) {
      setCampData(customerList?.results[0])
    }
  }, [customerList])
  console.log("customerList", campData)
  return (
    <main className={classes.content}>
      <br />
      <Paper className={classes.paper} elevation={15}>
        <h3 style={{ textAlign: "center" }}>View Customer Details</h3>
        {customerList && customerList?.results && customerList?.results.length > 0 ? (
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            spacing={3}
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={6} md={4}>
              <h6>Creation Date</h6>
              <p style={{ fontWeight: "normal" }}>
                {moment.utc(campData?.creation_time).format('MM-DD-YYYY')}
              </p>
            </Grid>
            <Grid item xs={6} md={4}>
              <h6>Camp Id</h6>
              <p style={{ fontWeight: "normal" }}>{campData?.camp_id
              }</p>
            </Grid>
            <Grid item xs={6} md={4}>
              <h6>Created By</h6>
              <p style={{ fontWeight: "normal" }}>

                {campData?.created_by}
              </p>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Customer Name</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Age</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Gender</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Email</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Phone</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">BP</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Pulse Rate</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Vials</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Radiology</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{campData?.name}</TableCell>
                    <TableCell align="center">{campData?.age}</TableCell>
                    <TableCell align="center">{campData?.gender}</TableCell>
                    <TableCell align="center">{campData?.email}</TableCell>
                    <TableCell align="center">{campData?.phone}</TableCell>
                    <TableCell align="center">{campData?.BP}</TableCell>
                    <TableCell align="center">{campData?.pulse_rate}</TableCell>
                    <TableCell align="center">{campData?.vials}</TableCell>
                    <TableCell align="center">{campData?.radiology}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Grid item xs={12} md={12} lg={12} >
              <Divider style={{ backgroundColor: "#009fff" }} />
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Mobile Number
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={campData?.phone || ""}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Alternate Number
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={campData?.alternate_no || "0"}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Height
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={campData?.height || "0"}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Weight
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={campData?.weight || "0"}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  BP
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={campData?.BP || ""}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
              <Grid
                direction="row"
                alignItems="center"
                container
                item
                xs={12}
                md={6}
                spacing={3}
              >
                <Grid item xs={12} md={5}>
                  <p>Pulse Rate</p>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    name="houseNumber"
                    type="text"
                    value={
                      campData?.pulse_rate
                    }
                    disabled
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                direction="row"
                alignItems="center"
                container
                item
                xs={12}
                md={6}
                spacing={3}
              >
                <Grid item xs={12} md={5}>
                  <p>Vials</p>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    name="customer_address"
                    type="text"
                    value={campData?.vials || ""}
                    disabled
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                container
                item
                xs={12}
                md={6}
                spacing={3}
              >
                <Grid item xs={12} md={5}>
                  <p>Radiology</p>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    name="customer_landmark"
                    type="text"
                    value={campData?.radiology || ""}
                    disabled
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
        ) : <Loader />}

      </Paper>
    </main>
  )
}

const mapStateToProps = (state: any) => ({
  customerList: state.PhleboReducer.customerList,
  campList: state.PhleboReducer.campList,
});
export default connect(mapStateToProps, {
  getCustomerDetailsById,
  getCampDetails
})(ViewCamp);
