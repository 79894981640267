import React from "react";
import { Grid, TextField } from "@mui/material";
import './Index.sass'
interface Props {
    mobileNumber: any;
    setMobileNumber: any;
    alternateMobileNumber: any;
    setAlternateMobileNumber: any;
    whatsappNumber: any;
    setWhatsappNumber: any;
    email:any;
    setEmail:any
    error:any;
}

const ContactDetails: React.FC<Props> = ({
    mobileNumber,
    setMobileNumber,
    alternateMobileNumber,
    setAlternateMobileNumber,
    whatsappNumber,
    setWhatsappNumber,
    email,
    setEmail,
    error
}) => {
    return (
        <Grid
            container
            direction="row"
            spacing={3}
            style={{ margin: "1rem auto", background: "#e1f5fe" }}
        >
            <Grid item md={3}>
                <TextField
                    className="input"
                    name="mobile"
                    type="text"
                    label="Mobile Number"
                    value={mobileNumber}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                    helperText={error} // error message
                    error={!!error}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    required
                />
            </Grid>
            <Grid item md={3}>
                <TextField
                    className="input"
                    name="alternatemobile"
                    type="text"
                    label="Alternate Number"
                    value={alternateMobileNumber}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                    helperText={error} // error message
                    error={!!error}
                    onChange={(e) => setAlternateMobileNumber(e.target.value)}
                    required
                />
            </Grid>
            <Grid item md={3}>
                <TextField
                    className="input"
                    name="whatsapp"
                    type="text"
                    label="Whatsapp Number"
                    value={whatsappNumber}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                    helperText={error} // error message
                    error={!!error}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                    required
                />
            </Grid>
            <Grid item md={3}>
                <TextField
                    className="input"
                    name="email"
                    type="text"
                    label="Email"
                    value={email}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                    helperText={error} // error message
                    error={!!error}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </Grid>
        </Grid>
    )
}

export default ContactDetails