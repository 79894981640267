import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Grid, TextField } from "@mui/material";
import TimeSlotsTable from "../TimeSlots/TimeSlotsTable";
import { getAllTimeslots } from '../../action/adminAction';
import { generateTimeSlotFilter } from '../../../helpers/generateUrl';
import { Button } from "@mui/material";
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
})
interface Props {
    getAllTimeslots:any;
    timeSlotsList:any;
}
const LeadDetailsFilter: React.FC<Props> = ({
    getAllTimeslots,
    timeSlotsList
}) => {
    const classes = useStyles();
    const [inputList, setInputList] = useState<any>([
        {
            bookingId: "",
            bookingDate: "",
            collectionDate: "",
            startTime: "",
            endTime: ""
        },
    ]);
    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    }

    const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const [bookingId, setBookingid] = useState<any>("");
    const [name, setName] = useState<any>("");
    const [phone, setPhone] = useState<any>("");
    const [booking_date, setBooking_date] = useState("");
    const [collection_date, setCollection_date] = useState("");
    const [start_date, setStart_date] = useState("");
    const [end_date, setEnd_date] = useState("");

    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            //   filterBookings(e);
        }
    };
    useEffect(() => {
        getAllTimeslots()
    }, [])
    const handleSubmit = async (e: any) => {
        const body = {
            _id: inputList[0].bookingId,
            booking_date: inputList[0].bookingDate,
            start_time: inputList[0].startTime,
            end_time: inputList[0].endTime,
            collection_date: inputList[0].collectionDate,
        }
        const url = generateTimeSlotFilter(body).substring(2);
        getAllTimeslots(url ? `?${url}` : "")
    }
    const handleReset = () => {
        const resertData = { bookingId: "", bookingDate: "", startTime: "", endTime: "", collectionDate: "" }
        setInputList([{ bookingId: "", bookingDate: "", startTime: "", endTime: "", collectionDate: "" }])
        getAllTimeslots()
    }
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            {inputList.map((x: any, index: number) => {
                return (
                    <Grid container direction="row" spacing={2} paddingLeft={2} >
                        <Grid item xs={12} sm={6} md={1.7}>
                            <label>Start Time</label><br />
                            <input
                                style={{ width: '100%', padding: '5px' }}
                                className="input"
                                name="startTime"
                                type="time"
                                placeholder="hh:mm:am"
                                value={x.startTime || "00:00"}
                                onChange={(e) => handleInputChange(e, index)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={1.7}>
                            <label>End Time</label><br />
                            <input
                                style={{ width: '100%', padding: '5px' }}
                                className="input"
                                name="endTime"
                                type="time"
                                value={x.endTime || "00:00"}
                                onChange={(e) => handleInputChange(e, index)}
                            />
                        </Grid>
                     
                        <Grid item xs={12} sm={6} md={2.5} marginTop={4}>
                            <Button
                                color="success"
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                                variant="contained"
                                onClick={(e: any) => handleSubmit(e)}
                            >
                                Filter
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.5} marginTop={4}>
                            <Button
                                color="error"
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                                variant="contained"
                                onClick={handleReset}
                            >
                                RESET
                            </Button>
                        </Grid>
                    </Grid>
                )
            })}
            <TimeSlotsTable
                timeSlotsList={timeSlotsList}
            />
        </main>
    )
}
const mapStateToProps = (state: any) => ({
    timeSlotsList: state.AdminReducer.timeSlotsList,
});
export default connect(mapStateToProps, {
    getAllTimeslots
})(LeadDetailsFilter);