import React from "react";
import RouteManagerDashboardLeft from "../../RouteManager/dashboard-left";
import RouteManagerDashboardRight from "../../RouteManager/dashboard-right";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const RouteManagerDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <RouteManagerDashboardLeft />
            <RouteManagerDashboardRight>{children && children}</RouteManagerDashboardRight>
        </div>
    );
};

export default RouteManagerDash;

