export const arr = [
  {
    role: "ADMIN",
    url: "admin",
  },
  {
    role: "PHA",
    url: "pha",
  },
  {
    role: "RM",
    url: "rm",
  },
  {
    role: "CE",
    url: "ce",
  },
  {
    role: "PH",
    url: "ph",
  },
];
