export const ACTIONS = {
  SET_LOADING: "admin/setloading",
  GET_USER_LIST: "admin/get_user_list",
  CREATE_NEW_USER: "admin/create_new_users",
  UPDATE_USER_PROFILE: "admin/update_user_profile",
  GET_BOOKING_TIMESLOTS: "admin/get_booking_timeslots",
  GET_PACKAGE_DETAILS: "admin/get_package_details",
  CREATE_BOOKING: "admin/create_booking",
  GET_ALL_BOOKING: "admin/get_all_booking",
  GET_ACTIVE_PINCODE:"admin/GET_ACTIVE_PINCODE",
  GET_ALL_PINCODE:"admin/GET_ALL_PINCODE",
  GET_ALL_LEADS:"admin/get_lead_details",
  GET_ALL_TIMESLOTS:"admin/get_all_timeslots",
  GET_PHLEBOS:"admin/get_phlebos",
  UPDATE_BOOKING:"admin/update_booking",
  ACTIVE_DEACTIVE_PINCODE:"admin/active_deactive_pincode",
  GET_DISCOUNT_PRICE:"/discount/price",
  GET_COMMENTS:'/admin/comments',
  CREATE_COMMENTS:'"admin/create_comment"'
};
interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface UpdateUserProfile {
  type: typeof ACTIONS.UPDATE_USER_PROFILE
  payload: Array<any>
}

interface GetUserList {
  type: typeof ACTIONS.GET_USER_LIST;
  payload: Array<any>;
}
interface CreateNewUser {
  type: typeof ACTIONS.CREATE_NEW_USER;
  payload: boolean;
}

interface GetBookingTimeSlots {
  type: typeof ACTIONS.GET_BOOKING_TIMESLOTS;
  payload: Array<any>;
}
interface GetPackageDetails {
  type: typeof ACTIONS.GET_PACKAGE_DETAILS;
  payload: Array<any>;
}
interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: Array<any>;
}
interface GetAllBooking {
  type: typeof ACTIONS.GET_ALL_BOOKING;
  payload: Array<any>;
}
interface GetDiscountPrice {
  type: typeof ACTIONS.GET_DISCOUNT_PRICE;
  payload: Array<any>;
}
interface getActivePinCode {
  type: typeof ACTIONS.GET_ACTIVE_PINCODE;
  payload: Array<any>;
}
interface getAllPinCode {
  type: typeof ACTIONS.GET_ALL_PINCODE;
  payload: Array<any>;
}
interface GetLeadDetails {
  type: typeof ACTIONS.GET_ALL_LEADS;
  payload: Array<any>;
}
interface GetAllTimeSlots {
  type: typeof ACTIONS.GET_ALL_TIMESLOTS;
  payload: Array<any>;
}
interface GetPhlebos {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}
interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: Array<any>;
}
interface ActiveDeactivePincode {
  type: typeof ACTIONS.ACTIVE_DEACTIVE_PINCODE;
  payload: Array<any>;
}
interface GetComments {
  type: typeof ACTIONS.GET_COMMENTS;
  payload: Array<any>;
}
interface CreateComments {
  type: typeof ACTIONS.CREATE_COMMENTS;
  payload: Array<any>;
}
export type ValidationDashboarType =
  | SetLoading
  | GetUserList
  | CreateNewUser
  | UpdateUserProfile
  | GetBookingTimeSlots
  | GetPackageDetails
  | CreateBooking
  | GetAllBooking
  | getActivePinCode
  | GetLeadDetails
  | GetAllTimeSlots
  | GetPhlebos
  | UpdateBooking
  | getAllPinCode
  | ActiveDeactivePincode
  | GetDiscountPrice
  | GetComments
  | CreateComments