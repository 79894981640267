import React, { useEffect } from "react";
import { Grid, Autocomplete, TextField, Checkbox } from "@mui/material";
import { error } from "console";
import { GENDER_CHOICE } from "../../../../constant";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
interface Props {
    error: any
    setError: any
    customerName: any;
    setCustomerName: any;
    customerAge: any;
    setCustomerAge: any;
    customerGender: any;
    setCustomerGender: any;
    mobileNumber: any;
    setMobileNumber: any;
    customerVials: any;
    setCustomerVials: any;
    customerRadiology: any;
    setcustomerRadiology: any;
    customerWeight: any;
    setCustomerWeight: any;
    customerHeight: any;
    setCustomerHeight: any;
    customerPulseRate: any;
    setCustomerPulseRate: any;
    customerBP: any;
    setCustomerBP: any;
    customerEmail: any;
    setCustomerEmail: any;
    customerSignature: any;
    setCustomerSignature: any;
    campList: any;
    campId: any;
    setCampId: any;
}
const BasicDetails: React.FC<Props> = ({
    error,
    setError,
    customerName,
    setCustomerName,
    customerAge,
    setCustomerAge,
    customerGender,
    setCustomerGender,
    mobileNumber,
    setMobileNumber,
    customerVials,
    setCustomerVials,
    customerRadiology,
    setcustomerRadiology,
    customerWeight,
    setCustomerWeight,
    customerHeight,
    setCustomerHeight,
    customerPulseRate,
    setCustomerPulseRate,
    customerBP,
    setCustomerBP,
    customerEmail,
    setCustomerEmail,
    customerSignature,
    setCustomerSignature,
    campList,
    campId,
    setCampId,
}) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
        { title: "Schindler's List", year: 1993 },
        { title: 'Pulp Fiction', year: 1994 },
    ];
    useEffect(()=>{
        campList && campList?.results && campList?.results.length > 0 &&
        campList?.results.map((data: any, index: any) => setCampId(data?.camp_code))
    },[])
    const campIds=campList && campList?.results && campList?.results.length > 0 &&
    campList?.results.map((data: any, index: any) => data?.camp_code)
    console.log("campId",campIds)
    // const campIds = campList && campList?.results && campList?.results.length > 0 &&
    //     campList?.results.map((data: any, index: any) => data?._id)
    // const handleInputChange = (e: any, index: number) => {
    //     const { name, value } = e.target;
    //     if (name === "customerName") {
    //         const newValue = e.target.value;

    //         if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
    //             setError("");
    //         } else {
    //             setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
    //         }
    //     }
    //     const list = [...inputList];
    //     if (name === "passport") {
    //         const result = value.replace(/[^A-Z0-9]/, "");

    //         list[index][name] = result;
    //     } else {
    //         list[index][name] = value;
    //     }
    //     if (e.target.name === "designation") {
    //         const findGender = GENDER_CHOICE.filter(
    //             (el: any) => el.display_name === e.target.value
    //         );
    //         list[index]["customerGender"] = findGender[0]?.gender;
    //         list[index]["customerDesignation"] = findGender[0]?.db_name;
    //     }
    //     if (e.target.name === "dateOfBirth") {
    //         let today = new Date();
    //         let birthDate = new Date(e.target.value);
    //         let age = today.getFullYear() - birthDate.getFullYear();
    //         var m = today.getMonth() - birthDate.getMonth();
    //         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //             age--;
    //         }
    //         list[index]["customerAge"] = age;
    //     }
    //     setInputList(list);
    // };
    return (
        <Grid container spacing={2} marginTop={5} marginLeft={0}>
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                spacing={3}
                style={{ margin: "1rem auto", background: "#eff" }}
            >
                <Grid
                    container
                    direction="row"
                    spacing={2} paddingRight={2} paddingBottom={2}
                    style={{ margin: "1rem auto", background: "#e1f5fe" }}
                >
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="campid"
                            type="text"
                            label="Camp Id"
                            value={campId || ''}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="customerName"
                            type="text"
                            label="Customer Name"
                            value={customerName}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setCustomerName(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="customerAge"
                            type="number"
                            label="Age"
                            value={customerAge}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            style={{ width: "100%", margin: "0" }}
                            inputProps={{ min: 0, max: 100 }}
                            onChange={(e) => { setCustomerAge(e.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="customerGender"
                            variant="outlined"
                            label="Gender"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={customerGender}
                            style={{ width: "100%", margin: "0", height: "40px" }}
                            onChange={(e) => setCustomerGender(e.target.value)}
                            // disabled={true}
                            required={true}
                        />
                        <p style={{ color: "red" }}>
                            {customerGender === null
                                ? "Please select gender"
                                : ""}
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="weight"
                            type="number"
                            label="Weight"
                            value={customerWeight}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setCustomerWeight(e.target.value)}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="height"
                            type="number"
                            label="Height"
                            value={customerHeight}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setCustomerHeight(e.target.value)}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="pulserate"
                            type="number"
                            label="Pulse Rate"
                            value={customerPulseRate}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setCustomerPulseRate(e.target.value)}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="bp"
                            type="number"
                            label="BP"
                            value={customerBP}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setCustomerBP(e.target.value)}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="mobile"
                            type="number"
                            label="Mobile Number"
                            value={mobileNumber}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps:{
                                    minLength:0,
                                    maxLength:10,
                                }
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setMobileNumber(e.target.value.substring(0, 10))}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="email"
                            type="email"
                            label="Email"
                            value={customerEmail}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setCustomerEmail(e.target.value)}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="vials"
                            type="text"
                            label="vials"
                            value={customerVials}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setCustomerVials(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="radiologu"
                            type="text"
                            label="Radiology"
                            value={customerRadiology}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setcustomerRadiology(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="customer"
                            type="text"
                            label="Customer Sign"
                            value={customerSignature}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                            style={{ width: "100%", margin: "0" }}
                            helperText={error} // error message
                            error={!!error}
                            onChange={(e) => setCustomerSignature(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default BasicDetails