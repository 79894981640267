import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Autocomplete, Button, Grid, Paper, TextField } from "@mui/material";
import { getAllPinCode, activeDeactivePincode, getActivePinCode } from '../../action/adminAction'
import PincodeTable from './pincodeList'
import './Index.sass'
const theme = createTheme();
const useStyles = makeStyles({
  root: {
    flexGrow: 0,
  },
  content: {
    width: "100%",
    flexGrow: 21,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  form: {
    width: "100%"
  },
  paper: {
    marginTop: "1rem",
    padding: "1rem 0.5rem 0.5rem 0.5rem",
    width: "100%",
  },
  hoveItem: {
    cursor: "pointer",
  },
  input: {
    "& .MuiOutlinedInput-input": {
      color: "green",
      padding: "9px"
    },
  },
  child1: {
    display: "inline-block",
    verticalAlign: "middle",
    alignItems: "right"
  },
  child2: {
    display: "inline-block",
    verticalAlign: "middle",
    alignItems: "left"
  },
  AutoCompleteinput: {
    "& .MuiOutlinedInput-input": {
      color: "green",
      padding: "6px",
      marginTop: "1px"
    },
  },

})
interface Props {
  getAllPinCode: any;
  pincodeList: any;
  activeDeactivePincode: any;
  getActivePinCode: any
  activePincodeList: any

}

const ManagePincode: React.FC<Props> = ({
  getAllPinCode,
  pincodeList,
  activeDeactivePincode,
  getActivePinCode,
  activePincodeList,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [pincode, setPincode] = useState<any>("")
  const [keys, setKeys] = useState<any>(0)
  const [keys2, setKeys2] = useState<any>(0)
  const [pincodeOBJ, setPincodeOBJ] = useState<any>("")
  const [status, setStatus] = useState<any>("")

  const handleSubmit = async () => {
    const body = {
      pincode: pincode,
      status: status
    }
    
    if (pincode !== "" && status !== "") {
      await activeDeactivePincode(body)
      setPincode("")
      setStatus("")
      setPincodeOBJ("")
      setKeys(Math.floor(Math.random()*10))
      setKeys2(Math.floor(Math.random()*10))
    }
    else {
      alert("Please Select Pincode and Status")
    }
  }
  const handleReset = () => {
    setKeys(Math.floor(Math.random() * 10))
    setKeys2(Math.floor(Math.random() * 10))
    setPincode("")
    setStatus("")
    setPincodeOBJ("")
    getAllPinCode()
  }
  useEffect(() => {
    // getActivePinCode()
    getAllPinCode()
  }, [])
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Paper
        elevation={5}
        className={classes.paper}
        sx={{ maxHeight: 250 }}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={6} sm={6} md={6}>
            <Autocomplete
              id="centre"
              key={keys}
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(
                    JSON.stringify(newValue, null, " ")
                  );
                  setPincode(obj?.pincode);
                  setPincodeOBJ(obj);
                }
              }}
              options={pincodeList && pincodeList?.results || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  if (newInputValue !== "")
                    getAllPinCode(`?pincode=${newInputValue}`);
                }, 500);
              }}
              getOptionLabel={(option: any) => option.pincode}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Pincode"
                  required
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Autocomplete
              id="centre"
              key={keys2}
              onChange={(event, newValue) => {
                if (newValue) {

                  let obj = JSON.parse(
                    JSON.stringify(newValue, null, " ")
                  );
                  setStatus(obj.value);
                }
              }}
              options={[
                { text: "Active", value: "true" },
                { text: "De-Active", value: "false" }
              ] }
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Status"
                  variant="outlined"
                  required
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Button variant="contained" fullWidth color="success" onClick={handleSubmit}>
              Update
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Button variant="contained" fullWidth color="error" onClick={handleReset}>
              Reset
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          spacing={3}
          style={{ margin: "1rem auto" }}
        >
          <Grid item xs={6} md={6} style={{ textAlign: "left" }}>
            <p style={{ fontWeight: "normal" }}>
              Pincode : {pincodeOBJ?.pincode}
            </p>
          </Grid>
          <Grid item xs={6} md={6} style={{ textAlign: "left" }}>
            <p style={{ fontWeight: "normal" }}>
              Distict : {pincodeOBJ?.district}
            </p>
          </Grid>
          <Grid item xs={6} md={6} style={{ textAlign: "left" }}>
            <p style={{ fontWeight: "normal" }}>
              State : {pincodeOBJ?.state}
            </p>
          </Grid>
        </Grid>
      </Paper>
      <PincodeTable
      pincodeList={pincodeList}
      getAllPinCode={getAllPinCode}
        getActivePinCode={getActivePinCode}
        activePincodeList={activePincodeList}
      />
    </main>
  )
}

const mapStateToProps = (state: any) => ({
  pincodeList: state.AdminReducer.pincodeList,
  activePincodeList: state.AdminReducer.activePincodeList,
})

export default connect(mapStateToProps, {
  getAllPinCode,
  activeDeactivePincode,
  getActivePinCode
})(ManagePincode)
