import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Grid, TextField, Button } from "@mui/material";
import CampTable from "./BookingTable";
import { getCampDetails, getCustomerDetails } from "../../action/phleboAction";
import { generateCustomerFilter } from '../../../helpers/generateUrl';
import { url } from "inspector";
import { dateCalendarClasses } from "@mui/x-date-pickers";
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
})
interface Props {
    // getAllBooking:any;
    // bookingList:any;
    // updateBooking:any;
    // getBookingComments:any;
    // comments:any;
    // createComments:any;
    getCampDetails: any;
    campList: any;
    getCustomerDetails: any;
    customerList: any;
}
const CampFilter: React.FC<Props> = ({
    // getAllBooking,
    // bookingList,
    // updateBooking,
    // getBookingComments,
    // comments,
    // createComments
    getCampDetails,
    campList,
    getCustomerDetails,
    customerList
}) => {
    const classes = useStyles();
    const [inputList, setInputList] = useState<any>([
        {
            customerName: "",
            age: "",
            gender: "",
            contactNumber: "",
        },
    ]);

    const [url, setCampId] = useState<any>("")
    useEffect(() => {
        getCampDetails()
    }, [])

    useEffect(() => {
        const Ids = campList && campList?.results &&
            campList?.results.length > 0 && campList?.results.map((data: any, index: any) =>
                setCampId(data?.camp_code)
            )
        console.log(url)
    })

    useEffect(()=>{
        if(url){
            getCustomerDetails(url)
        }
    },[url])

    // const handleSubmit = async (e: any) => {
    //     const body = {
    //         booking_id: inputList[0].bookingId,
    //         full_name: inputList[0].customerName,
    //         phone: inputList[0].contactNumber,
    //         booking_date: inputList[0].bookingDate,
    //         start_time: inputList[0].startTime,
    //         end_time: inputList[0].endTime,
    //         collectionDate: inputList[0].collection_date,
    //     }
    //     const url = genrateAdminFilter(body).substring(2);
    //     getAllBooking(url ? `?${url}` : "")
    // }

    // const handle = (e: any, index: any) => {
    //     handleInputChange(e, index)
    //     handleSubmit(e)
    // }

    // const handleReset = () => {
    //     const resertData = { bookingId: "", customerName: "", contactNumber: "", bookingDate: "", startTime: "", endTime: "", collectionDate: "" }
    //     setInputList([{ bookingId: "", customerName: "", contactNumber: "", bookingDate: "", startTime: "",endTime: "", contactNuendTimember: "", collectionDate: "" }])
    //     getAllBooking()
    // }
    // console.log("inputList",inputList)


    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    }

    const handleSubmit = async (e: any) => {
        const body = {
            name: inputList[0].customerName,
            age: inputList[0].age,
            gender: inputList[0].gender,
            phone: inputList[0].contactNumber,
        }
        const filter = generateCustomerFilter(body).substring(2);
        getCustomerDetails(filter ? `${url}?${filter}` : url)
    }
    const handle = (e: any, index: any) => {
        handleInputChange(e, index)
        handleSubmit(e)
    }

    const handleReset = () => {
        const resertData = { customerName: "", age: "", contactNumber: "", gender: "" }
        setInputList([{ customerName: "", age: "", contactNumber: "", gender: "" }])
    }

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            {campList && campList?.results &&
                campList?.results.length > 0 && campList?.results.map((data: any, index: any) => {
                    return (
                        <>
                            <Grid container alignItems="center" sx={{ fontSize: '22px', fontWeight: "600", color:'#07639d' }}>
                                <Grid item container justifyContent="start" xs={12} sm={12} md={6} lg={3}>
                                    CAMP Id:  {data?.camp_code}
                                </Grid>
                                <Grid item container justifyContent="start" xs={12} sm={12} md={6} lg={6}>
                                    Package Name:  {data?.package?.test_name}
                                </Grid>
                                <Grid item container justifyContent="start" xs={12} sm={12} md={6} lg={3}>
                                    Doctor Name:  {data.doctor_detail && data.doctor_detail.length >
                                                0 && data.doctor_detail.map((data1: any, index: any) => data1.fullname
                                                )}
                                </Grid>
                            </Grid>
                        </>
                    )
                })}
            {inputList.map((x: any, index: number) => {
                return (
                    <Grid container marginTop={3} direction="row" spacing={2} >

                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                className="input"
                                label="Name"
                                name="customerName"
                                value={x.customerName || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handle(e, index)}
                                id="outlined-start-adornment"
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                className="input"
                                label="Age"
                                name="age"
                                value={x.age || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handle(e, index)}
                                id="outlined-start-adornment"
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                className="input"
                                label="Gender"
                                name="gender"
                                value={x.gender || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handle(e, index)}
                                id="outlined-start-adornment"
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                className="input"
                                label="Phone"
                                id="outlined-start-adornment"
                                name="contactNumber"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={x.contactNumber || ""}
                                onChange={(e) => handle(e, index)}
                                type="number"
                                onInput={(e: any) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}

                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />

                        </Grid>
                    </Grid>
                )
            })}
            <Grid container direction="row" spacing={2} justifyContent="center" marginTop={1}>
                {/* <Grid item xs={12} sm={12} md={6}>
                    <Button
                        color="success"
                        style={{ width: '100%', margin: "0px", padding: "5px" }}
                        variant="contained"
                        onClick={(e: any) => handleSubmit(e)}
                    >
                        Filter
                    </Button>
                </Grid> */}
                <Grid item xs={12} sm={12} md={6}>
                    <Button
                        color="error"
                        style={{ width: '100%', margin: "0px", padding: "5px" }}
                        variant="contained"
                    onClick={handleReset}
                    >
                        RESET
                    </Button>
                </Grid>
            </Grid>
            <CampTable
                campList={campList}
                customerList={customerList}
            />
        </main>
    )
}
const mapStateToProps = (state: any) => ({
    campList: state.PhleboReducer.campList,
    customerList: state.PhleboReducer.customerList
});
export default connect(mapStateToProps, {
    getCampDetails,
    getCustomerDetails
})(CampFilter);