import React, { useRef, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { Grid, Card, Select, MenuItem, TextField, Button, Autocomplete } from "@mui/material";


const useStyles = makeStyles({
  content: {
    width: "100%",
    flexGrow: 21,
  },
  slotContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  slot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
  },
  selectedSlot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
    cursor: "pointer",
    margin: "0.5rem",
  },
  packageLink: {
    color: "#ffffff",
    textDecoration: "none",
  },
  displayContent: {
    display: 'flex',
  }
})

interface Props {
  timeslots: any;
  selectedSlot: any;
  setSelectedSlot: any;
  inputList: any;
  setInputList: any;
  cValue: any;
  setCValue: any;
  singlePackage: any;
  setSinglePackage: any;
  isPaneOpen: any;
  setPaneOpen: any;
  cType: any;
  setCType: any;
  couponKey: any;
  price: any;
  setPrice: any;
  coupon: any;
  setCoupon: any;
  couponCode: any;
  setCouponCode: any;
  discount: any;
  setDiscount: any;
  partnerCodeDiscount: any;
  setPartnerCodeDiscount: any;
  redProMembershipDiscount: any;
  setRedProMembershipDiscount: any;
  discountDueSmartPackage: any;
  setDiscountDueSmartPackage: any;
  referralPointsDiscount: any;
  setReferralPointsDiscount: any;
  referralPointsApplied: any;
  setReferralPointsApplied: any;
  referralPoints: any;
  setReferralPoints: any;
  extraCharge: any;
  phleboCost: any;
  totalPrice: any;
  getDiscountBooking: any;
  packagesIds: any;
  percentage: any;
  setPercentage: any;
  setPackagesIds: any;
  cApply: any;
  discountPrice: any;
}
const TimeSlotsPackageDetails: React.FC<Props> = ({
  timeslots,
  selectedSlot,
  setSelectedSlot,
  inputList,
  setInputList,
  cValue,
  setCValue,
  singlePackage,
  setSinglePackage,
  isPaneOpen,
  setPaneOpen,
  cType,
  setCType,
  couponKey,
  price,
  setPrice,
  coupon,
  setCoupon,
  couponCode,
  setCouponCode,
  discount,
  setDiscount,
  partnerCodeDiscount,
  setPartnerCodeDiscount,
  redProMembershipDiscount,
  setRedProMembershipDiscount,
  discountDueSmartPackage,
  setDiscountDueSmartPackage,
  referralPointsDiscount,
  setReferralPointsDiscount,
  referralPointsApplied,
  setReferralPointsApplied,
  referralPoints,
  setReferralPoints,
  extraCharge,
  phleboCost,
  totalPrice,
  getDiscountBooking,
  packagesIds,
  percentage,
  setPercentage,
  setPackagesIds,
  cApply,
  discountPrice,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);


  // const [manageCoupon, setManageCoupon] = useState<any>("")
  // const [percentage, setPercentage] = useState<any>("")
  // const [type, setType] = useState<any>("")

  const handleSubmit = async () => {
    let packagesIds: any = [];
    inputList &&
      inputList[0]?.customerPackage.map((item: any) => {
        setPackagesIds(packagesIds.push(item._id));
      });

    const body = {
      percentage: cValue,
      package_id: packagesIds,
      type: cType
    }
    await getDiscountBooking(body)

  }
  let packagesIds1: any = [];
  useEffect(() => {

    inputList &&
      inputList[0]?.customerPackage.map((item: any) => {
        setPackagesIds(packagesIds1.push(item._id));
      });

  })
  console.log("packagesIds", packagesIds1)

  const handle = () => {
    handleSubmit()
  }
  // console.log("packagesIds", packagesIds)
  // useEffect(() => {
  //   getDiscountBooking()
  //   // getActivePinCode()
  // }, [])
  console.log("data", discountPrice)
  {
    inputList &&
      inputList.length > 0 &&
      inputList.map((customerDetails: any, index: number) => {
        return (
          <>
            {customerDetails?.customerPackage &&
              customerDetails?.customerPackage.length > 0 &&
              customerDetails?.customerPackage.map(
                (packageItem: any, index: any) => {
                  return (
                    <>
                      {setPrice(packageItem.MRP)}
                    </>
                  )
                })}
          </>
        )
      })
  }
  console.log("inputList", inputList)

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      alignItems="center"
    >
      <Grid item xs={12} md={5}>
        <Card
          className="sampleCollectionTimeNewBooking"
          style={{ overflow: "auto", minHeight: "500px" }}
        >
          <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
          <div className={classes.slotContainer}>
            {timeslots && timeslots?.results &&
              timeslots?.results.length > 0 &&
              timeslots?.results.map((slot: any) => {
                return (
                  <Card
                    key={slot.id}
                    className={
                      slot._id === selectedSlot
                        ? classes.selectedSlot
                        : classes.slot
                    }
                    onClick={() => setSelectedSlot(slot._id as Number)}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px",
                      }}
                    >
                      {slot.start_time}-{slot.end_time}
                    </p>
                    <p
                      style={{ textAlign: "center", color: "green" }}
                    ></p>
                  </Card>
                );
              })}
            {/* {timeSlots !== undefined && timeSlots.length === 0 ? (
                      <p style={{ color: "red" }}>
                        "Time Slot is not Active in this Area"
                      </p>
                    ) : (
                      ""
                    )} */}
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} md={7} >
        <div className="price-details-new-booking">
          <table style={{ width: "100%", margin: "0" }}>
            <thead>
              <tr>
                <td className="cell" align="center">
                  S No.
                </td>
                <td className="cell" align="center">
                  Name
                </td>
                <td className="cell" align="center">
                  Package
                </td>
                <td className="cell" align="center">
                  Price
                </td>
              </tr>
            </thead>
            <tbody>
              {inputList &&
                inputList.length > 0 &&
                inputList.map((customerDetails: any, index: number) => {
                  return (
                    <tr>
                      <td className="cell" align="center">
                        {index + 1}
                      </td>
                      <td className="cell" align="left">
                        {(customerDetails.designation === "Other" || customerDetails.designation === "other") ? "Dear" : customerDetails.designation}{" "}
                        {customerDetails.customerName}
                      </td>
                      <td className="cell" align="left">
                        {customerDetails?.customerPackage &&
                          customerDetails?.customerPackage.length > 0 &&
                          customerDetails?.customerPackage.map(
                            (packageItem: any, index: any) => {
                              return (
                                <ul>
                                  <li>
                                    <a
                                      onClick={() => {
                                        setSinglePackage(packageItem);
                                        setPaneOpen(true);
                                      }}
                                      className={classes.packageLink}
                                    >
                                      {packageItem?.name}
                                      <br /><div className={classes.displayContent} >
                                        <div> <b>DESCRIPTION</b></div>
                                        {packageItem?.components}
                                      </div>
                                      <br />
                                      <div className={classes.displayContent} >
                                        <div> <b>Report time :</b></div>
                                        {packageItem?.package_city_prices
                                          ? packageItem?.package_city_prices
                                            ?.reported_n
                                          : packageItem?.reported_n}
                                      </div>
                                      <br />
                                      <div className={classes.displayContent} >
                                        <div> <b> Price :</b></div>

                                        {packageItem?.package_city_prices
                                          ? packageItem?.package_city_prices
                                            ?.mbt_price
                                          :
                                          packageItem?.mbt_price
                                        }
                                      </div>
                                      <br />
                                      <div className={classes.displayContent} >
                                        <div> <b>Fasting :</b></div>

                                        {packageItem?.fasting_time}
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              );
                            }
                          )}
                      </td>
                      <td className="cell" align="center">
                        {customerDetails?.customerPackage &&
                          customerDetails?.customerPackage.length > 0 &&
                          customerDetails?.customerPackage.map(
                            (packageItem: any, index: any) => {
                              return (
                                <>
                                  <ul>
                                    <li>
                                      ₹{packageItem?.MRP}
                                    </li>
                                  </ul>
                                </>
                              )

                            })}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={4}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td
                        className="cell"
                        align="left"
                        rowSpan={5}
                        colSpan={2}
                        style={{ width: "60%" }}
                        valign="top"
                      >

                        <Grid container spacing={1} direction="row">
                          <Grid item xs={12}>
                            <p style={{ paddingTop: "12px", marginBottom: "0px" }}>Manage Coupon</p>
                          </Grid>
                          <Grid item xs={12} style={{ marginBottom: "16px" }}>
                            <Select
                              className="input"
                              name="cType"
                              variant="outlined"
                              value={cType}
                              style={{ width: "100%", margin: "0", height: "40px" }}
                              onChange={(e) => setCType(e.target.value)}
                            >
                              <MenuItem selected value={"none"}>
                                Discount Type
                              </MenuItem>
                              <MenuItem value={"code"}>Code</MenuItem>
                              <MenuItem value={"percentage"}>Percentage</MenuItem>
                              <MenuItem value={"referral"}>Referral Coupon Code</MenuItem>
                            </Select>
                          </Grid>
                          {cType === "percentage" ? (<>

                            <Grid item xs={9}>
                              <TextField
                                name="area"
                                value={cValue}
                                className="input"
                                variant="outlined"
                                label="Percentage"
                                onChange={(e) =>
                                  setCValue(e.target.value)
                                }
                                fullWidth
                                size="small"
                                select
                                disabled={price < 1000}
                              >
                                <MenuItem selected value={''}>none</MenuItem>
                                {
                                  price < 2000 ? [5, 10].map((price) => {
                                    return (
                                      <MenuItem key={price} value={price}>{price}</MenuItem>
                                    )
                                  }) :

                                    [5, 10, 15].map((price) => {
                                      return (
                                        <MenuItem key={price} value={price}>{price}</MenuItem>
                                      )
                                    })
                                }

                                {/* {Array.from(
                                  Array(
                                    Number(price) < 2000
                                      ? 2
                                      : 3
                                  ).keys()
                                ).map((val: any) => {
                                  return (
                                    <MenuItem value={val + 5}>{val + 5}</MenuItem>
                                  );
                                })} */}
                              </TextField>
                            </Grid>
                            <Grid item xs={3}>
                              <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                //   disabled={redCashCheck === true}
                                onClick={handle}
                              >
                                Apply
                              </Button>
                            </Grid>
                          </>) :
                            (
                              cType === "code" ?
                                <>
                                  <Grid container spacing={1} direction="row">
                                    <Grid item xs={12}>
                                      <p style={{ paddingTop: "24px", marginBottom: "0px" }}>Enter Coupon</p>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Autocomplete
                                        id="coupon"
                                        key={couponKey}
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            let obj = JSON.parse(
                                              JSON.stringify(newValue, null, " ")
                                            );
                                            setCoupon(obj?.id as Number);
                                            setCouponCode(obj?.code as string);
                                            // setCouponType(obj.is_red_cash);

                                          }
                                        }}
                                        inputValue={couponCode}
                                        options={[]}
                                        freeSolo
                                        blurOnSelect
                                        onInputChange={(event, newInputValue) => {
                                          clearTimeout(timer.current);
                                          setCouponCode(newInputValue);
                                          timer.current = setTimeout(() => {
                                            //   getCoupons(
                                            //     `?code=${newInputValue}&org_type=homedx`
                                            //   );
                                          }, 1000);
                                          if (newInputValue === "") {
                                            setCoupon(0);
                                            setDiscount(0);
                                          }
                                        }}
                                        getOptionLabel={(option: any) => option?.code}
                                        //   getOptionDisabled={(option: any) =>
                                        //     option?.minimum_price > totalPrice-redProMembershipDiscount

                                        //   }

                                        renderInput={(params) => (
                                          <TextField
                                            className="input"
                                            {...params}
                                            placeholder="Enter Coupon"
                                            variant="outlined"
                                            inputProps={{
                                              ...params.inputProps,
                                              onKeyDown: (e) => {
                                                if (e.key === 'Enter') {
                                                  e.stopPropagation();
                                                }
                                              },
                                            }}
                                            style={{
                                              width: "100%",
                                              margin: "0",
                                              padding: "0",
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                      //   disabled={redCashCheck === true}
                                      //   onClick={applyCoupon}
                                      >
                                        Apply
                                      </Button>
                                    </Grid>
                                    {/* {redCashCheck === true ? <p style={{ color: "black", fontSize: "12px", paddingLeft: "4px" }}>Oops! Coupon Can't be applied as you are using RedCash.</p> : ""} */}
                                  </Grid>
                                </> :
                                (cType === "referral" ?
                                  <>
                                    <Grid container spacing={1} direction="row">
                                      <Grid item xs={12}>
                                        <p style={{ paddingTop: "24px", marginBottom: "0px" }}>Enter Referral Coupon Code</p>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <TextField
                                          className="input"
                                          name="manualCouponCode"
                                          type="text"
                                          // value={manualCouponCode}
                                          variant="outlined"
                                          style={{ width: "100%", margin: "0" }}
                                          inputProps={{ maxLength: 100 }}
                                          // onChange={(e) =>
                                          //   setManualCouponCode(e.target.value as string)
                                          // }
                                          placeholder="Type Referral Coupon Code"
                                        />
                                        {/* {(couponReferralValidation?.already_applied === true && couponReferralValidation?.message !== "This coupon is invalid") ? <p style={{ color: "#4e1212", fontSize: "14px" }}>{couponReferralValidation?.message}</p> : (couponReferralValidation?.already_applied === false ? <p style={{ color: "#091999", fontSize: "14px" }}>Code Applied Successfully</p> : (couponReferralValidation?.message === "This coupon is invalid" ? <p style={{ color: "black", fontSize: "14px" }}>{couponReferralValidation?.message}</p> : ""))} */}
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Button
                                          variant="contained"
                                          fullWidth
                                          color="primary"
                                        // disabled={redCashCheck === true}
                                        // onClick={applyCouponManually}
                                        >
                                          Apply
                                        </Button>
                                      </Grid>
                                      {/* {redCashCheck === true ? <p style={{ color: "black", fontSize: "12px", paddingLeft: "4px" }}>Oops! Coupon Can't be applied as you are using RedCash.</p> : ""} */}
                                    </Grid>
                                  </>
                                  :
                                  <>

                                  </>
                                )
                            )
                          }

                        </Grid>
                      </td>

                      <td
                        className="cell"
                        align="right"
                        style={{ width: "20%" }}
                      >
                        Discount
                      </td>
                      <td
                        className="cell"
                        align="center"
                        style={{ width: "20%" }}
                      >
                        {
                          inputList &&
                          inputList.length > 0 &&
                          inputList.map((customerDetails: any, index: number) => {
                            return (
                              <>
                                {customerDetails?.customerPackage &&
                                  customerDetails?.customerPackage.length > 0 &&
                                  customerDetails?.customerPackage.map(
                                    (packageItem: any, index: any) => {
                                      return (
                                        <>
                                          ₹ {
                                            cApply === true ? discountPrice && discountPrice.counpon_discount : packageItem?.MRP - packageItem?.mbt_price
                                          }
                                        </>
                                      )
                                    })}
                              </>
                            )
                          })
                        }
                        {/* ₹ {partnerCodeDiscount ? Math.round(discount) : Math.floor(discount)} */}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="cell"
                        align="right"
                        style={{ width: "20%" }}
                      >
                        Smart Package Discount
                      </td>
                      <td
                        className="cell"
                        align="center"
                        style={{ width: "20%" }}
                      >
                        ₹{" "}
                        {discountDueSmartPackage > 0
                          ? Math.floor(discountDueSmartPackage)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="cell"
                        align="right"
                        style={{ width: "20%" }}
                      >
                        Referral Points Discount
                      </td>
                      <td
                        className="cell"
                        align="center"
                        style={{ width: "20%" }}
                      >
                        ₹{" "}
                        {referralPoints !== undefined
                          ? Math.floor(referralPointsDiscount)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="cell"
                        align="right"
                        style={{ width: "20%" }}
                      >
                        RedCash Discount
                      </td>
                      <td
                        className="cell"
                        align="center"
                        style={{ width: "20%" }}
                      >
                        {/* ₹ {redDisc > 0 ? Math.floor(redDisc) : 0} */}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="cell"
                        align="right"
                        style={{ width: "20%" }}
                      >
                        VIP Membership Discount
                      </td>
                      <td
                        className="cell"
                        align="center"
                        style={{ width: "20%" }}
                      >
                        ₹ {redProMembershipDiscount > 0 ? Math.floor(redProMembershipDiscount) : 0}
                      </td>
                    </tr>


                    <tr>
                      <td className="cell" align="right">
                        Phlebo Cost
                      </td>
                      <td className="cell" align="center">
                        ₹ {extraCharge ? `+${phleboCost}` : "0"}
                      </td>
                    </tr>
                    <tr>
                      {/* {requestedForHardCopy==="true"?(
                              <>
                               <td className="cell" align="right">
                              Hard Copy Cost
                            </td>
                            <td className="cell" align="center">
                              ₹ { "150"}
                            </td>
                              </>
                            ):""} */}

                    </tr>
                    <tr>
                      <td className="cell" align="right">
                        <h4 className="totalPrice">Total Price</h4>
                      </td>
                      <td className="cell" align="center">
                        <h4 className="totalPrice">

                          {
                            inputList &&
                            inputList.length > 0 &&
                            inputList.map((customerDetails: any, index: number) => {
                              return (
                                <>
                                  {customerDetails?.customerPackage &&
                                    customerDetails?.customerPackage.length > 0 &&
                                    customerDetails?.customerPackage.map(
                                      (packageItem: any, index: any) => {
                                        return (
                                          <> ₹{
                                            cApply == true ? discountPrice && discountPrice.final_total_price : totalPrice
                                          }
                                          </>
                                        )
                                      })}
                                </>
                              )
                            })
                          }
                          {/* ₹{
                            cApply == true ? discountPrice && discountPrice.final_total_price : discountPrice && discountPrice.total_price_package
                          } */}

                          {/* {redDisc > 0
                                  ? (Math.floor(totalPrice) - Math.floor(redDisc) - Math.floor(referralPointsDiscount) - Math.floor(redProMembershipDiscount) + Math.floor(phleboCost) > 0 ? Math.floor(totalPrice) - Math.floor(redDisc) - Math.floor(referralPointsDiscount) - Math.floor(redProMembershipDiscount) + Math.floor(proMembershipPrice) + Math.floor(phleboCost)+ Math.floor(hardCopyPrice)   : (checkedWay !== "" ? Math.floor(proMembershipPrice) : 0))
                                  : (Math.floor(totalPrice) - Math.floor(referralPointsDiscount) - Math.floor(redProMembershipDiscount) + Math.floor(phleboCost) > 0 ? Math.floor(totalPrice) - Math.floor(referralPointsDiscount) - Math.floor(redProMembershipDiscount) + Math.floor(proMembershipPrice)+ Math.floor(phleboCost) +Math.floor(hardCopyPrice) : (checkedWay !== "" ? Math.floor(proMembershipPrice) : 0))} */}
                        </h4>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
    </Grid>
  )
}
export default TimeSlotsPackageDetails