//Authentication
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SnackBar from "../components/SnackBar";
import Login from "../containers/login";
import SecureStorage from "./SecureStorage";
import Dash from "../containers/dash";
import jwt_decode from "jwt-decode";
import { checkUser } from "../actions/loginActions";
import { connect } from "react-redux";


//Admin Dashboard
import AdminDashboard from '../containers/Admin-agent'
import AdminBookingTable from '../Admin/page/BookingTable/Filter'
import NewBooking from "../Admin/components/Bookings/NewBooking/NewBooking";
import AdminViewPage from '../Admin/components/Bookings/ViewPage/index';
import AdminLeadDetails from '../Admin/page/LeadDetails/leadFilter'
import AdminTimeSlots from '../Admin/page/TimeSlots/TimeSlotsFilter'
import AdminPhleboOnboardingDash from "../Admin/page/ManageUser/Index";
import AdminEditBookingPage from '../Admin/components/Bookings/EditBooking/EditBooking'
import AdminManagePinCode from '../Admin/page/ManagePincode'
import AdminManageInvoice from '../Admin/page/Invoice'
import CreateCamp from '../Admin/components/Bookings/CreateCamp/NewCamp'


//Phlebo Admin Dashboard
import PhleboAdminDashboard from '../containers/PhleboAdmin-agent'
import PhleboAdminCampTable from '../PhleboAdmin/page/campTable/Filter'
import PhleboAdminNewBooking from "../PhleboAdmin/components/Bookings/NewBooking/NewBooking";
import PhelboAdminCreateCamp from "../PhleboAdmin/components/Bookings/CreateCamp/NewCamp";
import PhleboAdminUpdateCamp from "../PhleboAdmin/components/Bookings/EditCamp/EditCamp";
import PhleboAdminViewCamp from "../PhleboAdmin/components/Bookings/viewCamp";


//Route Manager Dashboard
import RouteManagerDashboard from '../containers/RouteManager-agent'
import RouteManagerNewBooking from "../RouteManager/components/Bookings/NewBooking/NewBooking";
import RouteManagerBookingTable from '../RouteManager/page/BookingTable/Filter'

//CustomerExecutive Dashboard
import CustomerExecutiveDashboard from '../containers/CustomerExecutive-agent'
import CustomerExecutiveBookingTable from '../CustomerExecutive/page/BookingTable/Filter'
import CustomerExecutiveNewBooking from "../CustomerExecutive/components/Bookings/NewBooking/NewBooking";
import CustomerExecutiveViewPage from '../CustomerExecutive/components/Bookings/ViewPage/index'

//Phlebo Dashboard
import PhelboDashboard from '../containers/Phlebo-agent'
import PhleboCampTable from '../Phlebo/page/BookingTable/Filter'
import PlheboNewCustomer from "../Phlebo/components/Bookings/New Customer/NewCustomer"
import PhleboViewCustomer from "../Phlebo/components/Bookings/viewCamp/index";
import PhleboEditCustomer from "../Phlebo/components/Bookings/EditCustomer/EditCustomer";

function AuthenticatedRoutes(props) {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }

  const queryString = window.location.search;
  const token = SecureStorage.getItem("token");
  if (token) {
    return (
      <Routes>
        <Route path="/" element={<Dash path={path} query={query} />} />
      </Routes>
    );
  } else {
    return <Route render={() => <Navigate to="/" />} />;
  }
}

const HasRole = ({ children, role }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fetchToken = urlParams.get('token')

  if (fetchToken) {
    SecureStorage.setItem("token", fetchToken);
  }
  const token = SecureStorage.getItem("token");
  if (!token) {
    window.location = "/";
  }
  const decoded = jwt_decode(token);
  let roleChange = decoded.userGroup;
  if (role === "ADMIN") {
    role = roleChange;
  } 
  else if (role === "PHA") {
    role = roleChange;
  } 
  else if (role === "RM") {
    role = roleChange;
  } 
  else if (role === "CE") {
    role = roleChange;
  } 
  else if (role === "PH") {
    role = roleChange;
  } 
  else {
  }
  if (decoded.userGroup !== role) {
    window.location = "/dashboard";
  } else {
    return children;
  }
  return <div>Redirecting....</div>;
};

function AppRoutes(props) {

  return (
    <BrowserRouter>
        <SnackBar />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="dashboard" element={<AuthenticatedRoutes />} />
          <Route
            path="/dashboard/admin/*"
            element={

              <HasRole role="ADMIN">
                <AdminDashboard>
                  <Routes>
                    <Route
                      path="/"
                      element={<AdminBookingTable />}
                    />
                    <Route
                      path="/lead-details"
                      element={<AdminLeadDetails />}
                    />
                    <Route
                      path="/newbooking"
                      element={<NewBooking />}
                    />
                    <Route
                      path="/viewbooking/:id"
                      element={<AdminViewPage />}
                    />
                    <Route
                      path="/editbooking/:id"
                      element={<AdminEditBookingPage />}
                    />
                    <Route
                      path="/manage-user"
                      element={<AdminPhleboOnboardingDash />}
                    />
                    <Route
                      path="/timeslot"
                      element={<AdminTimeSlots />}
                    />
                    <Route
                      path="/manage-pincode"
                      element={<AdminManagePinCode />}
                    />
                    <Route
                      path="/invoice-creator"
                      element={<AdminManageInvoice />}
                    />
                    <Route path="/create-camp" element={<CreateCamp />} />
                  </Routes>
                </AdminDashboard>
              </HasRole>
            } />
          <Route
            path="/dashboard/pha/*"
            element={

              <HasRole role="PHA">
                <PhleboAdminDashboard>
                  <Routes>
                    <Route
                      path="/"
                      element={<PhleboAdminCampTable />}
                    />
                    <Route
                      path="/newbooking"
                      element={<PhleboAdminNewBooking />}
                    />
                    <Route
                      path="/create-camp"
                      element={<PhelboAdminCreateCamp />}
                    />
                    <Route
                      path="/updatecamp/:id"
                      element={<PhleboAdminUpdateCamp />}
                    />
                     <Route
                      path="/viewcamp/:id"
                      element={<PhleboAdminViewCamp />}
                    />
                  </Routes>
                </PhleboAdminDashboard>
              </HasRole>
            } />
          <Route
            path="/dashboard/rm/*"
            element={

              <HasRole role="RM">
                <RouteManagerDashboard>
                  <Routes>
                    <Route
                      path="/"
                      element={<RouteManagerBookingTable />}
                    />
                    <Route
                      path="/newbooking"
                      element={<RouteManagerNewBooking />}
                    />
                  </Routes>
                </RouteManagerDashboard>
              </HasRole>
            } />
          <Route
            path="/dashboard/ce/*"
            element={

              <HasRole role="CE">
                <CustomerExecutiveDashboard>
                  <Routes>
                    <Route
                      path="/"
                      element={<CustomerExecutiveBookingTable />}
                    />
                    <Route
                      path="/newbooking"
                      element={<CustomerExecutiveNewBooking />}
                    />
                    <Route
                      path="/viewbooking"
                      element={<CustomerExecutiveViewPage />}
                    />

                  </Routes>
                </CustomerExecutiveDashboard>
              </HasRole>
            } />
          <Route
            path="/dashboard/ph/*"
            element={

              <HasRole role="PH">
                <PhelboDashboard>
                  <Routes>
                    <Route
                      path="/"
                      element={<PhleboCampTable />}
                    />
                    <Route
                      path="/newcustomer"
                      element={<PlheboNewCustomer />}
                    />
                    <Route
                      path="/editcustomer/:id"
                      element={<PhleboEditCustomer />}
                    />
                     <Route
                      path="/viewcustomer/:id"
                      element={<PhleboViewCustomer />}
                    />
                  </Routes>
                </PhelboDashboard>
              </HasRole>
            } />
        
        </Routes>
    </BrowserRouter>
  );
}
export default connect(null, { checkUser })(AppRoutes);
