import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Grid, TextField } from "@mui/material";
import LeadTable from "../LeadDetails/leadTable";
import { getAllLeads } from '../../action/adminAction';
import { genrateLeadFilter } from '../../../helpers/generateUrl';
import {Button } from "@mui/material";
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
})
interface Props {
    getAllLeads:any;
    leadDetails:any;
}
const LeadDetailsFilter: React.FC<Props> = ({
    getAllLeads,
    leadDetails
}) => {
    const classes = useStyles();
    const [inputList, setInputList] = useState<any>([
        {
            leadId: "",
            leadName: "",
            leadEmail: "",
            contactNumber: "",
        },
    ]);

    useEffect(() => {
        getAllLeads()
    }, [])

    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    }

    const handleSubmit = async (e: any) => {
        const body = {
            _id: inputList[0].leadId,
            patient_name: inputList[0].leadName,
            email: inputList[0].leadEmail,
            mobile: inputList[0].contactNumber,
        }
        const url = genrateLeadFilter(body).substring(2);
        getAllLeads(url ? `?${url}` : "")
    }


    const handleReset = () => {
        const resertData = { leadId: "", leadName: "", leadEmail: "", contactNumber: "", }
        setInputList([{ leadId: "", leadName: "", leadEmail: "", contactNumber: "", }])
        getAllLeads()
    }
    const handle = (e: any, index: any) => {
        handleInputChange(e, index)
        handleSubmit(e)
    }
    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            //   filterBookings(e);
        }
    };
    // useEffect(() => {
    //     getAllLeads()
    // }, [])
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            {inputList.map((x: any, index: number) => {
                return (
                    <Grid container direction="row" spacing={2} >
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                className="input"
                                label="Lead Id"
                                name="leadId"
                                id="outlined-start-adornment"
                                value={x.leadId}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                 onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    const inputVal = e.target.value;
                                    (inputVal.replace(/[^0-9]/g, ''))
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                className="input"
                                label="Name"
                                name="leadName"
                                value={x.leadName}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                 onChange={(e) => handle(e, index)}
                                id="outlined-start-adornment"
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                className="input"
                                label="Email"
                                id="outlined-start-adornment"
                                name="leadEmail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={x.leadEmail}
                                 onChange={(e) => handle(e, index)}
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                className="input"
                                label="Phone"
                                id="outlined-start-adornment"
                                name="contactNumber"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={x.contactNumber}
                                 onChange={(e) => handle(e, index)}
                                type="number"
                                onInput={(e: any) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}

                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                    </Grid>
                )
            })}
            <Grid container direction="row" spacing={2} justifyContent="center" marginTop={1}>
                <Grid item xs={12} sm={6} md={6}>
                    <Button
                    color="error"
                        style={{width: '100%', margin: "0px", padding: "5px" }}
                        variant="contained"
                        onClick={handleReset}
                    >
                        RESET
                    </Button>
                </Grid>
                <Grid item md={12} sm={4} xs={12}></Grid>
            </Grid>
            <LeadTable
                leadDetails={leadDetails}
            />
        </main>
    )
}
const mapStateToProps = (state: any) => ({
    leadDetails: state.AdminReducer.leadDetails,
});
export default connect(mapStateToProps, {
    getAllLeads
})(LeadDetailsFilter);