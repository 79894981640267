import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import SecureStorage from '../../config/SecureStorage'
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Tooltip from '@mui/material/Tooltip';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import KeyIcon from '@mui/icons-material/Key';
import Avatar from '@mui/material/Avatar';
import DeckIcon from '@mui/icons-material/Deck';
import UpdatePassword from './updatePasswordModal'

const drawerWidth = 240;

const useStyles = makeStyles({
  dashbord: {
    // color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "20px 0px 10px 10px",
    color: "#ffffff"
  },
})


const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
interface Props {
  passwordStatus: boolean;
}

const CustomerExecutiveDashboard: React.FC<Props> = ({
  passwordStatus,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const mediaMatch = window.matchMedia('(min-width: 500px)');
  const [matches, setMatches] =React.useState(mediaMatch.matches);
  const [openPassModal, setOpenPassModal] = React.useState(false);
  const handleOpenPassModal = () => setOpenPassModal(true);
  const handleClosePassModal = () => setOpenPassModal(false);
  const settings = [{ name: 'Profile', icon: "SettingsIcon" }, { name: 'Change Password', icon: "KeyIcon" }];
  const fullname=SecureStorage.getItem('fullname')
  const getAvataricon=(icon:any)=> {
    switch(icon){
      case "Profile":
        return (<SettingsIcon/>);
      case "Change Password":
      return (<KeyIcon/>);
      case "Logout":
        return (<ExitToAppIcon/>)
        default:
          return ""
    }
  }



  const handleLogout = () => {
    SecureStorage.setItem('token',"")
    navigate('/')
    // dispatch(panelUserLogout());
    // dispatch(logout());
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    const handler = (e:any) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const handleAvatarButton =(name:any)=>{
    console.log(name)
    if(name==="Change Password"){
     setOpenPassModal(true)
    }
  }
  return (
    <Box sx={{ display: 'flex' }}>
                <UpdatePassword open={openPassModal} setOpen={setOpenPassModal} handleClose={handleClosePassModal} handleOpen={handleOpenPassModal}/>

      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ background: "#07639d" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Medi Bio Test

          </Typography>
          <Box sx={{ flexGrow: 0,position:"absolute",right:matches?"3rem":"0.5rem"}}>
            <ListItem button onClick={handleOpenUserMenu} >
              <ListItemAvatar style={{ minWidth: "30px" }}>
                <Person2OutlinedIcon />
              </ListItemAvatar>
              <ListItemText primary={fullname} />
            </ListItem>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                   <Avatar sx={{m:1}} >
                   {getAvataricon(setting.name)}  
                   </Avatar>
                  <Typography textAlign="center" onClick={()=>handleAvatarButton(setting.name)}>{setting.name}</Typography>
                </MenuItem>
              ))}
              <Divider/>
              <Link to="/">
              <MenuItem key="logout" onClick={handleLogout}>
                   <Avatar sx={{m:1}}>
                    <ExitToAppIcon/>
                   </Avatar>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
                </Link>
            </Menu>

          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} onClick={handleDrawerClose} >
        <DrawerHeader sx={{ background: "#2969b3" }}>
          <span className={classes.dashbord}>MEDI DASHBOARD</span>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon style={{ color: "#fff" }}/> : <ChevronLeftIcon style={{ color: "#fff" }}/>}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Link to="/dashboard/ce">
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                 <Tooltip title="Home" arrow>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <HomeIcon style={{ color: "#07639d" }} />
                </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/newbooking">
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                 <Tooltip title="New Booking" arrow>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <AddCircleIcon style={{ color: "#07639d" }} />
                </ListItemIcon>
                </Tooltip>
                <ListItemText primary="New Booking" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Logout" arrow>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >

                  <ExitToAppIcon style={{ color: "#07639d" }}/>
                </ListItemIcon>
              </Tooltip>
              <ListItemText  primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};
const mapStateToProps = (state: any) => ({
  passwordStatus: state.loginReducer.passwordStatus,
});

export default connect(mapStateToProps, {
})(CustomerExecutiveDashboard);
