import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Button, Card, Grid, MenuItem, Select, TextField } from "@mui/material";
import BasicDetails from "./basicDetails";
import TimeSlotsPackageDetails from "./TimeSlotsPackageDetails";
import ContactDetails from "./contactDetails";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import { getBookingTimeSlots, getPackageDetails, createBooking, getActivePinCode } from '../../../action/CustomerExecutiveAction'
import CustomerAddress from "./customerAddress";
import './Index.sass'
const useStyles = makeStyles({
  content: {
    width: "100%",
    flexGrow: 21,
  },
  slotContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  slot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
  },
  selectedSlot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
    cursor: "pointer",
    margin: "0.5rem",
  },
  packageLink: {
    color: "#ffffff",
    textDecoration: "none",
  },
})
interface Props {
  getBookingTimeSlots: any
  timeSlots: any;
  getPackageDetails: any;
  packageDetails: any;
  createBooking: any;
  getActivePinCode: any;
  activePincodeList: any;
}
const NewBooking: React.FC<Props> = ({
  getBookingTimeSlots,
  timeSlots,
  getPackageDetails,
  packageDetails,
  createBooking,
  getActivePinCode,
  activePincodeList,
}) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState<any>(0)
  const [error, setError] = useState("");
  const [selectedSlot, setSelectedSlot] = useState<Number>(0);
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [phleboCost, setPhleboCost] = useState<any>(0);
  const [cType, setCType] = useState<any>("none");
  const [cValue, setCValue] = useState<any>("");
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponKey, setCouponKey] = useState<any>(0);
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [partnerCodeDiscount, setPartnerCodeDiscount] = useState<boolean>(false);
  const [redProMembershipDiscount, setRedProMembershipDiscount] = useState<any>(0);
  const [redProMembershipDiscountPercentage, setRedProMembershipDiscountPercentage] = useState<any>(0);
  const [discountDueSmartPackage, setDiscountDueSmartPackage] =
    useState<any>(0);
  const [referralPointsDiscount, setReferralPointsDiscount] = useState<any>(0);
  const [referralPointsApplied, setReferralPointsApplied] = useState<any>(false);
  const [referralPoints, setReferralPoints] = useState<any>("");
  const [mobileNumber, setMobileNumber] = useState<any>("")
  const [alternateMobileNumber, setAlternateMobileNumber] = useState<any>("")
  const [whatsappNumber, setWhatsappNumber] = useState<any>("")
  const [email, setEmail] = useState<any>("")
  const [collectionDate, setCollectionDate] = useState<any>("")
  const [pincode, setPincode] = useState<any>("")
  const [customerAreaPincode, setCustomerAreaPincode] = useState<any>("")
  const [customerState, setCustomerState] = useState<any>("")
  const [customerCity, setCustomerCity] = useState<any>("")
  const [customerAddress, setCustomerAddress] = useState<any>("")
  const [customerLandMarks, setCustomerLandMarks] = useState<any>("")

  const timer = useRef<any>(0);
  const salulationList = [
    { title: 'Mr', value: "Mr" },
    { title: 'Mrs', year: "Mrs" },
    { title: 'Ms', year: "Ms" },
    { title: 'Dr', year: "Dr" },
    { title: 'Master', year: "Master" },
  ];
  const [inputList, setInputList] = useState<any>([
    {
      customerDesignation: "",
      customerName: "",
      customerAge: "",
      customerGender: "",
      customerPackage: [],
      packagePrice: 0,
      dateOfBirth: "",
      passport: "",

    },
  ]);

  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        customerDesignation: "",
        customerName: "",
        customerAge: "",
        customerGender: "",
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
      },
    ]);
  };
  //api calls
  useEffect(() => {
    getBookingTimeSlots()
    getPackageDetails()
    getActivePinCode()
  }, [])
  const handleSubmit = async () => {
    let packagesIds: any = [];
    inputList &&
      inputList[0]?.customerPackage.map((item: any) => {
        packagesIds.push(item._id);
      });

    const body = {
      title: inputList[0].customerDesignation,
      customer_name: inputList[0].customerName,
      age: Number(inputList[0].customerAge),
      gender: inputList[0].customerGender,
      passport_no: inputList[0].customerGender,
      aadhar_no: inputList[0].customerGender,
      package_id: packagesIds,
      mobile_no: mobileNumber,
      alternate_no: alternateMobileNumber,
      whats_app_no: whatsappNumber,
      email: email,
      time_slots_id: selectedSlot,
      city_id: customerCity,
      customer_pincode:customerAreaPincode,
      customer_state:customerState,
      customer_address:customerAddress,
      customer_landmarks:customerLandMarks,
      // phlebos_id : ["640c8f83eeeb6cc366eab736","640c8fa3eeeb6cc366eab73a"],
      date: moment(new Date()).format("YYYY-MM-DD")
    }
    await createBooking(body)
    navigate("/dashboard/customer/viewbooking");

  }
  console.log(inputList)
  return (
    <main className={classes.content}>
      <BasicDetails
        error={error}
        setError={setError}
        inputList={inputList}
        setInputList={setInputList}
        salulationList={salulationList}
        packageDetails={packageDetails}
        totalPrice={totalPrice}
        setTotalPrice={setTotalPrice}
        collectionDate={collectionDate}
        setCollectionDate={setCollectionDate}
        activePincodeList={activePincodeList}
        pincode={pincode}
        setPincode={setPincode}
        setCustomerAreaPincode={setCustomerAreaPincode}
        setCustomerState={setCustomerState}
        setCustomerCity={setCustomerCity}
      />
      <ContactDetails
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
        alternateMobileNumber={alternateMobileNumber}
        setAlternateMobileNumber={setAlternateMobileNumber}
        whatsappNumber={whatsappNumber}
        setWhatsappNumber={setWhatsappNumber}
        email={email}
        setEmail={setEmail}
        error={error}
      />
      <TimeSlotsPackageDetails
        timeslots={timeSlots}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        inputList={inputList}
        setInputList={setInputList}
        cValue={cValue}
        setCValue={setCValue}
        singlePackage={singlePackage}
        setSinglePackage={setSinglePackage}
        isPaneOpen={isPaneOpen}
        setPaneOpen={setPaneOpen}
        cType={cType}
        setCType={setCType}
        couponKey={couponKey}
        price={price}
        setPrice={setPrice}
        coupon={coupon}
        setCoupon={setCoupon}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
        discount={discount}
        setDiscount={setDiscount}
        partnerCodeDiscount={partnerCodeDiscount}
        setPartnerCodeDiscount={setPartnerCodeDiscount}
        redProMembershipDiscount={redProMembershipDiscount}
        setRedProMembershipDiscount={setRedProMembershipDiscount}
        discountDueSmartPackage={discountDueSmartPackage}
        setDiscountDueSmartPackage={setDiscountDueSmartPackage}
        referralPointsDiscount={referralPointsDiscount}
        setReferralPointsDiscount={setReferralPointsDiscount}
        referralPointsApplied={referralPointsApplied}
        setReferralPointsApplied={setReferralPointsApplied}
        referralPoints={referralPoints}
        setReferralPoints={setReferralPoints}
        extraCharge={extraCharge}
        phleboCost={phleboCost}
      />
      <CustomerAddress
        customerAreaPincode={customerAreaPincode}
        setCustomerAreaPincode={setCustomerAreaPincode}
        customerState={customerState}
        setCustomerState={setCustomerState}
        customerCity={customerCity}
        setCustomerCity={setCustomerCity}
        customerAddress={customerAddress}
        setCustomerAddress={setCustomerAddress}
        customerLandMarks={customerLandMarks}
        setCustomerLandMarks={setCustomerLandMarks}
        error={error}
        setError={setError}
      />
      <Grid
        container
        alignItems="center"
        spacing={3}
        style={{ margin: "1rem", alignItems: "center", justifyContent: "center" }}
      >
        <Grid item md={6}>
          <Button fullWidth variant="contained" onClick={handleSubmit}>Proceed for Booking</Button>
        </Grid>

      </Grid>
    </main>
  )

}
const mapStateToProps = (state: any) => ({
  timeSlots: state.CustomerExecutiveReducer.timeSlots,
  packageDetails: state.CustomerExecutiveReducer.packageDetails,
  activePincodeList: state.CustomerExecutiveReducer.activePincodeList,
});
export default connect(mapStateToProps, {
  getBookingTimeSlots,
  getPackageDetails,
  createBooking,
  getActivePinCode
})(NewBooking);