import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Button, Card, Grid, MenuItem, Select, TextField } from "@mui/material";
import BasicDetails from "./basicDetails";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import { getPackageDetails, getDoctorDetails, getActivePinCode, getPhleboDetails, getCampDetailsById, updateCamp } from '../../../action/phleboAdminAction'
import CustomerAddress from "./campAddress";
import './Index.sass'
import CampAddress from "./campAddress";
import AssignPhelbo from "./assignPhelbo";
import AssignDoctors from "./assignDoctor";
import { useParams } from "react-router-dom";
const useStyles = makeStyles({
  content: {
    width: "100%",
    flexGrow: 21,
  },
  slotContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  slot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
  },
  selectedSlot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
    cursor: "pointer",
    margin: "0.5rem",
  },
  packageLink: {
    color: "#ffffff",
    textDecoration: "none",
  },
})
interface Props {
  // timeSlots: any;
  getPackageDetails: any;
  packageDetails: any;
  // createBooking: any;
  getActivePinCode: any;
  activePincodeList: any;
  // createDBooking: any;
  // getDiscountBooking: any;
  // discountPrice: any;
  getDoctorDetails: any;
  doctorDetails: any;
  getPhleboDetails: any;
  phleboDetailsList: any;
  updateCamp: any;
  getCampDetailsById: any;
  campList: any;
}
const UpdateCamp: React.FC<Props> = ({
  // timeSlots,
  getPackageDetails,
  packageDetails,
  // createBooking,
  getActivePinCode,
  activePincodeList,
  // createDBooking,
  // getDiscountBooking,
  // discountPrice,
  getDoctorDetails,
  doctorDetails,
  getPhleboDetails,
  phleboDetailsList,
  updateCamp,
  getCampDetailsById,
  campList,

}) => {
  console.log("phleboDetailsList", phleboDetailsList)

  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams()
  const [totalPrice, setTotalPrice] = useState<any>(0)
  const [error, setError] = useState("");
  const [selectedSlot, setSelectedSlot] = useState<Number>(0);
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [phleboCost, setPhleboCost] = useState<any>(0);
  const [cType, setCType] = useState<any>("none");
  const [cValue, setCValue] = useState<any>("");
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponKey, setCouponKey] = useState<any>(0);
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [partnerCodeDiscount, setPartnerCodeDiscount] = useState<boolean>(false);
  const [redProMembershipDiscount, setRedProMembershipDiscount] = useState<any>(0);
  const [redProMembershipDiscountPercentage, setRedProMembershipDiscountPercentage] = useState<any>(0);
  const [discountDueSmartPackage, setDiscountDueSmartPackage] =
    useState<any>(0);
  const [referralPointsDiscount, setReferralPointsDiscount] = useState<any>(0);
  const [referralPointsApplied, setReferralPointsApplied] = useState<any>(false);
  const [referralPoints, setReferralPoints] = useState<any>("");
  const [mobileNumber, setMobileNumber] = useState<any>("")
  const [alternateMobileNumber, setAlternateMobileNumber] = useState<any>("")
  const [whatsappNumber, setWhatsappNumber] = useState<any>("")
  const [email, setEmail] = useState<any>("")
  const [collectionDate, setCollectionDate] = useState<any>("")
  const [pincode, setPincode] = useState<any>("")
  const [campName, setCampName] = useState<any>("")
  const [campAreaPincode, setCampAreaPincode] = useState<any>("")
  const [campState, setCampState] = useState<any>("")
  const [campCity, setCampCity] = useState<any>("")
  const [campAddress, setCampAddress] = useState<any>("")
  const [campLandMarks, setcampLandMarks] = useState<any>("")
  const [percentage, setPercentage] = useState<any>("")
  const [packagesIds, setPackagesIds] = useState<any>("")
  const [doctorId, setDoctorId] = useState<any>({fullname:""})
  const [plheboName, setPhleboName] = useState<any>("")
  const [cApply, setCApply] = useState<boolean>(false)
  const [phelboLoginId, setPhelboLoginId] = useState<any>("")
  const [phelboLoginPassword, setPhelboLoginPassword] = useState<any>("")
  const [manualAddLoginId, setManualAddLoginId] = useState<any>("")
  const [manualAddLoginPassword, setManualAddLoginPassword] = useState<any>("")
  const [campData, setCampData] = useState<any>("")

  const timer = useRef<any>(0);
  const salulationList = [
    { title: 'Mr', value: "Mr" },
    { title: 'Mrs', year: "Mrs" },
    { title: 'Ms', year: "Ms" },
    { title: 'Dr', year: "Dr" },
    { title: 'Master', year: "Master" },
  ];
  const [inputList, setInputList] = useState<any>([
    {
      address: "",
      city: "",
      state: "",
      pincode: "",
      packageId: "",
      doctorId: "",
      plheboName: "",
    },
  ]);

  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        address: "",
        city: "",
        state: "",
        pincode: "",
        packageId: "",
        doctorId: "",
        plheboName: "",
      },
    ]);
  };

  useEffect(() => {
    getPackageDetails()
    getPhleboDetails()
  }, [])

  useEffect(() => {
    getCampDetailsById(`?camp_code=${params.id}`)
  }, [params])
  useEffect(() => {
    if (campList && campList?.results && campList?.results.length > 0) {
      setCampData(campList?.results[0])
    }
  }, [campList])
  useEffect(() => {
    if (campList && campList?.results && campList?.results.length > 0) {
      setCampData(campList?.results[0])
    }
  }, [campList])
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const body = {
      camp_date: moment(new Date()).format("YYYY-MM-DD"),
      name: campName,
      address: campAddress,
      city: campCity,
      state: campState,
      pincode: campAreaPincode,
      package_id: packagesIds._id,
      doctor_id: doctorId._id,
      phlebo_id: plheboName._id,
    }
    await updateCamp(body, getCampDetailsById)
  }

  useEffect(() => {
    if (campData) {
      console.log("doctorNamee",campData.doctor_detail && campData.doctor_detail && campData.doctor_detail.length >
        0 && campData.doctor_detail.map((data1: any, index: any) => data1.fullname))
      setCollectionDate(moment.utc(campList?.collection_date).format("YYYY-MM-DD"))
      setCampName(campData?.camp_name)
      setCampAddress(campData?.address)
      setPincode(campData?.pincode)
      setCampAreaPincode(campData?.pincode)
      setCampState(campData?.state)
      setCampCity(campData?.city)
      setPackagesIds(campData?.package?.fullname)
      setPhleboName(campData?.phlebo_details?.fullname)
      setPhelboLoginPassword(campData?.phlebo_details?.password)
      setDoctorId( campData.doctor_detail && campData.doctor_detail && campData.doctor_detail.length >
        0 && campData.doctor_detail[0])
      // setDoctorId(
      //   campData.doctor_detail && campData.doctor_detail && campData.doctor_detail.length >
      //   0 && campData.doctor_detail.map((data1: any, index: any) => data1.fullname
      //   )

      // )
    }
  }, [campData])
  console.log("doctorName", campData)
  const handleReset = () => {
    setCValue('')
    setCType('none')
  }
  return (
    <main className={classes.content}>
      <form onSubmit={handleSubmit}>
        <BasicDetails
          error={error}
          setError={setError}
          inputList={inputList}
          setInputList={setInputList}
          salulationList={salulationList}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          collectionDate={collectionDate}
          setCollectionDate={setCollectionDate}
          pincode={pincode}
          setPincode={setPincode}
          setCampAreaPincode={setCampAreaPincode}
          setCampState={setCampState}
          setCampCity={setCampCity}
          getActivePinCode={getActivePinCode}
          activePincodeList={activePincodeList}
          handleReset={handleReset}
          campName={campName}
          setCampName={setCampName}
          campData={campData}
        />

        <CampAddress
          campAreaPincode={campAreaPincode}
          setCampAreaPincode={setCampAreaPincode}
          campState={campState}
          setCampState={setCampState}
          campCity={campCity}
          setCampCity={setCampCity}
          campAddress={campAddress}
          setCampAddress={setCampAddress}
          campLandMarks={campLandMarks}
          setcampLandMarks={setcampLandMarks}
          error={error}
          setError={setError}
          campData={campData}
        />

        <AssignDoctors
          error={error}
          setError={setError}
          inputList={inputList}
          setInputList={setInputList}
          salulationList={salulationList}
          packageDetails={packageDetails}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          collectionDate={collectionDate}
          setCollectionDate={setCollectionDate}
          //  activePincodeList={activePincodeList}
          pincode={pincode}
          setPincode={setPincode}
          setCampAreaPincode={setCampAreaPincode}
          setCampState={setCampState}
          setCampCity={setCampCity}
          //  getActivePinCode={getActivePinCode}
          getPackageDetails={getPackageDetails}
          getDoctorDetails={getDoctorDetails}
          handleReset={handleReset}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          alternateMobileNumber={alternateMobileNumber}
          setAlternateMobileNumber={setAlternateMobileNumber}
          doctorDetails={doctorDetails}
          doctorId={doctorId}
          setDoctorId={setDoctorId}
          packagesIds={packagesIds}
          setPackagesIds={setPackagesIds}
          campData={campData}
        />

        <AssignPhelbo
          phelboLoginId={phelboLoginId}
          setPhelboLoginId={setPhelboLoginId}
          phelboLoginPassword={phelboLoginPassword}
          setPhelboLoginPassword={setPhelboLoginPassword}
          manualAddLoginId={manualAddLoginId}
          setManualAddLoginId={setManualAddLoginId}
          manualAddLoginPassword={manualAddLoginPassword}
          setManualAddLoginPassword={setManualAddLoginPassword}
          plheboName={plheboName}
          setPhleboName={setPhleboName}
          getPhleboDetails={getPhleboDetails}
          phleboDetailsList={phleboDetailsList}
          campData={campData}
        />

        <Grid
          container
          alignItems="center"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Button fullWidth variant="contained" type="submit">UPDATE CAMP</Button>
          </Grid>

        </Grid>
      </form>
    </main>
  )

}
const mapStateToProps = (state: any) => ({
  packageDetails: state.PhleboAdminReducer.packageDetails,
  doctorDetails: state.PhleboAdminReducer.doctorDetails,
  activePincodeList: state.PhleboAdminReducer.activePincodeList,
  phleboDetailsList: state.PhleboAdminReducer.phleboDetailsList,
  campList: state.PhleboAdminReducer.campList,
});
export default connect(mapStateToProps, {
  getPackageDetails,
  getDoctorDetails,
  getActivePinCode,
  getPhleboDetails,
  updateCamp,
  getCampDetailsById,
})(UpdateCamp);