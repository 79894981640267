export const ACTIONS = {
    GET_MOBILE_OTP: "admin/get_mobile_otp",
    SET_LOADING: "admin/set_loading",
    SET_OTP_LOADING: "admin/set_Otp_loading",
    SET_EMAIL_AUTH_LOADING: "admin/set_email_loading",
    SET_PAN_LOADING: "admin/set_pan_loading",
    SET_PAN_LINK_LOADING: "admin/set_pan_link_loading",
    SET_BANK_AUTH_LOADING: "admin/set_bank_loading",
    SET_VALIDATE_OTP_LOADING:"admin/set_validate_otp_loading",
    SET_FACE_MATCHING_LOADING:"admin/set_face_matching_loading",
    SET_OCR_KYC_LOADING:"admin/set_ocr_kyc_loading",
    POST_VALIDATE_OTP_INPUT:"admin/post_validate_otp_input",
    SET_MOBILE_DETAILS_LOADING:"admin/set_mobile_details_loading",
    POST_MOBILE_DETAILS_DATA:"admin/post_mobile_details_data",
    POST_EMAIL_AUTHENTICATION_DATA:"admin/post_email_authentication_data",
    POST_BANK_AUTHENTICATION_DATA:"admin/post_bank_authentication_data",
    POST_PAN_AUTHENTICATION_DATA:"admin/post_pan_authentication_data",
    POST_PAN_LINK_WITH_AADHAAR_AUTHENTICATION_DATA:"admin/post_pan_link_with_aadhaar_authentication_data",
    VALIDATE_FACE_MATCHING:"admin/validate_face_matching",
    VALIDATE_OCR_KYC:"admin/validate_ocr_kyc",
    GET_USER_LIST:"admin/get_user_list",
    CREATE_NEW_USER:"admin/create_new_users",
    UPDATE_USER_PROFILE:"admin/update_user_profile",
    GET_PACKAGE_DETAILS: "admin/get_package_details",
    GET_DOCTOR_DETAILS: "admin/get_doctor_details",
    GET_ACTIVE_PINCODE:"admin/get_active_pincode",
    CREATE_NEW_CAMP:"admin/get_create_new_camp",
    GET_PHLEBO_DETAILS:"admin/phlebo",
    GET_CAMP_DETAILS:"admin/camp_details",
    UPDATE_CAMP:"admin/update_camp",
    DELETE_CAMP:"admin/deletecamp"
  };
  interface SetLoading {
    type: typeof ACTIONS.SET_OTP_LOADING;
    payload: boolean;
  }
  interface UpdateUserProfile {
    type: typeof ACTIONS.UPDATE_USER_PROFILE
    payload: Array<any>
  }
  interface SetMoileDetailsLoading {
    type: typeof ACTIONS.SET_MOBILE_DETAILS_LOADING;
    payload: boolean;
  }
  interface SetEmailLoading {
    type: typeof ACTIONS.SET_EMAIL_AUTH_LOADING;
    payload: boolean;
  }
  interface SetBankLoading {
    type: typeof ACTIONS.SET_BANK_AUTH_LOADING;
    payload: boolean;
  }
  interface SetPanLoading {
    type: typeof ACTIONS.SET_BANK_AUTH_LOADING;
    payload: boolean;
  }
  interface SetPanLinkLoading {
    type: typeof ACTIONS.SET_PAN_LOADING;
    payload: boolean;
  }
  interface SetFaceMathcingLoading {
    type: typeof ACTIONS.SET_FACE_MATCHING_LOADING;
    payload: boolean;
  }
  interface SetOcrKycLoading {
    type: typeof ACTIONS.SET_OCR_KYC_LOADING;
    payload: boolean;
  }
  interface SetValidateOtpInput {
    type: typeof ACTIONS.SET_PAN_LINK_LOADING;
    payload: boolean;
  }
  interface GetMobileOyp {
    type: typeof ACTIONS.GET_MOBILE_OTP;
    payload: Array<any>;
  }
  interface PostValidateOtpInput {
    type: typeof ACTIONS.POST_VALIDATE_OTP_INPUT;
    payload: Array<any>;
  }
  interface PostMobileDetailsData {
    type: typeof ACTIONS.POST_MOBILE_DETAILS_DATA;
    payload: Array<any>;
  }
  interface PostEmailDetailsData {
    type: typeof ACTIONS.POST_EMAIL_AUTHENTICATION_DATA;
    payload: Array<any>;
  }
  interface PostBankDetailsData {
    type: typeof ACTIONS.POST_BANK_AUTHENTICATION_DATA;
    payload: Array<any>;
  }
  interface PostPanDetailsData {
    type: typeof ACTIONS.POST_PAN_AUTHENTICATION_DATA;
    payload: Array<any>;
  }
  interface PostPanLinkWithAadhaarDetailsData {
    type: typeof ACTIONS.POST_PAN_LINK_WITH_AADHAAR_AUTHENTICATION_DATA;
    payload: Array<any>;
  }
  interface ValidateFaceMatching {
    type: typeof ACTIONS.VALIDATE_FACE_MATCHING;
    payload: Array<any>;
  }
  interface ValidateOcrKyc {
    type: typeof ACTIONS.VALIDATE_OCR_KYC;
    payload: Array<any>;
  }
  interface GetUserList {
    type: typeof ACTIONS.GET_USER_LIST;
    payload: Array<any>;
  }
  interface SetLoading {
    type: typeof ACTIONS.SET_LOADING;
    payload: boolean;
  }
  interface CreateNewUser {
    type: typeof ACTIONS.CREATE_NEW_USER;
    payload: boolean;
  }
  interface GetPackageDetails {
    type: typeof ACTIONS.GET_PACKAGE_DETAILS;
    payload: Array<any>;
  }
  interface GetDoctorDetails {
    type: typeof ACTIONS.GET_DOCTOR_DETAILS;
    payload: Array<any>;
  }
  interface GetActivePincode{
    type: typeof ACTIONS.GET_ACTIVE_PINCODE;
    payload: Array<any>;
  }
  interface CreateNewCamp{
    type: typeof ACTIONS. CREATE_NEW_CAMP;
    payload: Array<any>;
  }
  interface GetPhleboDetails{
    type: typeof ACTIONS. GET_PHLEBO_DETAILS;
    payload: Array<any>;
  }
  interface GetCampDetails {
    type: typeof ACTIONS.GET_CAMP_DETAILS;
    payload: boolean;
  }
  interface UpdateCampDetails {
    type: typeof ACTIONS.UPDATE_CAMP;
    payload: boolean;
  }
  interface deletCampDetails {
    type: typeof ACTIONS.DELETE_CAMP;
    payload: boolean;
  }
  export type ValidationDashboarType =
  | SetLoading
  | SetLoading
  | GetMobileOyp
  | SetMoileDetailsLoading
  | SetValidateOtpInput
  | PostValidateOtpInput
  | PostMobileDetailsData
  | PostEmailDetailsData
  | SetEmailLoading
  | PostBankDetailsData
  | SetBankLoading
  | SetPanLoading
  | SetPanLinkLoading
  | PostPanDetailsData
  | PostPanLinkWithAadhaarDetailsData
  | SetFaceMathcingLoading
  | ValidateFaceMatching
  | ValidateOcrKyc
  | SetOcrKycLoading
  | GetUserList
  | CreateNewUser
  | UpdateUserProfile
  | GetPackageDetails
  | GetDoctorDetails
  | GetActivePincode
  | CreateNewCamp
  | GetPhleboDetails
  | GetCampDetails
  | UpdateCampDetails
  | deletCampDetails 
    