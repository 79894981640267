import React, { useEffect, useRef } from "react";
import { Grid, Autocomplete, TextField, Checkbox, MenuItem, Select } from "@mui/material";
import { error } from "console";
import { GENDER_CHOICE } from "../../../../constant";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './Index.sass'
interface Props {
    error: any;
    setError: any;
    inputList: any;
    setInputList: any;
    salulationList: any;
    packageDetails: any;
    totalPrice: any;
    setTotalPrice: any;
    collectionDate: any;
    setCollectionDate: any;
    // activePincodeList: any;
    pincode: any;
    setPincode: any
    setCampAreaPincode: any;
    setCampState: any;
    setCampCity: any;
    // getActivePinCode: any;
    getPackageDetails: any;
    handleReset: any;
    mobileNumber: any;
    setMobileNumber: any;
    alternateMobileNumber: any;
    setAlternateMobileNumber: any;
    getDoctorDetails: any;
    doctorDetails: any;
    doctorId: any;
    setDoctorId: any;
    packagesIds: any;
    setPackagesIds: any;
    campData:any;
}
const AssignDoctors: React.FC<Props> = ({
    error,
    setError,
    inputList,
    setInputList,
    salulationList,
    packageDetails,
    totalPrice,
    setTotalPrice,
    collectionDate,
    // setCollectionDate,
    // activePincodeList,
    pincode,
    setPincode,
    setCampAreaPincode,
    setCampState,
    setCampCity,
    // getActivePinCode,
    getPackageDetails,
    handleReset,
    mobileNumber,
    setMobileNumber,
    alternateMobileNumber,
    setAlternateMobileNumber,
    getDoctorDetails,
    doctorDetails,
    doctorId,
    setDoctorId,
    packagesIds,
    setPackagesIds,
    campData,
}) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const timer = useRef<any>(0);
    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        if (name === "customerName") {
            const newValue = e.target.value;

            if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
                setError("");
            } else {
                setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
            }
        }
        const list = [...inputList];
        if (name === "passport") {
            // const result = value.replace(/[^A-Z0-9]/, "");

            list[index][name] = value;
        } else {
            list[index][name] = value;
        }
        if (e.target.name === "designation") {
            const findGender = GENDER_CHOICE.filter(
                (el: any) => el.display_name === e.target.value
            );
            list[index]["customerGender"] = findGender[0]?.gender;
            list[index]["customerDesignation"] = findGender[0]?.db_name;
        }
        if (e.target.name === "dateOfBirth") {
            let today = new Date();
            let birthDate = new Date(e.target.value);
            let age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            list[index]["customerAge"] = age;
        }
        setInputList(list);
    };
    const handlePackage = (
        index: number,
        customerPackages: any,
        lastPack: any
    ) => {
        const list = [...inputList];

        list[index]["customerPackage"] = customerPackages;
        setInputList(list);

        let accumulatedPrice: any = 0;
        if (customerPackages && customerPackages.length > 0) {
            customerPackages.map((item: any) => {

                accumulatedPrice = accumulatedPrice + item.mbt_price;
                // }
            });
        }
        else if (customerPackages && customerPackages.length <= 0) {
            handleReset()
        }
        list[index]["packagePrice"] = accumulatedPrice;
        setInputList(list);
        let allPackagePrice = 0;
        inputList &&
            inputList.map((rowItem: any) => {
                allPackagePrice = allPackagePrice + rowItem?.packagePrice;
            });
        setTotalPrice(Math.floor(allPackagePrice));
    };
    const handlePackageId = (e: any, value: any) => {
        setPackagesIds(value)
    }
    const handleDoctorId = (e: any, value: any) => {
        setDoctorId(value)
        console.log(value)
    }

    const handleDoctorFocus = () => {
        getDoctorDetails()
    }
useEffect(()=>{
    setTimeout(()=>{
        if(doctorId._id){
            handleDoctorId('',doctorId)
            // setDoctorId(doctorId)
        }
    },3000)

},[doctorId])
    console.log("doctorId",doctorId._id)
    return (
        <Grid container spacing={3} style={{ margin: "2rem auto" }}>
            <Grid container spacing={3} style={{ margin: "1rem" }} alignItems="flex-end">
                <h4>Assign Doctor</h4>
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                style={{ margin: "1rem auto", background: "#eff" }}
            >
                {inputList.map((x: any, index: number) => {
                    return (
                        <>
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                paddingRight={2}
                                paddingBottom={2}
                                style={{ margin: "1rem auto", background: "#e1f5fe" }}
                            >
                                <Grid item xs={12} sm={6} md={4}>
                                    <Select
                                        className="input"
                                        id="demo-simple-select-standard"
                                        name="designation"
                                        variant="outlined"
                                        label="Designation"
                                         value={x.designation}
                                        style={{ width: "100%", margin: "0", height: "40px" }}
                                        onChange={(e: any) => handleInputChange(e, index)}
                                    // displayEmpty={true}
                                    // value={x.designation !== "" ? undefined : () => "Select Title"}
                                    >
                                        <MenuItem value=""><em>Designation</em></MenuItem>
                                        <MenuItem value="Mr">Mr</MenuItem>
                                        <MenuItem value="Ms">Ms (Mrs/Miss)</MenuItem>
                                        <MenuItem value="other">Others</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} sx={{ width: '100%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        defaultValue={doctorId}
                                        // value={doctorId.fullname}
                                        options={doctorDetails || []}
                                         onChange={(e: any, value: any) => handleDoctorId(e, value)}
                                        getOptionLabel={(option: any) =>
                                            option?.free === false ?
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                    <div>{option?.fullname}</div>
                                                    <div style={{ marginLeft: '15px', color: 'red', textAlign: 'end' }}>Not Available</div>
                                                </div> :
                                                option?.fullname}
                                        getOptionDisabled={(option: any) => option?.free === false}
                                        onFocusCapture={handleDoctorFocus}
                                        style={{ width: "100%", margin: "0" }}
                                        renderInput={(params) =>
                                            <TextField
                                                className="input"
                                                {...params}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Doctor Name"
                                                error={error}
                                            />}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className="input"
                                        name="mobile"
                                        type="number"
                                        label="Mobile Number"
                                        value={mobileNumber}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                minLength: 0,
                                                maxLength: 10,
                                            }
                                        }}
                                        style={{ width: "100%", margin: "0" }}
                                        helperText={error} // error message
                                        error={!!error}
                                        onChange={(e) => setMobileNumber(e.target.value.substring(0, 10))}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className="input"
                                        name="alternatemobile"
                                        type="number"
                                        label="Alternate Number"
                                        value={alternateMobileNumber}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                minLength: 0,
                                                maxLength: 10,
                                            }
                                        }}
                                        style={{ width: "100%", margin: "0" }}
                                        helperText={error} // error message
                                        error={!!error}
                                        onChange={(e) => setAlternateMobileNumber(e.target.value.substring(0, 10))}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Autocomplete
                                        disablePortal
                                        id="checkboxes-tags-demo"
                                        options={packageDetails && packageDetails?.results || []}
                                        // value={packagesIds}
                                        // inputValue={packagesIds || ""}
                                        onChange={handlePackageId}
                                        getOptionLabel={(option: any) => option?.test_name}
                                        style={{ width: "100%", margin: "0" }}
                                        renderInput={(params) =>
                                            <TextField
                                                className="input"
                                                {...params}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Package Name"
                                            />
                                        }
                                    />

                                </Grid>
                                {+inputList[0]?.customerAge < 5 && inputList[0]?.customerAge !== "" && <p style={{ textAlign: 'center', width: '100%', color: 'red' }}>Age cannot be less than 5 years.</p>}
                            </Grid>

                        </>
                    );
                })}
            </Grid>
        </Grid>
    )
}
export default AssignDoctors