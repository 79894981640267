const isSelected = (field) => {
  if (
      field === "all" ||
      field === "" ||
      field === undefined ||
      field === null ||
      field === "none"
  ) {
      return false;
  } else {
      return true;
  }
};

export const genrateAdminFilter = (body) => {
  const { booking_id, lead_id, full_name,age,gender,phone,booking_date,collection_date,start_time,end_time } = body;
  const admin_booking_id = isSelected(booking_id)? `&booking_id=${booking_id}`: "";
  const admin_lead_id = isSelected(lead_id)? `&lead_id=${lead_id}`: "";
  const admin_full_name = isSelected(full_name) ? `&full_name=${full_name}` : "";
  const admin_age = isSelected(age) ? `&age=${age}` : "";
  const admin_gender = isSelected(gender) ? `&gender=${gender}` : "";
  const admin_phone = isSelected(phone) ? `&phone=${phone}` : "";
  const admin_booking_date = isSelected(booking_date) ? `&booking_date=${booking_date}` : "";
  const admin_collection_date = isSelected(collection_date) ? `&collection_date=${collection_date}` : "";
  const admin_start_time = isSelected(start_time) ? `&start_date=${start_time}` : "";
  const admin_end_time = isSelected(end_time) ? `&end_date=${end_time}` : "";
  const url = `?${admin_booking_id}${admin_lead_id}${admin_full_name}${admin_age}${admin_gender}${admin_phone}${admin_booking_date}${admin_collection_date}${admin_start_time}${admin_end_time}`;
  return url;
};

export const genrateLeadFilter = (body) => {
  const { _id, patient_name, email,mobile} = body;
  const lead__id = isSelected(_id)? `&lead_id=${_id}`: "";
  const lead_patient_name = isSelected(patient_name)? `&lead_name=${patient_name}`: "";
  const lead_email = isSelected(email) ? `&lead_email=${email}` : "";
  const lead_mobile = isSelected(mobile) ? `&lead_phone=${mobile}` : "";
  const url = `?${lead__id}${lead_patient_name}${lead_email}${lead_mobile}`;
  return url;
};

export const generateTimeSlotFilter = (body) => {
  const { _id,booking_date,collection_date,start_time,end_time } = body;
  const admin__id = isSelected(_id)? `&_id=${_id}`: "";
  const admin_booking_date = isSelected(booking_date) ? `&booking_date=${booking_date}` : "";
  const admin_collection_date = isSelected(collection_date) ? `&collection_date=${collection_date}` : "";
  const admin_start_time = isSelected(start_time) ? `&start_time=${start_time}` : "";
  const admin_end_time = isSelected(end_time) ? `&end_time=${end_time}` : "";
  const url = `?${admin__id}${admin_booking_date}${admin_collection_date}${admin_end_time}${admin_start_time}`;
  return url;
};

export const generateCustomerFilter = (body) => {
  const { name,age,gender,phone } = body;
  const campcustomer_name = isSelected(name)? `&name=${name}`: "";
  const campcustomer_age = isSelected(age) ? `&age=${age}` : "";
  const campcustomer_gender = isSelected(gender) ? `&gender=${gender}` : "";
  const campcustomer_phone = isSelected(phone) ? `&phone=${phone}` : "";
  const url = `?${campcustomer_name}${campcustomer_age}${campcustomer_gender}${campcustomer_phone}`;
  return url;
};

export const generateCampFilter = (body) => {
  const { camp_code,camp_name,doctor_name,phlebo_name } = body;
  const camp_camp_code = isSelected(camp_code)? `&camp_code=${camp_code}`: "";
  const camp_camp_name = isSelected(camp_name) ? `&camp_name=${camp_name}` : "";
  const camp_doctor_name = isSelected(doctor_name) ? `&doctor_name=${doctor_name}` : "";
  const camp_phlebo_name = isSelected(phlebo_name) ? `&phlebo_name=${phlebo_name}` : "";
  const url = `?${camp_camp_code}${camp_camp_name}${camp_doctor_name}${camp_phlebo_name}`;
  return url;
};


