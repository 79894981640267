import React, { FunctionComponent, useState } from "react";
import {Button,TextField, Grid, Box, Hidden} from '@mui/material';
import SecureStorage from "../../config/SecureStorage";
import CircularProgress from '@mui/material/CircularProgress';
import {Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, resetPasswordReset, sendOtp } from "../../actions/loginActions";
import { useEffect } from "react";
import { createTheme } from '@mui/material/styles';
import UpdatePasswordModal from "./updatePasswordModal";
import loginImg from "../../Assets/login.svg";
const theme = createTheme();
const useStyles = makeStyles(({
  
  medibiotest: {
    color:'#90CAF9',
    width:"100%",
    letterSpacing:'0.189rem',
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    width:"100%",
    height:"100%",
    letterSpacing:'0.189rem',
    justifyContent:'left',
    justifyItems:'left'
    
  },
  loginForm:{
    background:" #fff",
    position: "absolute",
    top:" 0%",
    right: "0%",
    height:" 100%",
  },
    
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    height: "100%",
    // display: "flex",
    padding:'2rem',
    top:"5%",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -9,
    marginLeft: -9,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color:'black'
  },
  input: {
    padding: "1px",
  },
}));

type FormData = {
  username: string;
  password: string;
}

type LoginFormProps = {
  handleForm: any;
  loading: boolean;
}

const LoginForm: FunctionComponent<LoginFormProps> = ({ handleForm, loading }) => {
  const classes = useStyles();
  const { handleSubmit, register, formState: { errors }, } = useForm<FormData>();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loginReducer = useSelector((state: any) => state.loginReducer)
  const { otp: otpSent, success, loading: loadingReset } = loginReducer
  const [forgot, setForgot] = useState<any>(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [otp, setOtp] = useState("")
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const sendOtpHandler = () => {
    dispatch(sendOtp({ username }))
  }

  const resetPasswordHandler = async () => {
    const body: any = {
      token: otp,
      password
    }
    if (otp && password) {
      dispatch(resetPassword(body))
    }
  }

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (success) {
      navigate('/')
    }
  }, [navigate, success])

useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(resetPasswordReset())
  }, [dispatch])

  const resetDetails = () => {
    setForgot(false)
    setUsername("")
    setOtp("")
    setPassword("")
    dispatch(resetPasswordReset())
  }

  const token = SecureStorage.getItem("token");

  return (
    <>  
        <Grid container direction="row">
       
        </Grid>
        <Grid  container className={classes.loginForm} direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}>
          <Hidden lgDown>
            <Grid item xs={8} alignItems='flex-end'>
              <img alt='' src={loginImg}  className="loginImg" style={{width:'80vh', height:'60vh' }}/>
            </Grid>
          </Hidden>
          <Grid item xs={4} >
          <div className={classes.paper}>
          <Typography variant="h3" sx={{ letterSpacing: 3,fontWeight:'500' }}>
            {forgot ? "Reset Password" : "Welcome !"}
          </Typography>
          <Box sx={{ color: 'text.disabled',lineHeight:1,}}>Login to continue.</Box>
          {!forgot ? (
            <form
              className={classes.form}
              onSubmit={handleSubmit(handleForm)}
              noValidate
            >
              <TextField
                className="input"
                variant="outlined"
                margin="normal"
                fullWidth
                id="username"
                label="Username"
                autoComplete="username"
                autoFocus
                {...register("username", {
                  required: "Username is Required",
                })}
                InputLabelProps={{
                  style: { color: "#0a0a0a", fontWeight: "bold" },
                }}
                required={errors.username ? true : false}
                error={errors.username ? true : false}
                helperText={errors.username && errors.username.message}
              />
              <TextField
                className="input"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Password"
                type="password"
                id="password"
                InputLabelProps={{
                  style: { color: "#0a0a0a", fontWeight: "bold" },
                }}
                autoComplete="current-password"
                {...register("password", {
                  required: "Password is Required",
                })}
                required={errors.password ? true : false}
                error={errors.password ? true : false}
                helperText={errors.password && errors.password.message}
              />
              <div className={classes.wrapper} style={{ marginTop: "1rem" }}>
                {/* <Link to="/authentication/mobile"> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ background: "#90caf9",color:'black', }}
                  disabled={loading}
                  sx={{letterSpacing:2,fontWeight:'700'}}
                  className={classes.submit}
                >
                  Sign In
                </Button>
                {/* </Link> */}
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
              <div className={classes.wrapper} style={{ marginTop: "1rem" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  disabled={loading}
                  className={classes.submit}
                  // onClick={() => setForgot(true)}
                  onClick={handleOpen}
                >
                  Change Password
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </form>
          ) : (
            <>
              <div className={classes.form}>
                <input
                  className="input"
                  name="username"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  style={{
                    width: "100%",
                    margin: "1rem auto",
                    padding: "14px 14px",
                  }}
                />
                <div className={classes.wrapper}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loadingReset || !username}
                    className={classes.submit}
                    onClick={() => sendOtpHandler()}
                  >
                    Send OTP
                  </Button>
                  {loadingReset && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
                <input
                  className="input"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    width: "100%",
                    margin: "1rem auto",
                    padding: "14px 14px",
                  }}
                  disabled={!(otpSent && otpSent.status)}
                />
                <input
                  className="input"
                  name="otp"
                  type="password"
                  placeholder="OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  style={{
                    width: "100%",
                    margin: "1rem auto",
                    padding: "14px 14px",
                  }}
                  disabled={!(otpSent && otpSent.status)}
                />
                <div className={classes.wrapper}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                      loadingReset ||
                      !(otpSent && otpSent.status) ||
                      !otp ||
                      !password
                    }
                    className={classes.submit}
                    onClick={() => resetPasswordHandler()}
                  >
                    Update Password
                  </Button>
                  {loadingReset && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
                <div className={classes.wrapper}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={() => resetDetails()}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
          </Grid>
        </Grid>
        
        <UpdatePasswordModal
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleOpen={handleOpen}
        />
    </>
  );
}

export default LoginForm

