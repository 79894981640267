import { ACTIONS, CustomerExecutiveType } from "../interfaces/CustomerExecutiveType";

type InitialState = {
  loading: boolean;
  userList:Array<any>
  timeSlots:Array<any>
  packageDetails:Array<any>
  bookingList:Array<any>
  activePincodeList:Array<any>

};

const initialState: InitialState = {
  loading: false,
  userList:[],
  timeSlots:[],
  packageDetails:[],
  bookingList:[],
  activePincodeList:[]


};

const CustomerExecutiveReducer = (state = initialState, action: CustomerExecutiveType) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.GET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_TIMESLOTS:
      return {
        ...state,
        timeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_BOOKING:
      return {
        ...state,
        bookingList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ACTIVE_PINCODE:
      return {
        ...state,
        activePincodeList: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default CustomerExecutiveReducer;