import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { Button, Grid } from "@mui/material";
import BasicDetails from "./basicDetails";
import { useParams } from "react-router-dom";
import { getCampDetails, updateCustomerDetails, getCustomerDetailsById } from "../../../action/phleboAction";
import './Index.sass'
const useStyles = makeStyles({
  content: {
    width: "100%",
    flexGrow: 21,
  },
  slotContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  slot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
  },
  selectedSlot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
    cursor: "pointer",
    margin: "0.5rem",
  },
  packageLink: {
    color: "#ffffff",
    textDecoration: "none",
  },
})
interface Props {
  campList: any;
  getCampDetails: any;
  updateCustomerDetails: any;
  customerList: any;
  getCustomerDetailsById: any;
}
const EditCustomer: React.FC<Props> = ({
  campList,
  getCampDetails,
  updateCustomerDetails,
  customerList,
  getCustomerDetailsById
}) => {
  const classes = useStyles();
  const params = useParams();
  const [campData, setCampData] = useState<any>({})
  const [url, setCampIds] = useState<any>("")
  const [error, setError] = useState("");
  const [customerName, setCustomerName] = useState<any>("")
  const [customerAge, setCustomerAge] = useState<any>("")
  const [customerGender, setCustomerGender] = useState<any>("")
  const [mobileNumber, setMobileNumber] = useState<any>("")
  const [customerVials, setCustomerVials] = useState<any>("")
  const [customerRadiology, setcustomerRadiology] = useState<any>("")
  const [customerWeight, setCustomerWeight] = useState<any>("")
  const [customerHeight, setCustomerHeight] = useState<any>("")
  const [customerPulseRate, setCustomerPulseRate] = useState<any>("")
  const [customerBP, setCustomerBP] = useState<any>("")
  const [customerEmail, setCustomerEmail] = useState<any>("")
  const [customerSignature, setCustomerSignature] = useState<any>("")
  const [campId, setCampId] = useState<any>("")


  useEffect(() => {
    getCampDetails()
  }, [])

  useEffect(() => {
    const Ids = campList && campList?.results &&
      campList?.results.length > 0 && campList?.results.map((data: any, index: any) =>
        setCampIds(data?.camp_code)
      )
  })

  useEffect(() => {
    if (customerList && customerList?.results && customerList?.results.length > 0) {
      setCampData(customerList?.results[0])
    }
  }, [customerList])
  useEffect(() => {
    if (customerList && customerList?.results && customerList?.results.length > 0) {
      setCampData(customerList?.results[0])
    }
  }, [customerList])
  useEffect(() => {
    if (url && params.id !== "")
      getCustomerDetailsById(`/${url}?_id=${params.id}`)
  }, [params, url])
  //api calls

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const body = {
      camp_id: campId,
      name: customerName,
      age: customerAge,
      gender: customerGender,
      phone: mobileNumber,
      vials: customerVials,
      radiology: customerRadiology,
      height: customerHeight,
      weight: customerWeight,
      pulse_rate: customerPulseRate,
      BP: customerBP,
      email: customerEmail,
      customer_sign: customerSignature,
    }
    await updateCustomerDetails(body, `/${params.id}`)
  }

  useEffect(() => {
    setCampId(campData?.camp_id)
    setCustomerName(campData?.name)
    setCustomerAge(campData?.age)
    setCustomerGender(campData?.gender)
    setMobileNumber(campData?.phone)
    setCustomerVials(campData?.vials)
    setcustomerRadiology(campData?.radiology)
    setCustomerHeight(campData?.height)
    setCustomerWeight(campData?.weight)
    setCustomerPulseRate(campData?.pulse_rate)
    setCustomerBP(campData?.BP)
    setCustomerEmail(campData?.email)
    setCustomerSignature(campData?.signature)
  }, [campData])
  console.log("EditCustomerdetils", campData)
  return (
    <main className={classes.content}>
      <form onSubmit={handleSubmit}>
        <BasicDetails
          error={error}
          setError={setError}
          customerName={customerName}
          setCustomerName={setCustomerName}
          customerAge={customerAge}
          setCustomerAge={setCustomerAge}
          customerGender={customerGender}
          setCustomerGender={setCustomerGender}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          customerVials={customerVials}
          setCustomerVials={setCustomerVials}
          customerRadiology={customerRadiology}
          setcustomerRadiology={setcustomerRadiology}
          customerWeight={customerWeight}
          setCustomerWeight={setCustomerWeight}
          customerHeight={customerHeight}
          setCustomerHeight={setCustomerHeight}
          customerPulseRate={customerPulseRate}
          setCustomerPulseRate={setCustomerPulseRate}
          customerBP={customerBP}
          setCustomerBP={setCustomerBP}
          customerEmail={customerEmail}
          setCustomerEmail={setCustomerEmail}
          customerSignature={customerSignature}
          setCustomerSignature={setCustomerSignature}
          campList={campList}
          campId={campId}
          setCampId={setCampId}
        />
        <Grid
          container
          alignItems="center"
          marginTop={2}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Button type="submit" fullWidth variant="contained">Proceed for Adding Customer</Button>
          </Grid>
        </Grid>
      </form>
    </main>
  )
}
const mapStateToProps = (state: any) => ({
  customerList: state.PhleboReducer.customerList,
  campList: state.PhleboReducer.campList
});
export default connect(mapStateToProps, {
  updateCustomerDetails,
  getCampDetails,
  getCustomerDetailsById
})(EditCustomer);