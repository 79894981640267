export const GENDER_CHOICE = [
  {
    display_name: "Mrs",
    db_name: "Mrs",
    gender: "female",
  },
  {
    display_name: "Miss",
    db_name: "Miss",
    gender: "female",
  },
  {
    display_name: "Ms",
    db_name: "Ms",
    gender: "female",
  },
  {
    display_name: "Smt",
    db_name: "Smt",
    gender: "female",
  },
  {
    display_name: "Mr",
    db_name: "Mr",
    gender: "male",
  },
  {
    display_name: "Baby Boy",
    db_name: "Baby Boy",
    gender: "male",
  },
  {
    display_name: "Baby Girl",
    db_name: "Baby Girl",
    gender: "female",
  },
  {
    display_name: "other",
    db_name: "other",
    gender: "other",
  },
];

export const CITY = "City";
export const LOCALITY = "Locality";
export const SUBLOCALITY = "Locality For Customer Address";
export const ADDRESS_LINE1 = "House / Flat / Shop Number";
export const ADDRESS_LINE2 = "Apartment / Building / Street Number";
export const LANDMARK = "Landmark";
export const PINCODE = "Pin Code";