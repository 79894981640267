import { Grid, TextField } from "@mui/material";
import React from "react";

interface Props {
    customerAreaPincode: any;
    setCustomerAreaPincode: any;
    customerState: any;
    setCustomerState: any;
    customerCity: any;
    setCustomerCity: any;
    customerAddress: any;
    setCustomerAddress: any;
    customerLandMarks: any;
    setCustomerLandMarks: any;
    error: any;
    setError: any;
}
const CustomerAddress: React.FC<Props> = ({
    customerAreaPincode,
    setCustomerAreaPincode,
    customerState,
    setCustomerState,
    customerCity,
    setCustomerCity,
    customerAddress,
    setCustomerAddress,
    customerLandMarks,
    setCustomerLandMarks,
    error,
    setError,
}) => {
    return (
        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
              <h4>Customer Address</h4>
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                spacing={3}
                style={{ margin: "1rem auto", background: "#eff" }}
            >
                <Grid item md={6}>
                    <TextField
                        className="input"
                        name="customerAddress"
                        type="text"
                        label="Customer Address"
                        value={customerAddress}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCustomerAddress(e.target.value)}
                        required
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        className="input"
                        name="customerlocality"
                        type="text"
                        label="Landmarks"
                        value={customerLandMarks}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCustomerLandMarks(e.target.value)}
                        required
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        className="input"
                        name="city"
                        type="text"
                        label="Customer City"
                        value={customerCity}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCustomerCity(e.target.value)}
                        required
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        className="input"
                        name="customerareapincode"
                        type="text"
                        label="Customer Area Pincode"
                        value={customerAreaPincode}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCustomerAreaPincode(e.target.value)}
                        required
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        className="input"
                        name="state"
                        type="text"
                        label="Customer State"
                        value={customerState}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCustomerState(e.target.value)}
                        required
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default CustomerAddress