import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Grid, Paper } from "@mui/material";
import './index.sass'
const theme = createTheme();
const useStyles = makeStyles({
    root: {
        flexGrow: 0,
      },
      toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
      },
      content: {
        width: "80%",
        flexGrow: 21,
      },
      buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
      },
      formControl: {
        margin: theme.spacing(3),
      },
})
interface Props{

}
const ViewBooking =({})=>{
    const classes = useStyles();
    return(
        <main className={classes.content}>
             <Paper className={classes.paper} elevation={15}>
             <h3 style={{ textAlign: "center" }}>View Booking Details</h3>
             <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                 <Grid item xs={6} md={3}>
                  <h6>Collection Date</h6>
                  <p style={{ fontWeight: "normal" }}>
                  ""
                  </p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Pincode</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {/* {booking?.customer_areapincode &&
                      booking?.customer_areapincode?.pincode} */}
                  </p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Zone Name</h6>
                  <p style={{ fontWeight: "normal" }}>
                    {/* {booking?.customer_areapincode &&
                      booking?.customer_areapincode?.area} */}
                  </p>
                </Grid>
                <Grid item xs={6} md={3}>
                  <h6>Booking Id</h6>
                  <p style={{ fontWeight: "normal" }}>"ss"</p>
                </Grid>
              </Grid>
             </Paper>
        </main>
    )
}
export default ViewBooking
