import React, { useState, useEffect, useReducer } from 'react'
import { makeStyles } from '@mui/styles';
import { Button, Chip, Paper, NativeSelect, MenuItem, Modal, Box, Select, TextField, Grid, InputLabel } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createTheme } from '@mui/material/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 500,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '2px 2px 12px 2px #00000026',
    borderRadius: '10px',
    p: 4,
};

const commentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '500px',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '2px 2px 12px 2px #00000026',
    borderRadius: '10px',
    p: 4,
};
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    paper: {
        marginTop: "2rem",
        width: "100%",
    },
    tableHeadCell: {
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
    },
    table: {
        maxWidth: "100%",
    },
    tableHead: {
        background: "rgb(146, 74, 145)!important",
    },
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0A1929",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
interface Props {
    campList: any;
    customerList: any;
    // updateBooking: any;
    // getBookingComments: any;
    // comments: any;
    // createComments: any;
}

const CampTable: React.FC<Props> = ({
    campList,
    customerList,
    // updateBooking,
    // getBookingComments,
    // comments,
    // createComments
}) => {
    const classes = useStyles();
    const [pickingStatus, setPickingStatus] = useState<any>("")
    const [open, setOpen] = React.useState(false);
    const [bookingData, setBookingData] = useState<any>({})
    const [bookingStatus, setBookingStatus] = useState<any>("")
    const [openBookingStatus, setOpenBookingStatus] = useState(false)
    const [reportStatus, setReportStatus] = useState<any>("")
    const [openReportStatus, setOpenReportStatus] = useState(false)
    const [openComments, setOpenComments] = useState<any>(false)
    const [newComment, setNewComment] = useState<any>('')
    const handleClose = () => setOpen(false);
    const handleCloseBookingStatus = () => setOpenBookingStatus(false);
    const handleCloseReportStatus = () => setOpenReportStatus(false);

    // useEffect(() => {
    //     setBookingData(bookingData)
    //     getBookingComments(bookingData)
    // }, [bookingData])

    const handleSubmit = async (e: any) => {
        setPickingStatus(e.target.value)
        setOpen(true)
    }

    const handleBookingStatus = async (e: any) => {
        setBookingStatus(e.target.value)
        setOpenBookingStatus(true)
    }

    const handleReportStatus = async (e: any) => {
        setReportStatus(e.target.value)
        setOpenReportStatus(true)
    }

    // const changePickUpStatus = async () => {
    //     const body = {
    //         pickup_status: pickingStatus,
    //     }
    //     await updateBooking(body, bookingData)
    //     window.location.reload()
    //     setOpen(false)
    // }

    // const changeBookingStatus = async () => {
    //     const body = {
    //         booking_status: bookingStatus,
    //     }
    //     await updateBooking(body, bookingData)
    //     window.location.reload()
    //     setOpenBookingStatus(false)
    // }

    // const changeReportStatus = async () => {
    //     const body = {
    //         report_status: reportStatus,
    //     }
    //     await updateBooking(body, bookingData)
    //     window.location.reload()
    //     setOpenReportStatus(false)
    // }

    // const createBookingComments = async () => {
    //     const body = {
    //         comment: newComment
    //     }
    //     await createComments(body, bookingData)
    //     window.location.reload()
    //     setNewComment('')
    // }

    const handleOpenComments = () => {
        setOpenComments(true)
    }
    const handleCloseComments = () => {
        setOpenComments(false)
    }
    // const handleSubmitModal = async (data: any) => {
    //     handleOpenComments()
    //     setBookingData(data)
    //     getBookingComments(bookingData)
    console.log('customerData', customerList && customerList?.results && customerList?.results)
    return (
        <div style={{ width: "100%" }} className="data-table">
            <TableContainer className={classes.tableContainer}
                component={Paper}
                style={{ maxHeight: "550px" }}
            >
                <Table stickyHeader aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                        <StyledTableRow>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Action</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>View</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Camp Id</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Customer Name</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}> Age</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Gender</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Mobile Number</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Pulse Rate</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>BP</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>vials</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>radiology</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {customerList && customerList?.results && customerList?.results?.length > 0
                            && customerList?.results.map((data: any, index: any) => {
                                return (
                                    <>
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center">
                                                <Link
                                                    to={`/dashboard/ph/editcustomer/${data?._id}`}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <>
                                                        <Button variant="contained" color='success'>Edit</Button>
                                                    </>
                                                </Link>

                                            </StyledTableCell>
                                            <StyledTableCell align="center">

                                                <Link
                                                    to={`/dashboard/ph/viewcustomer/${data?._id}`}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <Button variant="contained">View</Button>
                                                </Link>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{data?.camp_id}</StyledTableCell>
                                            <StyledTableCell align="center">{data?.name}</StyledTableCell>
                                            <StyledTableCell align="center">{data?.age}</StyledTableCell>
                                            <StyledTableCell align="center">{data?.gender}</StyledTableCell>
                                            <StyledTableCell align="center">{data?.phone}</StyledTableCell>
                                            <StyledTableCell align="center">{data?.pulse_rate}</StyledTableCell>
                                            <StyledTableCell align="center">{data?.BP}</StyledTableCell>
                                            <StyledTableCell align="center">{data?.vials}</StyledTableCell>
                                            <StyledTableCell align="center">{data?.radiology}</StyledTableCell>
                                        </StyledTableRow>
                                    </>
                                )
                            })
                        }
                        {customerList && customerList?.results && customerList?.results.length > 0 &&
                            customerList?.results.map((data: any, index: any) => {
                                return (
                                    <>
                                        <StyledTableCell align="center">{data?.fullname}</StyledTableCell>
                                    </>
                                )
                            })}
                    </TableBody >
                </Table>
                {customerList && customerList?.results && customerList?.results.length > 0
                    ? "" :
                        campList && campList?.results && campList?.results.length > 0 ?
                        <div style={{ width: '100%', textAlign: 'center', fontSize: '20px', fontWeight: '600', padding: '15px' }}>
                            No Customer is added yet
                        </div>
                        : <div style={{ width: '100%', textAlign: 'center', fontSize: '20px', fontWeight: '600', padding: '15px' }}>
                            No Camp has been assigned to you
                        </div>
                    
                }
            </TableContainer>

            <Modal open={openBookingStatus} onClose={handleCloseBookingStatus}>
                <Box sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item padding={2} justifyContent="center" xs={12} sm={12} md={12} lg={12}>
                            Are you sure, you want to change the Booking Status?
                        </Grid>
                        <Grid item container padding={2} justifyContent="center" xs={12} sm={12} md={9} lg={9}>
                            <Button
                                //  onClick={changeBookingStatus}
                                color='success' variant="contained" sx={{ width: '100%' }}>Confirm Booking Status</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item padding={2} justifyContent="center" xs={12} sm={12} md={12} lg={12}>
                            Are you sure, you want to change the Pending Status?
                        </Grid>
                        <Grid item container padding={2} justifyContent="center" xs={12} sm={12} md={9} lg={9}>
                            <Button
                                // onClick={changePickUpStatus}
                                color='success' variant="contained" sx={{ width: '100%' }}>Confirm Pending Status</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal open={openReportStatus} onClose={handleCloseReportStatus}>
                <Box sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item padding={2} justifyContent="center" xs={12} sm={12} md={12} lg={12}>
                            Are you sure, you want to change the Report Status?
                        </Grid>
                        <Grid item container padding={2} justifyContent="center" xs={12} sm={12} md={9} lg={9}>
                            <Button
                                //  onClick={changeReportStatus} 
                                color='success' variant="contained" sx={{ width: '100%' }}>Confirm Report Status</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openComments}
                onClose={handleCloseComments}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-width'>
                    <Box sx={commentStyle}>
                        <div style={{ height: '250px', overflowY: 'scroll' }}>
                            {/* {
                                comments && comments.comments && comments.comments.map((data: any) =>
                                    <>
                                        <Grid container>
                                            <Grid item md={12} lg={12} sx={{ color: '#b6b6b6', fontSize: '11px' }}>
                                                <ul>
                                                    <li>{`${data.messgae} on ${new Date(data.updated_at).toLocaleDateString()} at ${new Date(data.updated_at).toLocaleTimeString()}`}</li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            } */}
                        </div>
                        <Grid container paddingTop={4}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <InputLabel>Add New Comment</InputLabel>
                                <TextField required sx={{ width: '100%' }} color="success" value={newComment} onChange={((e) => setNewComment(e.target.value))} />
                            </Grid>
                            <Grid item container padding={2} justifyContent="center" xs={12} sm={12} md={12} lg={12} >
                                <Button sx={{ width: '150px' }}
                                    //  onClick={createBookingComments}
                                    variant="contained" color='success'>Save</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Modal>
        </div>
    )
}

export default CampTable