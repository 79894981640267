import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { getCampDetailsById } from "../../../action/phleboAdminAction";
import { useParams } from "react-router-dom";
import LoaderCustom from "../../../../components/loader/Loader";
import Loader from "../../../../components/loader";
import moment from "moment";
import './index.sass'
const theme = createTheme();
const useStyles = makeStyles({
  root: {
    flexGrow: 0,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    width: "80%",
    flexGrow: 21,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(3),
  },
})
interface Props {
  campList: any;
  getCampDetailsById:any;
}
const ViewCamp: React.FC<Props> = ({
  campList,
  getCampDetailsById,
}) => {
  const params = useParams();
  const classes = useStyles();
  const [campData, setCampData] = useState<any>({})
  console.log(params)

  useEffect(() => {
    getCampDetailsById(`?camp_code=${params.id}`)
  }, [params])
  useEffect(() => {
    if (campList && campList?.results && campList?.results.length > 0) {
      setCampData(campList?.results[0])
    }
  }, [campList])
  console.log("campData",campData)
  return (
    <main className={classes.content}>
      <br />
      <Paper className={classes.paper} elevation={15}>
        <h3 style={{ textAlign: "center" }}>View Camp Details</h3>
        {campList && campList?.results && campList?.results.length > 0 ? (
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            spacing={3}
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={6} md={3}>
              <h6>Creation Date</h6>
              <p style={{ fontWeight: "normal" }}>
                {moment.utc(campData?.creation_time).format('MM-DD-YYYY')}
              </p>
            </Grid>
            <Grid item xs={6} md={3}>
              <h6>Pincode</h6>
              <p style={{ fontWeight: "normal" }}>
                {campData?.pincode}
              </p>
            </Grid>
            <Grid item xs={6} md={3}>
              <h6>Camp Id</h6>
              <p style={{ fontWeight: "normal" }}>{campData?.camp_code}</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <h6>Created By</h6>
              <p style={{ fontWeight: "normal" }}>

                {campData?.created_by}
              </p>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Camp Name</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Camp City</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Package Name</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Doctor Name</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Phlebo Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{campData?.camp_name}</TableCell>
                    <TableCell align="center">{campData?.city}</TableCell>
                    <TableCell align="center">{campData?.package?.test_name}</TableCell>
                    <TableCell align="center">
                      {
                        campData.doctor_detail &&  campData.doctor_detail && campData.doctor_detail.length >
                        0 && campData.doctor_detail.map((data1: any, index: any) => data1.fullname
                        )
                      }
                      </TableCell>
                    <TableCell align="center">
                    {campData?.phlebo_details?.fullname}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            
            <Grid item xs={12} md={12} lg={12} >
                  <Divider style={{ backgroundColor: "#009fff" }} />
                  <h3 style={{ textAlign: "center", marginTop:'10px' }}>Doctor Details</h3>
                </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Mobile Number
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={campData?.mobile_no || ""}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Email Address
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="email"
                  value={
                    campData.doctor_detail &&  campData.doctor_detail && campData.doctor_detail.length >
                    0 && campData.doctor_detail.map((data1: any, index: any) => data1.email
                    )
                  }
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Doctor Name
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={
                    campData.doctor_detail &&  campData.doctor_detail && campData.doctor_detail.length >
                    0 && campData.doctor_detail.map((data1: any, index: any) => data1.fullname
                    )
                  }
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Doctor Number
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={campData?.phlebos?campData?.phlebos?.mobile:""}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                direction="row"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12} >
                  <Divider style={{ backgroundColor: "#009fff" }} />
                  <h3 style={{ textAlign: "center", marginTop:'10px' }}> CAMP Address</h3>
                </Grid>
                <Grid
                  direction="row"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Camp Address</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="houseNumber"
                      type="text"
                      value={
                        campData?.address
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Landmarks</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={campData?.customer_landmarks || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>City</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_landmark"
                      type="text"
                      value={campData?.city|| ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>State</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="State"
                      type="text"
                      value={
                        campData?.state
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Pincode</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="city"
                      type="text"
                      value={
                        campData?.pincode
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                </Grid>
          </Grid>
        ) : <Loader />}

      </Paper>
    </main>
  )
}

const mapStateToProps = (state: any) => ({
  campList: state.PhleboAdminReducer.campList,
});
export default connect(mapStateToProps, {
  getCampDetailsById
})(ViewCamp);
