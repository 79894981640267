export const ACTIONS = {
  SET_LOADING: "ce/setloading",
  GET_USER_LIST: "ce/get_user_list",
  CREATE_NEW_USER: "ce/create_new_users",
  UPDATE_USER_PROFILE: "ce/update_user_profile",
  GET_BOOKING_TIMESLOTS: "ce/get_booking_timeslots",
  GET_PACKAGE_DETAILS: "ce/get_package_details",
  CREATE_BOOKING: "ce/create_booking",
  GET_ALL_BOOKING: "ce/get_all_booking",
  GET_ACTIVE_PINCODE:"ce/GET_ACTIVE_PINCODE"
};
interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface UpdateUserProfile {
  type: typeof ACTIONS.UPDATE_USER_PROFILE
  payload: Array<any>
}

interface GetUserList {
  type: typeof ACTIONS.GET_USER_LIST;
  payload: Array<any>;
}
interface CreateNewUser {
  type: typeof ACTIONS.CREATE_NEW_USER;
  payload: boolean;
}

interface GetBookingTimeSlots {
  type: typeof ACTIONS.GET_BOOKING_TIMESLOTS;
  payload: Array<any>;
}
interface GetPackageDetails {
  type: typeof ACTIONS.GET_PACKAGE_DETAILS;
  payload: Array<any>;
}
interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: Array<any>;
}
interface GetAllBooking {
  type: typeof ACTIONS.GET_ALL_BOOKING;
  payload: Array<any>;
}
interface getActivePinCode {
  type: typeof ACTIONS.GET_ACTIVE_PINCODE;
  payload: Array<any>;
}
export type CustomerExecutiveType =
  | SetLoading
  | GetUserList
  | CreateNewUser
  | UpdateUserProfile
  | GetBookingTimeSlots
  | GetPackageDetails
  | CreateBooking
  | GetAllBooking
  | getActivePinCode