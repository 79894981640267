import React, { useEffect } from "react";
import { checkUser } from "../../actions/loginActions";
import { connect } from "react-redux";
import CheckRoles from "./checkRoles";
import SecureStorage from "../../config/SecureStorage";

interface Props {
  checkUser: Function;
  user: any;
  path: any;
  query: any;
  
}

const Dash = ({ checkUser, user,path,query }: Props) => {
  
  useEffect(() => {
    checkUser();
  }, []);
  if(document.getElementById("loading")?.innerHTML===undefined){
    checkUser();
  }
  return (
    <div>
      {user?.user!==undefined? (
        <CheckRoles role={user.user.usergroup} path={path} query={query}/>
      ) : (
        <div id="loading" style={{ width: "32px", height: "32px" }}>
          Loading...
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  user: state.loginReducer.user,
});
export default connect(mapStateToProps, { checkUser })(Dash);