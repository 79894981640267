import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Grid, TextField } from "@mui/material";
import BookingTable from "./BookingTable";
import { getAllBooking } from '../../action/CustomerExecutiveAction'
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
})
interface Props {
    getAllBooking:any;
    bookingList:any;

}
const BookingFilter: React.FC<Props> = ({
    getAllBooking,
    bookingList
}) => {
    const classes = useStyles();
    const [bookingId, setBookingid] = useState<any>("");
    const [name, setName] = useState<any>("");
    const [phone, setPhone] = useState<any>("");
    const [booking_date, setBooking_date] = useState("");
    const [collection_date, setCollection_date] = useState("");
    const [start_date, setStart_date] = useState("");
    const [end_date, setEnd_date] = useState("");
    const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const blockCharInAutocompleteField = (e: React.KeyboardEvent<HTMLDivElement>) => {
        return ("~!@#$%^&*()_+{}|:<>?,./;'[]\=-0987654321`").includes(e.key) && e.preventDefault();
    }
    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            //   filterBookings(e);
        }
    };
    useEffect(() => {
        getAllBooking()
    }, [])
    console.log("hello")
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid
                container
                spacing={1}
            >
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="booking"
                        type="text"
                        label="Booking Id"
                        value={bookingId}
                        variant="outlined"
                        onWheel={(e: any) => e.target.blur()}
                        inputProps={{ min: 0 }}
                        InputLabelProps={{ shrink: true }}
                        // onKeyDown={(e) => {
                        //   blockCharInPhoneField(e)
                        //   onKeyDown(e)
                        // }}
                        onChange={(e) => {
                            const inputVal = e.target.value;
                            setBookingid(inputVal.replace(/[^0-9]/g, ''))
                        }}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="name"
                        type="text"
                        label="Name"
                        value={name}
                        variant="outlined"
                        onKeyDown={onKeyDown}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setName(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="phone"
                        type="number"
                        label="Phone"
                        value={phone}
                        variant="outlined"
                        onWheel={(e: any) => e.target.blur()}
                        InputLabelProps={{ shrink: true }}
                        onKeyDown={(e) => {
                            blockCharInPhoneField(e)
                            onKeyDown(e)
                        }}
                        onInput={(e: any) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                        }}
                        inputProps={{ min: 1, max: 9999999999 }}
                        onChange={(e) => setPhone(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        name="booking_date"
                        type="date"
                        id="noTyping"
                        value={start_date === "" || end_date === "" ? booking_date : ""}
                        className="input"
                        InputLabelProps={{ shrink: true }}
                        label="Booking Date"
                        variant="outlined"
                        onKeyDown={onKeyDown}
                        disabled={start_date !== "" || end_date !== ""}
                        onChange={(e) => String(e.target.value).length <= 10 ? setBooking_date(e.target.value as string) : ""}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="start_date"
                        id="noTyping"
                        type="date"
                        label="Start Date"
                        InputLabelProps={{ shrink: true }}
                        value={
                            booking_date === "" || collection_date === ""
                                ? start_date
                                : ""
                        }
                        variant="outlined"
                        disabled={booking_date !== "" || collection_date !== ""}
                        onKeyDown={onKeyDown}
                        onChange={(e) => String(e.target.value).length <= 10 ? setStart_date(e.target.value as string) : ""}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        name="end_date"
                        type="date"
                        id="noTyping"
                        InputLabelProps={{ shrink: true }}
                        value={
                            booking_date === "" || collection_date === "" ? end_date : ""
                        }
                        className="input"
                        label="End Date"
                        variant="outlined"
                        disabled={booking_date !== "" || collection_date !== ""}
                        onChange={(e) => String(e.target.value).length <= 10 ? setEnd_date(e.target.value as string) : ""}
                        style={{ width: "100%" }}
                    />
                </Grid>
            </Grid>
            <BookingTable 
            bookingList={bookingList}
            />
        </main>
    )
}
const mapStateToProps = (state: any) => ({
    bookingList: state.CustomerExecutiveReducer.bookingList,
});
export default connect(mapStateToProps, {
    getAllBooking
})(BookingFilter);