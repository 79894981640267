import { Paper, TableContainer, Table, TableHead, TableRow, TableBody , Button, ButtonGroup} from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: "#001F45",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
interface Props {
    getActivePinCode: any;
    activePincodeList: any;
    pincodeList:any;
    getAllPinCode:any;
}
interface Column {
    id: 'pincode' | 'distict' | 'state' | 'status';
    label: string;
    minWidth?: number;
    align?: 'center';
    format?: (value: number) => string;
}

const PincodeData:React.FC<Props> = ({
    getActivePinCode,
    activePincodeList,
    pincodeList,
    getAllPinCode
}) => {

    const columns: readonly Column[] = [
        // { id: 'index', label: 'Center Code', minWidth: 170, align: 'right' },
        { id: 'pincode', label: 'Pincode', minWidth: 170, align: 'center' },
        { id: 'distict', label: 'Distict', minWidth: 170, align: 'center' },
        { id: 'state', label: 'State', minWidth: 170, align: 'center' },
        { id: 'status', label: 'Status', minWidth: 170, align: 'center' },
    ];
  
    const [pageNumber, setPageNumber] = React.useState<any>("");
    const handleNext = () => {
        if (pincodeList?.next !== null) {
            let url = pincodeList?.links?.next.split("?")[1];
            getAllPinCode(`?${url}`);
            setPageNumber(url);
        }
    }
    const handlePrev = () => {
        if (pincodeList?.prev !== null) {
            let url = pincodeList?.links?.previous.split("?")[1];
            setPageNumber(url);
            getAllPinCode(`?${url}`);
        }
    }
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }} className="data-table-header">
            <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell align="center">
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {activePincodeList && activePincodeList?.results && activePincodeList?.results.length >=0 && activePincodeList?.results.map((data: any, index: any) => {
                            return (
                                <StyledTableRow key={index}>
                                    {/* <StyledTableCell align="center" >{index + 1}</StyledTableCell> */}
                                    {/* <StyledTableCell align="center" >{data?.pincode}</StyledTableCell>
                                    <StyledTableCell align="center" >{data?.distict}</StyledTableCell>
                                    <StyledTableCell align="center" >{data?.state}</StyledTableCell>
                                    <StyledTableCell align="center" >{data?.is_active?"Active":"Deactive"}</StyledTableCell>
                                </StyledTableRow> */}
                            {/* )
                        })} */}
                         {pincodeList && pincodeList?.results &&
                          pincodeList?.results.length >=0
                          && pincodeList?.results.map((data: any, index: any) => {
                           
                            return (
                                <>
                                {data?.is_active==="true" &&
                                <StyledTableRow key={index}>
                                {/* <StyledTableCell align="center" >{index + 1}</StyledTableCell> */}
                                <StyledTableCell align="center" >{data?.pincode}</StyledTableCell>
                                <StyledTableCell align="center" >{data?.district}</StyledTableCell>
                                <StyledTableCell align="center" >{data?.state}</StyledTableCell>
                                <StyledTableCell align="center" >{data?.is_active?"Active":"Deactive"}</StyledTableCell>
                            </StyledTableRow>
                                }
                                </>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ButtonGroup
                size="large" aria-label="large button group"
            >
                <Button disabled={pincodeList?.links?.previous===null}  onClick={handlePrev}startIcon={<ArrowBackIosIcon />}>Prev</Button>
                <Button disabled={pincodeList?.links?.next===null} onClick={handleNext}   endIcon={<ArrowForwardIosIcon />}>Next</Button>
            </ButtonGroup>
        </Paper>
    )
}

const mapStateToProps = (state: any) => ({
})
export default connect(mapStateToProps, {
})(PincodeData)