import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Button, Card, Grid, MenuItem, Select, TextField } from "@mui/material";
import BasicDetails from "./basicDetails";
import TimeSlotsPackageDetails from "./TimeSlotsPackageDetails";
import ContactDetails from "./contactDetails";
import Checkbox from '@mui/material/Checkbox';
import './Index.sass'
const useStyles = makeStyles({
  content: {
    width: "100%",
    flexGrow: 21,
  },
  slotContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  slot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
  },
  selectedSlot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
    cursor: "pointer",
    margin: "0.5rem",
  },
  packageLink: {
    color: "#ffffff",
    textDecoration: "none",
  },
})
interface Props {

}
const NewBooking: React.FC<Props> = ({ }) => {

  const timeslots = {
    "links": {
      "next": null,
      "previous": null
    },
    "count": 17,
    "page_size": 50,
    "results": [
      {
        "id": 14,
        "start_time": "06:00:00",
        "end_time": "07:00:00",
        "slot_time": {
          "start_time": "06:00 AM",
          "end_time": "07:00 AM"
        },
        "slot12_time": {
          "start_time": "06:00 AM",
          "end_time": "07:00 AM"
        }
      },
      {
        "id": 2,
        "start_time": "07:00:00",
        "end_time": "08:00:00",
        "slot_time": {
          "start_time": "07:00 AM",
          "end_time": "08:00 AM"
        },
        "slot12_time": {
          "start_time": "07:00 AM",
          "end_time": "08:00 AM"
        }
      },
      {
        "id": 3,
        "start_time": "08:00:00",
        "end_time": "09:00:00",
        "slot_time": {
          "start_time": "08:00 AM",
          "end_time": "09:00 AM"
        },
        "slot12_time": {
          "start_time": "08:00 AM",
          "end_time": "09:00 AM"
        }
      },
      {
        "id": 4,
        "start_time": "09:00:00",
        "end_time": "10:00:00",
        "slot_time": {
          "start_time": "09:00 AM",
          "end_time": "10:00 AM"
        },
        "slot12_time": {
          "start_time": "09:00 AM",
          "end_time": "10:00 AM"
        }
      },
      {
        "id": 5,
        "start_time": "10:00:00",
        "end_time": "11:00:00",
        "slot_time": {
          "start_time": "10:00 AM",
          "end_time": "11:00 AM"
        },
        "slot12_time": {
          "start_time": "10:00 AM",
          "end_time": "11:00 AM"
        }
      },
      {
        "id": 6,
        "start_time": "11:00:00",
        "end_time": "12:00:00",
        "slot_time": {
          "start_time": "11:00 AM",
          "end_time": "12:00 PM"
        },
        "slot12_time": {
          "start_time": "11:00 AM",
          "end_time": "12:00 PM"
        }
      },
      {
        "id": 7,
        "start_time": "12:00:00",
        "end_time": "13:00:00",
        "slot_time": {
          "start_time": "12:00 PM",
          "end_time": "01:00 PM"
        },
        "slot12_time": {
          "start_time": "12:00 PM",
          "end_time": "01:00 PM"
        }
      },
      {
        "id": 8,
        "start_time": "13:00:00",
        "end_time": "14:00:00",
        "slot_time": {
          "start_time": "01:00 PM",
          "end_time": "02:00 PM"
        },
        "slot12_time": {
          "start_time": "01:00 PM",
          "end_time": "02:00 PM"
        }
      },
      {
        "id": 9,
        "start_time": "14:00:00",
        "end_time": "15:00:00",
        "slot_time": {
          "start_time": "02:00 PM",
          "end_time": "03:00 PM"
        },
        "slot12_time": {
          "start_time": "02:00 PM",
          "end_time": "03:00 PM"
        }
      },
      {
        "id": 10,
        "start_time": "15:00:00",
        "end_time": "16:00:00",
        "slot_time": {
          "start_time": "03:00 PM",
          "end_time": "04:00 PM"
        },
        "slot12_time": {
          "start_time": "03:00 PM",
          "end_time": "04:00 PM"
        }
      },
      {
        "id": 11,
        "start_time": "16:00:00",
        "end_time": "17:00:00",
        "slot_time": {
          "start_time": "04:00 PM",
          "end_time": "05:00 PM"
        },
        "slot12_time": {
          "start_time": "04:00 PM",
          "end_time": "05:00 PM"
        }
      },
      {
        "id": 12,
        "start_time": "17:00:00",
        "end_time": "18:00:00",
        "slot_time": {
          "start_time": "05:00 PM",
          "end_time": "06:00 PM"
        },
        "slot12_time": {
          "start_time": "05:00 PM",
          "end_time": "06:00 PM"
        }
      },
      {
        "id": 13,
        "start_time": "18:00:00",
        "end_time": "19:00:00",
        "slot_time": {
          "start_time": "06:00 PM",
          "end_time": "07:00 PM"
        },
        "slot12_time": {
          "start_time": "06:00 PM",
          "end_time": "07:00 PM"
        }
      },
      {
        "id": 15,
        "start_time": "19:00:00",
        "end_time": "20:00:00",
        "slot_time": {
          "start_time": "07:00 PM",
          "end_time": "08:00 PM"
        },
        "slot12_time": {
          "start_time": "07:00 PM",
          "end_time": "08:00 PM"
        }
      },
      {
        "id": 43,
        "start_time": "21:00:00",
        "end_time": "22:00:00",
        "slot_time": {
          "start_time": "09:00 PM",
          "end_time": "10:00 PM"
        },
        "slot12_time": {
          "start_time": "09:00 PM",
          "end_time": "10:00 PM"
        }
      },
      {
        "id": 49,
        "start_time": "22:00:00",
        "end_time": "23:00:00",
        "slot_time": {
          "start_time": "10:00 PM",
          "end_time": "11:00 PM"
        },
        "slot12_time": {
          "start_time": "10:00 PM",
          "end_time": "11:00 PM"
        }
      },
      {
        "id": 44,
        "start_time": "23:00:00",
        "end_time": "23:59:59",
        "slot_time": {
          "start_time": "11:00 PM",
          "end_time": "12:00 PM"
        },
        "slot12_time": {
          "start_time": "11:00 PM",
          "end_time": "11:59 PM"
        }
      }
    ]
  }
  const classes = useStyles();
  const [error, setError] = useState("");
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(0);
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [phleboCost, setPhleboCost] = useState<any>(0);
  const [cType, setCType] = useState<any>("none");
  const [cValue, setCValue] = useState<any>("");
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponKey, setCouponKey] = useState<any>(0);
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [partnerCodeDiscount, setPartnerCodeDiscount] = useState<boolean>(false);
  const [redProMembershipDiscount, setRedProMembershipDiscount] = useState<any>(0);
  const [redProMembershipDiscountPercentage, setRedProMembershipDiscountPercentage] = useState<any>(0);
  const [discountDueSmartPackage, setDiscountDueSmartPackage] =
    useState<any>(0);
  const [referralPointsDiscount, setReferralPointsDiscount] = useState<any>(0);
  const [referralPointsApplied, setReferralPointsApplied] = useState<any>(false);
  const [referralPoints, setReferralPoints] = useState<any>("");
  const [mobileNumber,setMobileNumber]=useState<any>("")
  const [alternateMobileNumber,setAlternateMobileNumber]=useState<any>("")
  const [whatsappNumber,setWhatsappNumber]=useState<any>("")
  const [email,setEmail]=useState<any>("")
  const timer = useRef<any>(0);
  const salulationList = [
    { title: 'Mr', value: "Mr" },
    { title: 'Mrs', year: "Mrs" },
    { title: 'Ms', year: "Ms" },
    { title: 'Dr', year: "Dr" },
    { title: 'Master', year: "Master" },
  ];
  const [inputList, setInputList] = useState<any>([
    {
      customerDesignation: "",
      customerName: "",
      customerAge: "",
      customerGender: "",
      customerPackage: [],
      packagePrice: 0,
      dateOfBirth: "",
      passport: "",

    },
  ]);

  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        customerDesignation: "",
        customerName: "",
        customerAge: "",
        customerGender: "",
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
      },
    ]);
  };
  return (
    <main className={classes.content}>
     <BasicDetails
       error={error}
       setError={setError}
       inputList={inputList}
       setInputList={setInputList}
       salulationList={salulationList}
     />
     <ContactDetails
       mobileNumber={mobileNumber}
       setMobileNumber={setMobileNumber}
       alternateMobileNumber={alternateMobileNumber}
       setAlternateMobileNumber={setAlternateMobileNumber}
       whatsappNumber={whatsappNumber}
       setWhatsappNumber={setWhatsappNumber}
       email={email}
       setEmail={setEmail}
       error={error}
     />
     <TimeSlotsPackageDetails
     timeslots={timeslots}
     selectedSlot={selectedSlot}
     setSelectedSlot={setSelectedSlot}
     inputList={inputList}
     setInputList={setInputList}
     cValue={cValue}
     setCValue={setCValue}
     singlePackage={singlePackage}
     setSinglePackage={setSinglePackage}
     isPaneOpen={isPaneOpen}
     setPaneOpen={setPaneOpen}
     cType={cType}
     setCType={setCType}
     couponKey={couponKey}
     price={price}
     setPrice={setPrice}
     coupon={coupon}
     setCoupon={setCoupon}
     couponCode={couponCode}
     setCouponCode={setCouponCode}
     discount={discount}
     setDiscount={setDiscount}
     partnerCodeDiscount={partnerCodeDiscount}
     setPartnerCodeDiscount={setPartnerCodeDiscount}
     redProMembershipDiscount={redProMembershipDiscount}
     setRedProMembershipDiscount={setRedProMembershipDiscount}
     discountDueSmartPackage={discountDueSmartPackage}
     setDiscountDueSmartPackage={setDiscountDueSmartPackage}
     referralPointsDiscount={referralPointsDiscount}
     setReferralPointsDiscount={setReferralPointsDiscount}
     referralPointsApplied={referralPointsApplied}
     setReferralPointsApplied={setReferralPointsApplied}
     referralPoints={referralPoints}
     setReferralPoints={setReferralPoints}
     extraCharge={extraCharge}
     phleboCost={phleboCost}
     />
     <Grid 
     container
      alignItems="center"  
      spacing={3}
      style={{ margin: "1rem", alignItems:"center",justifyContent:"center"}}
      >
        <Grid item md={6}>
        <Button fullWidth variant="contained">Proceed for Booking</Button>
        </Grid>

     </Grid>
       
   
    </main>
  )

}
const mapStateToProps = (state: any) => ({});
export default connect(mapStateToProps, {
})(NewBooking);