import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Button, Card, Grid, MenuItem, Select, TextField } from "@mui/material";
import BasicDetails from "./basicDetails";
import TimeSlotsPackageDetails from "./TimeSlotsPackageDetails";
import ContactDetails from "./contactDetails";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import { getBookingById, getBookingTimeSlots, getPackageDetails, updateBooking, getActivePinCode, getPhlebo } from '../../../action/adminAction'
import CustomerAddress from "./customerAddress";
import { useParams } from "react-router-dom";
import './Index.sass'
const useStyles = makeStyles({
  content: {
    width: "100%",
    flexGrow: 21,
  },
  slotContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  slot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
  },
  selectedSlot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
    cursor: "pointer",
    margin: "0.5rem",
  },
  packageLink: {
    color: "#ffffff",
    textDecoration: "none",
  },
})
interface Props {
  getBookingTimeSlots: any
  timeSlots: any;
  getPackageDetails: any;
  packageDetails: any;
  updateBooking: any;
  getActivePinCode: any;
  activePincodeList: any;
  getBookingById: any;
  bookingList: any;
  getPhlebo: any;
  phleboList: any;
  updateDBooking: any;
}
const EditBooking: React.FC<Props> = ({
  getBookingTimeSlots,
  timeSlots,
  getPackageDetails,
  packageDetails,
  updateBooking,
  getActivePinCode,
  activePincodeList,
  updateDBooking,
  getBookingById,
  bookingList,
  getPhlebo,
  phleboList,
}) => {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState<any>({})
  const [totalPrice, setTotalPrice] = useState<any>(0)
  const [error, setError] = useState("");
  const [selectedSlot, setSelectedSlot] = useState<Number>(0);
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [phleboCost, setPhleboCost] = useState<any>(0);
  const [cType, setCType] = useState<any>("none");
  const [cValue, setCValue] = useState<any>("");
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponKey, setCouponKey] = useState<any>(0);
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [partnerCodeDiscount, setPartnerCodeDiscount] = useState<boolean>(false);
  const [redProMembershipDiscount, setRedProMembershipDiscount] = useState<any>(0);
  const [redProMembershipDiscountPercentage, setRedProMembershipDiscountPercentage] = useState<any>(0);
  const [discountDueSmartPackage, setDiscountDueSmartPackage] =
    useState<any>(0);
  const [referralPointsDiscount, setReferralPointsDiscount] = useState<any>(0);
  const [referralPointsApplied, setReferralPointsApplied] = useState<any>(false);
  const [referralPoints, setReferralPoints] = useState<any>("");
  const [mobileNumber, setMobileNumber] = useState<any>("")
  const [alternateMobileNumber, setAlternateMobileNumber] = useState<any>("")
  const [whatsappNumber, setWhatsappNumber] = useState<any>("")
  const [email, setEmail] = useState<any>("")
  const [collectionDate, setCollectionDate] = useState<any>("")
  const [pincode, setPincode] = useState<any>("")
  const [customerAreaPincode, setCustomerAreaPincode] = useState<any>("")
  const [customerState, setCustomerState] = useState<any>("")
  const [customerCity, setCustomerCity] = useState<any>("")
  const [customerAddress, setCustomerAddress] = useState<any>("")
  const [customerLandMarks, setCustomerLandMarks] = useState<any>("")
  const [phleboId, setPhleboId] = useState<any>("")
  const [totalPackagePrice, setTotalPackagePrice] = useState<any>("")
  const [coupanDiscount, setCoupanDiscount] = useState<any>("")
  const [finalPackagePrice, setFinalPackagePrice] = useState<any>("")
  const timer = useRef<any>(0);
  const salulationList = [
    { title: 'Mr', value: "Mr" },
    { title: 'Mrs', year: "Mrs" },
    { title: 'Ms', year: "Ms" },
    { title: 'Dr', year: "Dr" },
    { title: 'Master', year: "Master" },
  ];

  const [inputList, setInputList] = useState<any>([
    {
      customerDesignation: "",
      customerName: "",
      customerAge: "",
      customerGender: "",
      customerPackage: [],
      packagePrice: 0,
      dateOfBirth: "",
      passport: "",
      aadhaar: "",
    },
  ]);

  useEffect(() => {
    if (params.id !== "")
      getBookingById(`?booking_id=${params.id}`)
  }, [params])
  //api calls
  useEffect(() => {
    getBookingTimeSlots()
    getPackageDetails()
    getPhlebo()
  }, [])
  const handleSubmit = async () => {
    let packagesIds: any = [];
    inputList &&
      inputList[0]?.customerPackage.map((item: any) => {
        packagesIds.push(item._id);
      });
    if (updateDBooking.status === "BOOKING UPDATED SUCCESSFULLY") {
      navigate(`/dashboard/admin`);
    }
    const body = {
      title: inputList[0].customerDesignation,
      customer_name: inputList[0].customerName,
      age: Number(inputList[0].customerAge),
      gender: inputList[0].customerGender,
      passport_no: inputList[0].passport,
      aadhar_no: inputList[0].aadhaar,
      package_id: packagesIds,
      mobile_no: mobileNumber,
      alternate_no: alternateMobileNumber,
      whats_app_no: whatsappNumber,
      email: email,
      time_slots_id: selectedSlot,
      city_id: customerCity,
      customer_pincode: customerAreaPincode,
      customer_state: customerState,
      customer_address: customerAddress,
      customer_landmarks: customerLandMarks,
      collection_date: collectionDate,
      phlebos_id: phleboId,
      date: moment(new Date()).format("YYYY-MM-DD")
      
    }
    await updateBooking(body, bookingData?.booking_id)
  }

  useEffect(() => {
    if (bookingList && bookingList?.results && bookingList?.results.length > 0) {
      setBookingData(bookingList?.results[0])
    }
  }, [bookingList])
  useEffect(() => {
    if (bookingList && bookingList?.results && bookingList?.results.length > 0) {
      setBookingData(bookingList?.results[0])
    }
  }, [bookingList])
  useEffect(() => {
    if (bookingData)
      setCollectionDate(moment.utc(bookingData?.collection_date).format("YYYY-MM-DD"))
    setInputList([{
      customerDesignation: bookingData?.title,
      customerName: bookingData?.customer_name,
      customerAge: bookingData?.age,
      customerGender: bookingData?.gender,
      customerPackage: bookingData?.packages,
      packagePrice: 0,
      dateOfBirth: "",
      passport: bookingData?.passport_no,
      aadhaar: bookingData?.aadhar_no

    }])
    setMobileNumber(bookingData?.mobile_no)
    setAlternateMobileNumber(bookingData?.alternate_no)
    setWhatsappNumber(bookingData?.whats_app_no)
    setEmail(bookingData?.email)
    setCustomerAddress(bookingData?.customer_address)
    setCustomerLandMarks(bookingData?.customer_landmarks)
    setCustomerCity(bookingData?.city_id)
    setCustomerState(bookingData?.customer_state)
    setSelectedSlot(bookingData?.time_slot?._id)
    setSelectedSlot(bookingData?.time_slot?._id)
    setCustomerAreaPincode(bookingData?.customer_pincode)
    setTotalPrice(bookingData?.booking_cost?.total_price_package)
     setPhleboCost(bookingData.booking_cost?.phlebo_cost)
     setTotalPackagePrice(bookingData.booking_cost?.total_price_package)
     setCoupanDiscount(bookingData.booking_cost?.counpon_discount)
     setFinalPackagePrice(bookingData.booking_cost?.final_total_price)
  }, [bookingData])
   console.log("bookingList", bookingData)
  return (
    <main className={classes.content}>
      <BasicDetails
        error={error}
        setError={setError}
        inputList={inputList}
        setInputList={setInputList}
        salulationList={salulationList}
        packageDetails={packageDetails}
        totalPrice={totalPrice}
        setTotalPrice={setTotalPrice}
        collectionDate={collectionDate}
        setCollectionDate={setCollectionDate}
        activePincodeList={activePincodeList}
        pincode={pincode}
        setPincode={setPincode}
        setCustomerAreaPincode={setCustomerAreaPincode}
        setCustomerState={setCustomerState}
        setCustomerCity={setCustomerCity}
        getActivePinCode={getActivePinCode}
        bookingData={bookingData}
        phleboList={phleboList}
        phleboId={phleboId}
        setPhleboId={setPhleboId}
      />
      <ContactDetails
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
        alternateMobileNumber={alternateMobileNumber}
        setAlternateMobileNumber={setAlternateMobileNumber}
        whatsappNumber={whatsappNumber}
        setWhatsappNumber={setWhatsappNumber}
        email={email}
        setEmail={setEmail}
        error={error}
      />
      <TimeSlotsPackageDetails
        timeslots={timeSlots}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        inputList={inputList}
        setInputList={setInputList}
        cValue={cValue}
        setCValue={setCValue}
        singlePackage={singlePackage}
        setSinglePackage={setSinglePackage}
        isPaneOpen={isPaneOpen}
        setPaneOpen={setPaneOpen}
        cType={cType}
        setCType={setCType}
        couponKey={couponKey}
        price={price}
        setPrice={setPrice}
        coupon={coupon}
        setCoupon={setCoupon}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
        discount={discount}
        setDiscount={setDiscount}
        partnerCodeDiscount={partnerCodeDiscount}
        setPartnerCodeDiscount={setPartnerCodeDiscount}
        redProMembershipDiscount={redProMembershipDiscount}
        setRedProMembershipDiscount={setRedProMembershipDiscount}
        discountDueSmartPackage={discountDueSmartPackage}
        setDiscountDueSmartPackage={setDiscountDueSmartPackage}
        referralPointsDiscount={referralPointsDiscount}
        setReferralPointsDiscount={setReferralPointsDiscount}
        referralPointsApplied={referralPointsApplied}
        setReferralPointsApplied={setReferralPointsApplied}
        referralPoints={referralPoints}
        setReferralPoints={setReferralPoints}
        extraCharge={extraCharge}
        phleboCost={phleboCost}
        totalPackagePrice={totalPackagePrice}
        coupanDiscount={coupanDiscount}
        finalPackagePrice={finalPackagePrice}
        totalPrice={totalPrice}
      />
      <CustomerAddress
        customerAreaPincode={customerAreaPincode}
        setCustomerAreaPincode={setCustomerAreaPincode}
        customerState={customerState}
        setCustomerState={setCustomerState}
        customerCity={customerCity}
        setCustomerCity={setCustomerCity}
        customerAddress={customerAddress}
        setCustomerAddress={setCustomerAddress}
        customerLandMarks={customerLandMarks}
        setCustomerLandMarks={setCustomerLandMarks}
        error={error}
        setError={setError}
      />
      <Grid
        container
        alignItems="center"
        spacing={3}
        style={{ margin: "1rem", alignItems: "center", justifyContent: "center" }}
      >
        <Grid item md={6}>
          <Button fullWidth variant="contained" onClick={handleSubmit}>Proceed for Booking</Button>
        </Grid>
      </Grid>
    </main>
  )

}
const mapStateToProps = (state: any) => ({
  timeSlots: state.AdminReducer.timeSlots,
  packageDetails: state.AdminReducer.packageDetails,
  activePincodeList: state.AdminReducer.activePincodeList,
  updateDBooking: state.AdminReducer.updateDBooking,
  bookingList: state.AdminReducer.bookingList,
  phleboList: state.AdminReducer.phleboList,
});
export default connect(mapStateToProps, {
  getBookingTimeSlots,
  getPackageDetails,
  updateBooking,
  getActivePinCode,
  getBookingById,
  getPhlebo
})(EditBooking);