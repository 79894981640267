import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { Button, Grid } from "@mui/material";
import BasicDetails from "./basicDetails";
import { createNewCustomer, getCampDetails } from "../../../action/phleboAction";
import './Index.sass'
const useStyles = makeStyles({
  content: {
    width: "100%",
    flexGrow: 21,
  },
  slotContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  slot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "0.5rem",
  },
  selectedSlot: {
    width: "150px",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
    cursor: "pointer",
    margin: "0.5rem",
  },
  packageLink: {
    color: "#ffffff",
    textDecoration: "none",
  },
})
interface Props {
  createNewCustomer: any;
  campList: any;
  getCampDetails:any;
}
const NewCustomer: React.FC<Props> = ({
  createNewCustomer,
  campList,
  getCampDetails,
}) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [customerName, setCustomerName] = useState<any>("")
  const [customerAge, setCustomerAge] = useState<any>("")
  const [customerGender, setCustomerGender] = useState<any>("")
  const [mobileNumber, setMobileNumber] = useState<any>("")
  const [customerVials, setCustomerVials] = useState<any>("")
  const [customerRadiology, setcustomerRadiology] = useState<any>("")
  const [customerWeight, setCustomerWeight] = useState<any>("")
  const [customerHeight, setCustomerHeight] = useState<any>("")
  const [customerPulseRate, setCustomerPulseRate] = useState<any>("")
  const [customerBP, setCustomerBP] = useState<any>("")
  const [customerEmail, setCustomerEmail] = useState<any>("")
  const [customerSignature, setCustomerSignature] = useState<any>("")
  const [campId, setCampId] = useState<any>("")

  console.log("campId",campId)
  const handleSubmit = async (e:any) => {
    e.preventDefault()
    setCustomerName('')
    setCustomerAge('')
    setCustomerGender('')
    setCustomerWeight('')
    setCustomerHeight('')
    setCustomerPulseRate('')
    setCustomerBP('')
    setCustomerVials('')
    setcustomerRadiology('')
    setCustomerEmail('')
    setMobileNumber('')
    const body = {
      camp_id: campId,
      name: customerName,
      age: customerAge,
      gender: customerGender,
      phone: mobileNumber,
      vials: customerVials,
      radiology: customerRadiology,
      height: customerHeight,
      weight: customerWeight,
      pulse_rate: customerPulseRate,
      BP: customerBP,
      email: customerEmail,
      customer_sign: customerSignature,
    }
    await createNewCustomer(body)
  }
  return (
    <main className={classes.content}>
      <form onSubmit={handleSubmit}>
        <BasicDetails
          error={error}
          setError={setError}
          customerName={customerName}
          setCustomerName={setCustomerName}
          customerAge={customerAge}
          setCustomerAge={setCustomerAge}
          customerGender={customerGender}
          setCustomerGender={setCustomerGender}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          customerVials={customerVials}
          setCustomerVials={setCustomerVials}
          customerRadiology={customerRadiology}
          setcustomerRadiology={setcustomerRadiology}
          customerWeight={customerWeight}
          setCustomerWeight={setCustomerWeight}
          customerHeight={customerHeight}
          setCustomerHeight={setCustomerHeight}
          customerPulseRate={customerPulseRate}
          setCustomerPulseRate={setCustomerPulseRate}
          customerBP={customerBP}
          setCustomerBP={setCustomerBP}
          customerEmail={customerEmail}
          setCustomerEmail={setCustomerEmail}
          customerSignature={customerSignature}
          setCustomerSignature={setCustomerSignature}
          campList={campList}
          campId={campId} 
          setCampId={setCampId}
        />
        {/* <ContactDetails
       mobileNumber={mobileNumber}
       setMobileNumber={setMobileNumber}
       alternateMobileNumber={alternateMobileNumber}
       setAlternateMobileNumber={setAlternateMobileNumber}
       whatsappNumber={whatsappNumber}
       setWhatsappNumber={setWhatsappNumber}
       email={email}
       setEmail={setEmail}
       error={error}
     />
     <TimeSlotsPackageDetails
     timeslots={timeslots}
     selectedSlot={selectedSlot}
     setSelectedSlot={setSelectedSlot}
     inputList={inputList}
     setInputList={setInputList}
     cValue={cValue}
     setCValue={setCValue}
     singlePackage={singlePackage}
     setSinglePackage={setSinglePackage}
     isPaneOpen={isPaneOpen}
     setPaneOpen={setPaneOpen}
     cType={cType}
     setCType={setCType}
     couponKey={couponKey}
     price={price}
     setPrice={setPrice}
     coupon={coupon}
     setCoupon={setCoupon}
     couponCode={couponCode}
     setCouponCode={setCouponCode}
     discount={discount}
     setDiscount={setDiscount}
     partnerCodeDiscount={partnerCodeDiscount}
     setPartnerCodeDiscount={setPartnerCodeDiscount}
     redProMembershipDiscount={redProMembershipDiscount}
     setRedProMembershipDiscount={setRedProMembershipDiscount}
     discountDueSmartPackage={discountDueSmartPackage}
     setDiscountDueSmartPackage={setDiscountDueSmartPackage}
     referralPointsDiscount={referralPointsDiscount}
     setReferralPointsDiscount={setReferralPointsDiscount}
     referralPointsApplied={referralPointsApplied}
     setReferralPointsApplied={setReferralPointsApplied}
     referralPoints={referralPoints}
     setReferralPoints={setReferralPoints}
     extraCharge={extraCharge}
     phleboCost={phleboCost}
     /> */}
        <Grid
          container
          alignItems="center"
          marginTop={2}
          style={{alignItems: "center", justifyContent: "center" }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Button type="submit" fullWidth variant="contained">Proceed for Adding Customer</Button>
          </Grid>

        </Grid>
      </form>
    </main>
  )

}
const mapStateToProps = (state: any) => ({
  campList: state.PhleboReducer.campList
});
export default connect(mapStateToProps, {
  createNewCustomer,
  getCampDetails
})(NewCustomer);