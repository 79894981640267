import * as React from 'react';
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { changeUserPassword } from '../../actions/loginActions'
const theme = createTheme();

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const useStyles = makeStyles(({

    container: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: '24',
        p: 4,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
}));

interface PropsCR {
    open: any,
    setOpen: any,
    handleClose: any,
    handleOpen: any,
    changeUserPassword: any

}
const UpdatePasswordModal: React.FC<PropsCR> = ({
    open,
    setOpen,
    handleClose,
    handleOpen,
    changeUserPassword,
}) => {
    const classes = useStyles();
    const { register,reset, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {
        const body = {
            username: data.username,
            password: data.oldpassword,
            newpassword: data.newpassword,
            confirmpassword: data.confirmpassword,
        }
        await changeUserPassword(body)
        handleClose()
        reset()
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Change Password
                    </Typography>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                    >
                        <TextField
                            className="input"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="username"
                            label="Username"
                            autoComplete="username"
                            autoFocus
                            {...register("username", {
                                required: "Username is Required",
                            })}
                            InputLabelProps={{
                                style: { color: '#0a0a0a',fontWeight:"bold"},
                              }}
                            required={errors.username ? true : false}
                            error={errors.username ? true : false}
                        />
                        <TextField
                            className="input"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="oldpassword"
                            label="Old Password"
                            autoComplete="oldpassword"
                            autoFocus
                            {...register("oldpassword", {
                                required: "Old is Required",
                            })}
                            InputLabelProps={{
                                style: { color: '#0a0a0a',fontWeight:"bold"},
                              }}
                            required={errors.oldpassword ? true : false}
                            error={errors.oldpassword ? true : false}
                        />
                        {errors.username && <span>Old Password is required</span>}
                        <TextField
                            className="input"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="newpassword"
                            label="New Password"
                            autoComplete="newpassword"
                            autoFocus
                            {...register("newpassword", {
                                required: "Old is Required",
                            })}
                            InputLabelProps={{
                                style: { color: '#0a0a0a',fontWeight:"bold"},
                              }}
                            required={errors.newpassword ? true : false}
                            error={errors.newpassword ? true : false}
                        />
                        {errors.username && <span>New Password is required</span>}
                        <TextField
                            className="input"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            type="password"
                            id="confirmpassword"
                            label="Confirm Password"
                            autoComplete="confirmpassword"
                            autoFocus
                            {...register("confirmpassword", {
                                required: "Old is Required",
                            })}
                            InputLabelProps={{
                                style: { color: '#0a0a0a',fontWeight:"bold"},
                              }}
                            required={errors.confirmpassword ? true : false}
                            error={errors.confirmpassword ? true : false}
                        />
                        {errors.username && <span>Confirm Password is required</span>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{background:"#07639d"}}
                            // disabled={loading}
                            className={classes.submit}
                        >
                            Update Password
                        </Button>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
    changeUserPassword
})(UpdatePasswordModal);