import React, { useEffect, useState, useRef } from "react";
// import logo from "../../../images/logo-transparent.7a4badaf.png";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ReactToPrint from "react-to-print";
import atarra from "./atarra.png";
// import civillines from "./civillines.png";
import medicalcollege from "./medicalcollege.png";
// import swasthyam from "./swasthyam.png";
import excel from "./excel.png";

interface PropsCR {
    patientName:any;
    bookingId:any;
    age:any;
    invoiceNumber:any;
    billDate:any;
    packagePrice:any;
    discount:any;
    grandTotal:any;
    packageName:any;
    testCode:any;
}

const InvoicePage: React.FC<PropsCR> = ({  
    patientName,
    bookingId,
    age,
    invoiceNumber,
    billDate,
    packagePrice,
    discount,
    grandTotal,
    packageName,
    testCode,
}) => {
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [cobrand, isCobrand] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    bId: "",
    iDate: "",
    cDate: "",
    cName: "",
    cAge: "",
    cAgeDob: "",
    cAddr: "",
    packages: [],
    totalPrice: "",
    totalDiscount: "",
    centre: "",
    centreAddr: "",
    payment: [],
  });



  const componentRef = useRef<any>(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return <button>Print Receipt</button>;
  }, []);
  const handleBack = () => {
    localStorage.removeItem("addonPrice");
    // history.goBack();
  };


  return (
    <div
      style={{
        margin: "50px auto",
      }}
    >
      <button
        style={{
          width: "120px",
          borderRadius: "5%",
          backgroundColor: "#ef5350",
          color: "white",
        }}
        onClick={handleBack}
      >
        {"<< Go Back"}
      </button>
      <ReactToPrint
        content={reactToPrintContent}
        trigger={reactToPrintTrigger}
      />
      <div
        style={{
          width: "842px",
          border: "1px solid grey",
          margin: 20,
          background: "#fff",
        }}
      >
        <div
          ref={componentRef}
          style={{
            margin: 20,
          }}
        >
          <div>
            <meta charSet="UTF-8" style={{ fontFamily: "sans-serif" }} />
            <meta
              name="viewport"
              // content="width=device-width, initial-scale=1.0"
              style={{ fontFamily: "sans-serif" }}
            />
            <title style={{ fontFamily: "sans-serif" }}>Invoice</title>
            <div
              className="invoiceContainer"
              style={{
                fontFamily: "sans-serif",
                marginInline: "5vw",
                marginTop: 0,
              }}
            >
              <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>
                <hr
                  className="headerLine"
                  style={{
                    border: "5px solid #47cacc",
                    fontFamily: "sans-serif",
                    width: "100%",
                  }}
                />
                {cobrand ? (
                  <div
                    className="logoContainer"
                    style={{
                      display: "flex",

                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      {/* <img
                        className="invoiceImage"
                        src={
                          String(booking?.centre_address?.lab) ===
                          "Banda- Medical College"
                            ? medicalcollege
                            : String(booking?.centre_address?.lab) ===
                              "Banda- Atarra"
                            ? atarra
                            : String(booking?.centre_address?.lab) ===
                              "Banda- Civil Lines"
                            ? civillines
                            : String(booking?.centre_address?.lab) ===
                              "Bikaner- Swasthyam"
                            ? swasthyam
                            : String(booking?.centre_address?.lab) ===
                              "Thane- Excel Lab"
                            ? excel
                            : ""
                        }
                        style={{ fontFamily: "sans-serif", width: "100%" }}
                      /> */}
                    </div>

                    <div style={{ width: "100%", marginTop: 10 }}>
                      <span
                        className="invoiceHeading"
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          display: "flex",
                        }}
                      >
                        {" "}
                        <div style={{ width: "50%" }}>
                          <strong style={{ fontFamily: "sans-serif" }}>
                            {data?.centre}
                          </strong>
                          <br />
                          CIN No. :U86905RJ2023PTC086046
                        </div>
                        <div style={{ width: "50%", marginTop: 20 }}>
                          Address : MEDI BIOTEST PRIVATE LIMITED,<br/>
                          Ward No.3,Gaanv 2 B Bdi, Kotha,Kotha,Sriganganagar,Ganganagar-335901,Rajasthan 
                        </div>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    className="logoContainer"
                    style={{
                      display: "flex",
                      padding: "1rem",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img
                        className="invoiceImage"
                        src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/medi-logo.jpg"
                        style={{ fontFamily: "sans-serif", height: "100px",width:"250px"}}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <span
                        className="invoiceHeading"
                        style={{
                          float: "right",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        <strong style={{ fontFamily: "sans-serif" }}>
                          {/* {data?.centre} */}
                        </strong>
                        <br />
                        CIN No. : U86905RJ2023PTC086046
                        <br />
                        Address : MEDI BIOTEST PRIVATE LIMITED<br/>Ward No.3,Gaanv 2 B Bdi, Kotha,Kotha,Sriganganagar,Ganganagar-335901,Rajasthan
                      </span>
                    </div>
                  </div>
                )}

                <hr style={{ fontFamily: "sans-serif" }} />
                <div
                  className="invoiceGrid"
                  style={{ marginTop: "-8px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                  <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Name:</span>
                      <b>{patientName}</b>{" "}
                    </span>

                    <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Booking Id:</span>
                      <b>{bookingId}</b>{" "}
                    </span>

                    {/* <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>GE Centre:</span>
                      <b>{data?.GeCode}</b>{" "}
                    </span> */}
                  </div>
                  <div style={{ width: "50%", marginLeft: "15%" }}>
                    <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Patient Age:</span>
                      <b>
                        {age}
                      </b>
                    </span>

                    <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Bill Date:</span>{" "}
                      {billDate}
                    </span>

                    <span style={{ display: "flex" }}>
                      <span style={{ width: 100 }}>Invoice No.:</span>{" "}
                      {invoiceNumber}
                    </span>
                  </div>
                </div>
                <hr style={{ clear: "left", fontFamily: "sans-serif" }} />

                <div style={{ fontFamily: "sans-serif" }}>
                  <div
                    className="invoiceTestDescHead"
                    style={{
                      backgroundColor: "#f4f4f4",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,

                      padding: "1rem",
                      paddingBottom: "2rem",
                    }}
                  >
                    <div
                      style={{
                        float: "left",
                        fontFamily: "sans-serif",
                        width: "17%",
                      }}
                    >
                      TEST CODE : 
                    </div>
                    <div
                      style={{
                        float: "left",
                        fontFamily: "sans-serif",

                        width: "70%",
                      }}
                    >
                      TEST NAME : 
                    </div>
                    <div
                      style={{
                        float: "right",
                        textAlign: "right",
                        fontFamily: "sans-serif",
                        width: "13%",
                      }}
                    >
                      AMOUNT
                    </div>
                  </div>
                  <div
                        className="billDetailsTests"
                        style={{
                          color: "black !important",
                          fontFamily: "sans-serif",
                          fontSize: "16px",
                          padding: "1rem",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            float: "left",
                            fontFamily: "sans-serif",
                            width: "17%",
                          }}
                        >
                          <strong style={{ fontFamily: "sans-serif" }}>
                          {testCode}
                          </strong>
                        </div>
                        <div
                          style={{
                            float: "left",
                            fontFamily: "sans-serif",
                            width: "70%",
                          }}
                        >
                          <strong style={{ fontFamily: "sans-serif" }}>
                          {packageName}
                          </strong>
                        </div>
                        <div
                          style={{
                            float: "right",
                            textAlign: "right",
                            fontFamily: "sans-serif",
                            width: "13%",
                          }}
                        >
                          <strong style={{ fontFamily: "sans-serif" }}>
                            ₹{packagePrice}
                          </strong>
                        </div>
                      </div>

                  <div
                    className="invoiceTestDescTotal"
                    style={{
                      backgroundColor: "#fbfbfb",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      marginTop: "1rem",
                      padding: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                      Total Amount
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{grandTotal}
                    </div>
                  </div>
                  <div
                    className="invoiceTestDescTotal"
                    style={{
                      backgroundColor: "#fbfbfb",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      padding: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                      Discount
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{discount}
                    </div>
                  </div>
                  <hr style={{ fontFamily: "sans-serif" }} />
                  <div
                    className="invoiceTestDescTotal1"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,
                      padding: "0 1rem 1rem 1rem",
                      paddingBottom: "1.3rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                      Grand Total
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{grandTotal}
                    </div>
                  </div>
                  <div
                    className="invoiceTestDescTotal2"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,
                      padding: "1rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                      Paid Amount
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{grandTotal}
                    </div>
                  </div>
                  {/* <div
                    className="invoiceTestDescTotal2"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,
                      padding: "1rem",
                    }}
                  >
                    <div style={{ float: "left", fontFamily: "sans-serif" }}>
                      {Number(data?.due) < 0
                        ? "Refundable Amount"
                        : "Due Amount"}
                    </div>
                    <div style={{ float: "right", fontFamily: "sans-serif" }}>
                      ₹{Number(data?.due)}
                    </div>
                  </div> */}
                </div>
             <div  className="invoiceSummary"
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "13px",
                    lineHeight: "1.4",
                    marginTop: "3rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    marginBottom: "11rem",
                  }}>
                  <div
                    style={{
                      float: "left",
                      fontFamily: "sans-serif",
                      width: "50%",
                    }}
                  >
                    <strong style={{ fontFamily: "sans-serif" }}>
                      Amount Chargable (in words)
                    </strong>
                    <div style={{ fontFamily: "sans-serif" }}>
                      <i style={{ fontFamily: "sans-serif" }}>
                        Rupees
                        {/* {price_in_words(data?.final)} */}
                        Only
                      </i>
                    </div>
                    <div
                      className="blueColor"
                      style={{
                        color: "#11406b",
                        fontFamily: "sans-serif",
                        fontSize: "15px",
                      }}
                    >
                      Company's PAN No.{" "}
                      <span style={{ fontFamily: "sans-serif" }}>
                        AAKCR7631M
                      </span>
                    </div>
                  </div>
                  {data?.centreType === "collection_center" ? (
                    <div
                      className="paymentSummary"
                      style={{
                        float: "right",
                        fontFamily: "sans-serif",
                        textAlign: "end",
                        width: "50%",
                      }}
                    >
                      <strong style={{ fontFamily: "sans-serif" }}>
                        Payment Mode Summary
                      </strong>
                      <table
                        className="invoiceSummaryTable"
                        style={{
                          borderCollapse: "collapse",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          marginTop: "1rem",
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr
                            style={{
                              backgroundColor: "#f1f1f1",
                              fontFamily: "sans-serif",
                            }}
                          >
                            <td
                              style={{
                                fontFamily: "sans-serif",
                                padding: ".6rem",
                                paddingRight: "1.6rem",
                              }}
                            >
                              Payment Date
                            </td>
                            <td
                              style={{
                                fontFamily: "sans-serif",
                                padding: ".6rem",
                                paddingRight: "1.6rem",
                              }}
                            >
                              Payment Mode
                            </td>
                            <td
                              style={{
                                fontFamily: "sans-serif",
                                padding: ".6rem",
                              }}
                            >
                              Amount
                            </td>
                          </tr>
                          {data?.payment.length > 0 &&
                            data?.payment.map((val: any) => {
                              if (val?.link_type !== "link") {
                                return (
                                  <tr
                                    style={{
                                      backgroundColor: "#f9f9f9",
                                      fontFamily: "sans-serif",
                                    }}
                                  >
                                    <td
                                      style={{
                                        fontFamily: "sans-serif",
                                        padding: ".6rem",
                                        paddingRight: "1.6rem",
                                      }}
                                    >
                                      {moment(val?.created_at).format(
                                        "DD MMM,YYYY"
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        fontFamily: "sans-serif",
                                        padding: ".6rem",
                                        paddingRight: "1.6rem",
                                      }}
                                    >
                                      {val?.amount < 0
                                        ? "refund"
                                        : val?.link_type}
                                    </td>
                                    <td
                                      style={{
                                        fontFamily: "sans-serif",
                                        padding: ".6rem",
                                      }}
                                    >
                                      ₹{val?.amount}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                        </tbody>
                      </table>
                      <hr style={{ fontFamily: "sans-serif" }} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="invoiceNotes"
                  style={{
                    clear: "left",
                    backgroundColor: "#fbfbfb",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    lineHeight: "1.4",
                    marginBottom: "1rem",
                    marginTop: "1.6rem",
                    padding: ".5rem",
                  }}
                >
                  <strong style={{ fontFamily: "sans-serif" }}>
                    Important Notes:
                  </strong>
                  <ul style={{ fontFamily: "sans-serif" }}>
                    <li style={{ fontFamily: "sans-serif" }}>
                      This invoice can be used for tax exemption under section
                      80-D (Preventive Health Checkup)
                    </li>
                    {/* <li style={{ fontFamily: "sans-serif" }}>
                      Reports sent on registered mail ID according to the
                      respective TAT and can also be downloaded through
                      Redcliffe Labs Application
                    </li> */}
                    <li style={{ fontFamily: "sans-serif" }}>
                      All dispute / claims concerning to reports are subject to
                      the courts of Delhi only
                    </li>
                    {/* <li style={{ fontFamily: "sans-serif" }}>
                      Medibiotest Labs assumes no liability towards any delays
                      beyond its control
                    </li> */}
                    <li style={{ fontFamily: "sans-serif" }}>
                      It is recommended that you consult your treating
                      Doctor/Physician before choosing any treatment based on
                      your report
                    </li>
                    {/* <li style={{ fontFamily: "sans-serif" }}>
                      Maximum liability of the Company shall not exceed the
                      amount charged by the Company for the services availed
                    </li> */}
                    <li style={{ fontFamily: "sans-serif" }}>
                      This is a computer generated receipt and does not require
                      signature/stamp
                    </li>
                  </ul>
                </div>
                <div
                  className="invoiceFooter"
                  style={{
                    backgroundColor: "#47cacc",
                    color: "#0a2d4e",
                    fontFamily: "sans-serif",
                    fontSize: "16px",
                    padding: "1.8rem",
                    paddingBottom: "2.9rem",
                  }}
                >
                  <div
                    className="invoiceFooterItem"
                    style={{
                      float: "left",
                      width: "33.33%",
                      alignItems: "center",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <span style={{ color: "#e2124f", fontWeight: 700 }}>
                      &nbsp;930-525-2569
                    </span>
                  </div>
                  <div
                    className="invoiceFooterItem"
                    style={{
                      float: "left",
                      width: "33.33%",
                      alignItems: "center",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <span style={{ color: "#e2124f", fontWeight: 700 }}>
                      &nbsp;tech@medibiotest.com
                    </span>
                  </div>
                  <div
                    className="invoiceFooterItem"
                    style={{
                      float: "right",
                      width: "33.33%",
                      alignItems: "center",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <span style={{ color: "#e2124f", fontWeight: 700 }}>
                      &nbsp;www.medibiotest.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
//   booking: state.CCPartnerReducer.booking,
});

export default connect(mapStateToProps, {
//   getBookingById,
})(InvoicePage);