import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { getBookingById } from '../../../action/adminAction'
import { useParams } from "react-router-dom";
import LoaderCustom from "../../../../components/loader/Loader";
import Loader from "../../../../components/loader";
import moment from "moment";
import './index.sass'
const theme = createTheme();
const useStyles = makeStyles({
  root: {
    flexGrow: 0,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    width: "80%",
    flexGrow: 21,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(3),
  },
})
interface Props {
  getBookingById: any;
  bookingList: any;
}
const ViewBooking: React.FC<Props> = ({
  getBookingById,
  bookingList
}) => {
  const params = useParams();
  const classes = useStyles();
  const [bookingData, setBookingData] = useState<any>({})

  useEffect(() => {
    if (params.id !== "")
      getBookingById(`?booking_id=${params.id}`)
  }, [params])
  useEffect(() => {
    if (bookingList && bookingList?.results && bookingList?.results.length > 0) {
      setBookingData(bookingList?.results[0])
    }
  }, [bookingList])
  console.log("bookingList",bookingList)
  return (
    <main className={classes.content}>
      <br />
      <Paper className={classes.paper} elevation={15}>
        <h3 style={{ textAlign: "center" }}>View Booking Details</h3>
        {bookingList && bookingList?.results && bookingList?.results.length > 0 ? (
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            spacing={3}
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={6} md={3}>
              <h6>Collection Date</h6>
              <p style={{ fontWeight: "normal" }}>
                {moment.utc(bookingData?.collection_date).format('MM-DD-YYYY')}
              </p>
            </Grid>
            <Grid item xs={6} md={3}>
              <h6>Pincode</h6>
              <p style={{ fontWeight: "normal" }}>
                {bookingData?.customer_pincode}
              </p>
            </Grid>
            <Grid item xs={6} md={3}>
              <h6>Time Slot</h6>
              <p style={{ fontWeight: "normal" }}>

                {`${bookingData?.time_slot?.start_time} - ${bookingData?.time_slot?.end_time}`}
              </p>
            </Grid>
            <Grid item xs={6} md={3}>
              <h6>Booking Id</h6>
              <p style={{ fontWeight: "normal" }}>{bookingData?.booking_id}</p>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Customer Name</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Age</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Gender</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">City</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Package</TableCell>
                    <TableCell style={{ background: "#0A1929", color: "#fff" }} align="center">Total Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{bookingData?.customer_name}</TableCell>
                    <TableCell align="center">{bookingData?.age}</TableCell>
                    <TableCell align="center">{bookingData?.gender}</TableCell>
                    <TableCell align="center">{bookingData?.city_id}</TableCell>
                    <TableCell align="center">
                      <ul>
                        {bookingData && bookingData.packages && bookingData.packages.map((data: any, index: any) => {
                          return (
                            <li key={index}
                              style={{
                                margin: "1rem 0",
                                textAlign: "left",
                              }}>
                              {data.name}
                            </li>
                          )
                        })}

                      </ul>
                    </TableCell>
                    <TableCell align="center">
                      {bookingData?.booking_cost?.total_price_package || 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Mobile Number
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={bookingData?.mobile_no || ""}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Alternate Number
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={bookingData?.alternate_no || ""}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Phlebo Name
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={bookingData?.phlebos?bookingData?.phlebos?.fullname:""}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              direction="row"
              alignItems="center"
              container
              item
              xs={12}
              md={6}
              spacing={3}
            >
              <Grid item xs={12} md={5}>
                <p>
                  Phlebo Number
                </p>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  name="customer_phonenumber"
                  type="text"
                  value={bookingData?.phlebos?bookingData?.phlebos?.mobile:""}
                  disabled
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                direction="row"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12}>
                  <Divider style={{ backgroundColor: "#009fff" }} />
                  <h3 style={{ textAlign: "center" }}>Address</h3>
                </Grid>
                <Grid
                  direction="row"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Customer Address</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="houseNumber"
                      type="text"
                      value={
                        bookingData?.customer_address
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Landmarks</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_address"
                      type="text"
                      value={bookingData?.customer_landmarks || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>City</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="customer_landmark"
                      type="text"
                      value={bookingData?.city_id || ""}
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>City</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="city"
                      type="text"
                      value={
                        bookingData?.customer_state
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Pincode</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="city"
                      type="text"
                      value={
                        bookingData?.customer_pincode
                      }
                      disabled
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                </Grid>
          </Grid>
        ) : <Loader />}

      </Paper>
    </main>
  )
}

const mapStateToProps = (state: any) => ({
  bookingList: state.AdminReducer.bookingList,
});
export default connect(mapStateToProps, {
  getBookingById
})(ViewBooking);
