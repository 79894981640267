import React from "react";
import { Grid, Autocomplete, TextField, Checkbox } from "@mui/material";
import { error } from "console";
import { GENDER_CHOICE } from "../../../../constant";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
interface Props {
    error: any
    setError: any
    inputList: any
    setInputList: any;
    salulationList: any;

}
const BasicDetails: React.FC<Props> = ({
    error,
    setError,
    inputList,
    setInputList,
    salulationList,

}) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
        { title: "Schindler's List", year: 1993 },
        { title: 'Pulp Fiction', year: 1994 },
    ];
    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        if (name === "customerName") {
            const newValue = e.target.value;

            if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
                setError("");
            } else {
                setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
            }
        }
        const list = [...inputList];
        if (name === "passport") {
            const result = value.replace(/[^A-Z0-9]/, "");
          
            list[index][name] = result;
        } else {
            list[index][name] = value;
        }
        if (e.target.name === "designation") {
            const findGender = GENDER_CHOICE.filter(
                (el: any) => el.display_name===e.target.value
            );
            list[index]["customerGender"] = findGender[0]?.gender;
            list[index]["customerDesignation"] = findGender[0]?.db_name;
        }
        if (e.target.name==="dateOfBirth") {
            let today = new Date();
            let birthDate = new Date(e.target.value);
            let age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            list[index]["customerAge"] = age;
        }
        setInputList(list);
    };
    return (
        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                spacing={3}
                style={{ margin: "1rem auto", background: "#eff" }}
            >
                {inputList.map((x: any, index: number) => {
                    return (
                        <>
                            <Grid item md={1}>
                                {/* {inputList.length !== 1 && (
                                    <HighlightOffIcon
                                        style={{
                                            marginBottom: "1rem",
                                            height: "40px",
                                            width: "40px",
                                            borderRadius: "50%",
                                        }}
                                        onClick={() => handleRemoveClick(index)}
                                    />
                                )}
                                {inputList.length - 1 === index && inputList.length < 5 && (
                                    <AddCircleIcon
                                        style={{
                                            marginBottom: "1rem",
                                            height: "40px",
                                            width: "40px",
                                            borderRadius: "50%",
                                        }}
                                        onClick={handleAddClick}
                                    />
                                )} */}
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                spacing={3}
                                style={{ margin: "1rem auto", background: "#e1f5fe" }}
                            >
                                <Grid item md={3}>
                                    <Autocomplete
                                        className="input"
                                        id="free-solo-demo"
                                        freeSolo
                                        style={{ width: "100%", margin: "0" }}
                                        options={salulationList.map((option: any) => option.title)}
                                        renderInput={(params) =>
                                            <TextField
                                                className="input"
                                                {...params} label="Title" />}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="customerName"
                                        type="text"
                                        label="Customer Name"
                                        value={x.customerName || ""}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%", margin: "0" }}
                                        helperText={error} // error message
                                        error={!!error}
                                        onChange={(e) => handleInputChange(e, index)}
                                        required
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <input
                                        className="input"
                                        name="dateOfBirth"
                                        type="date"
                                        value={x.dateOfBirth}
                                        onChange={(e) => handleInputChange(e, index)}
                                        min={`${new Date().getFullYear() - 150}-${new Date().getMonth() + 1 < 10
                                            ? `0${new Date().getMonth() + 1}`
                                            : new Date().getMonth() + 1
                                            }-${new Date().getDate() < 10
                                                ? `0${new Date().getDate()}`
                                                : new Date().getDate()
                                            }`}
                                        max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                            ? `0${new Date().getMonth() + 1}`
                                            : new Date().getMonth() + 1
                                            }-${new Date().getDate() < 10
                                                ? `0${new Date().getDate()}`
                                                : new Date().getDate()
                                            }`}
                                        style={{
                                            width: "100%",
                                            padding: "15px 14px",
                                            background: "#eff",
                                            height: "40px",
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="customerAge"
                                        type="number"
                                        label="Age"
                                        value={x.customerAge}
                                        disabled={x.dateOfBirth !== ""}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0" }}
                                        inputProps={{ min: 0, max: 100 }}
                                        onChange={(e) => {
                                            let ageValue = parseInt(e.target.value);
                                            if (ageValue > 150) ageValue = 150;
                                            if (ageValue < 0) ageValue = 0;
                                            e.target.value = ageValue.toString();
                                            handleInputChange(e, index);
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="customerGender"
                                        variant="outlined"
                                        label="Gender"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={
                                            x.customerGender === "male"
                                                ? "Male"
                                                : x.customerGender === "female"
                                                    ? "Female"
                                                    : x.customerGender === "other" ? "Others" : ""
                                        }
                                        style={{ width: "100%", margin: "0", height: "40px" }}
                                        onChange={(e) => handleInputChange(e, index)}
                                        disabled={true}
                                        required={true}
                                    />
                                    <p style={{ color: "red" }}>
                                        {inputList[0]?.customerGender === null
                                            ? "Please select gender"
                                            : ""}
                                    </p>
                                </Grid>

                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="passport"
                                        type="text"
                                        label="Passport Number"
                                        value={x.passport || ""}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%", margin: "0" }}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.toString().slice(0, 10);
                                        }}
                                        onChange={(e) => handleInputChange(e, index)}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="aadhaar"
                                        type="text"
                                        label="Aadhaar Number"
                                        value={x.aadhaar || ""}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%", margin: "0" }}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.toString().slice(0, 10);
                                        }}
                                        onChange={(e) => handleInputChange(e, index)}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={top100Films}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.title}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.title}
                                            </li>
                                        )}
                                        style={{ width: "100%", margin: "0" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Package"
                                            />
                                        )}
                                    />
                                </Grid>
                                {+inputList[0]?.customerAge < 5 && inputList[0]?.customerAge !== "" && <p style={{ textAlign: 'center', width: '100%', color: 'red' }}>Age cannot be less than 5 years.</p>}
                            </Grid>
                          
                        </>
                    );
                })}
            </Grid>
        </Grid>
    )
}
export default BasicDetails