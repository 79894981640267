import { ACTIONS } from "../interfaces/adminType";
import AXIOS from "../../config/Axios";
import Prefix from "../../config/ApiPrefix";
// import SecureStorage from "../../config/SecureStorage";
import snackBarUpdate from "../../actions/snackBarActions";
import SecureStorage from "../../config/SecureStorage";


  export const getUserList = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/users`,
  
        { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_USER_LIST, payload: data });
    } catch (err: any) {
      console.log(err)
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const createNewUser = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(`users/create`,
            body,
            { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
        );
        snackBarUpdate({
          payload: {
              message: "User Created Successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);

        dispatch({ type: ACTIONS.CREATE_NEW_USER, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = String(err.response.data.message);
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const updateUserProfile =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/users/update/${id}`,
        body,
        {
          headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` },
        }
      );

      dispatch({
        type: ACTIONS.UPDATE_USER_PROFILE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "User Profile Updated successfully!!!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getBookingTimeSlots = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `/timeslot/getAllTimeslots`,
  
        { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_BOOKING_TIMESLOTS, payload: data });
    } catch (err: any) {
      console.log(err)
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getPackageDetails = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `/package/getAllPackage`,
  
        { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_PACKAGE_DETAILS, payload: data });
    } catch (err: any) {
      console.log(err)
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getAllBooking = (filter: number,url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `/booking/getAllBooking${filter?filter:""}`,
  
        { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_ALL_BOOKING, payload: data });
    } catch (err: any) {
      console.log(err)
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title|| "Data Not Found",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getBookingComments = (bookingId: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `/booking/comments/${bookingId}`,
  
        { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_COMMENTS, payload: data });
    } catch (err: any) {
      console.log(err)
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title|| "Data Not Found",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const createComments = (body: any,bookingId:any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(`/booking/create-comment/${bookingId}`,
            body,
            { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
        );
        snackBarUpdate({
          payload: {
              message: "Comment Added Successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);

        dispatch({ type: ACTIONS.GET_DISCOUNT_PRICE, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = String(err.response.data.error);
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};


  export const getDiscountBooking = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(`/discount/price`,
            body,
            { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
        );
        snackBarUpdate({
          payload: {
              message: "Discount Applied successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);

        dispatch({ type: ACTIONS.GET_DISCOUNT_PRICE, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = String(err.response.data.error);
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

  export const getBookingById = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `/booking/getAllBooking${url?url:""}`,
  
        { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_ALL_BOOKING, payload: data });
    } catch (err: any) {
      console.log(err)
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const createBooking = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(`/booking/createBooking`,
            body,
            { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
        );
        snackBarUpdate({
          payload: {
              message: "Booking Created Successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);

        dispatch({ type: ACTIONS.CREATE_BOOKING, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = String(err.response.data.error);
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
  export const updateBooking = (body: any,bookingId:any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.patch(`/booking/updateBooking/${bookingId}`,
            body,
            { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
        );
        snackBarUpdate({
          payload: {
              message: "Booking Updated Successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);

        dispatch({ type: ACTIONS.UPDATE_BOOKING, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = String(err.response.data.error);
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getActivePinCode = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `/pincode/getPinByCode/${url?url:""}`,

      { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ACTIVE_PINCODE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.error;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getAllPinCode = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `/pincode/getAllPincode/${url?url:""}`,

      { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ALL_PINCODE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.error;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getAllLeads = (filter:number,url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `/lead/${filter?filter:""}`,

      { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ALL_LEADS, payload: data });
  } catch (err: any) {
    console.log(err)
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getAllTimeslots = (filter: number,url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `/timeslot/getAllTimeslots${filter?filter:""}`,

      { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ALL_TIMESLOTS, payload: data });
  } catch (err: any) {
    console.log(err)
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPhlebo = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `/phlebos`,

      { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PHLEBOS, payload: data });
  } catch (err: any) {
    console.log(err)
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const activeDeactivePincode = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
      const { data } = await AXIOS.post(`pincode/updatePincodeStatus`,
          body,
          { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
        payload: {
            message: "Pincode update Successfully!",
            status: true,
            type: "success",
        },
    })(dispatch);

      dispatch({ type: ACTIONS.ACTIVE_DEACTIVE_PINCODE, payload: data });
  } catch (err: any) {
      let title = "";
      if (err.response) {
          title = String(String(err.response.data.error));
      } else {
          title = "Something went wrong!";
      }
      snackBarUpdate({
          payload: {
              message: title || "Something went wrong!",
              status: true,
              type: "error",
          },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
  }
};