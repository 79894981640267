import { Grid, TextField } from "@mui/material";
import React from "react";

interface Props {
    campAreaPincode: any;
    setCampAreaPincode: any;
    campState: any;
    setCampState: any;
    campCity: any;
    setCampCity: any;
    campAddress: any;
    setCampAddress: any;
    campLandMarks: any;
    setcampLandMarks: any;
    error: any;
    setError: any;
}
const CampAddress: React.FC<Props> = ({
    campAreaPincode,
    setCampAreaPincode,
    campState,
    setCampState,
    campCity,
    setCampCity,
    campAddress,
    setCampAddress,
    campLandMarks,
    setcampLandMarks,
    error,
    setError,
}) => {
    return (
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                spacing={2}
                marginLeft={0}
                paddingRight={2}
                paddingTop={2}
                paddingBottom={2}
                style={{ background: "#eff"}}
            >
                <Grid item xs={12} sm={6} md={4} >
                    <TextField
                        className="input"
                        name="campAddress"
                        type="text"
                        label="Camp Address"
                        value={campAddress}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCampAddress(e.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                    <TextField
                        className="input"
                        name="customerlocality"
                        type="text"
                        label="Landmarks"
                        value={campLandMarks}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        onChange={(e) => setcampLandMarks(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        className="input"
                        name="city"
                        type="text"
                        label="Camp City"
                        value={campCity}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCampCity(e.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        className="input"
                        name="customerName"
                        type="text"
                        label="Camp Name"
                        value={campAreaPincode}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCampAreaPincode(e.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        className="input"
                        name="state"
                        type="text"
                        label="Camp State"
                        value={campState}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCampState(e.target.value)}
                        required
                    />
                </Grid>
            </Grid>
    )
}
export default CampAddress