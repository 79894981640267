import React, { useRef } from "react";
import { Grid, Autocomplete, TextField, Checkbox, MenuItem, Select } from "@mui/material";
import { error } from "console";
import { GENDER_CHOICE } from "../../../../constant";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import './Index.sass'
interface Props {
    error: any;
    setError: any;
    inputList: any;
    setInputList: any;
    salulationList: any;
    totalPrice: any;
    setTotalPrice: any;
    collectionDate: any;
    setCollectionDate: any;
    pincode: any;
    setPincode: any
    setCampAreaPincode: any;
    setCampState: any;
    setCampCity: any;
    getActivePinCode: any;
    activePincodeList: any;
    handleReset: any;
    campName: any;
    setCampName: any;
    campData:any;
}
const BasicDetails: React.FC<Props> = ({
    error,
    setError,
    inputList,
    setInputList,
    salulationList,
    totalPrice,
    setTotalPrice,
    collectionDate,
    setCollectionDate,
    pincode,
    setPincode,
    setCampAreaPincode,
    setCampState,
    setCampCity,
    getActivePinCode,
    activePincodeList,
    handleReset,
    campName,
    setCampName,
    campData,
}) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const timer = useRef<any>(0);
    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        if (name === "customerName") {
            const newValue = e.target.value;

            if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
                setError("");
            } else {
                setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
            }
        }
        const list = [...inputList];
        if (name === "passport") {
            // const result = value.replace(/[^A-Z0-9]/, "");

            list[index][name] = value;
        } else {
            list[index][name] = value;
        }
        if (e.target.name === "designation") {
            const findGender = GENDER_CHOICE.filter(
                (el: any) => el.display_name === e.target.value
            );
            list[index]["customerGender"] = findGender[0]?.gender;
            list[index]["customerDesignation"] = findGender[0]?.db_name;
        }
        if (e.target.name === "dateOfBirth") {
            let today = new Date();
            let birthDate = new Date(e.target.value);
            let age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            list[index]["customerAge"] = age;
        }
        setInputList(list);
    };
    const handlePackage = (
        index: number,
        customerPackages: any,
        lastPack: any
    ) => {
        const list = [...inputList];

        list[index]["customerPackage"] = customerPackages;
        setInputList(list);

        let accumulatedPrice: any = 0;
        if (customerPackages && customerPackages.length > 0) {
            customerPackages.map((item: any) => {

                accumulatedPrice = accumulatedPrice + item.mbt_price;
                // }
            });
        }
        else if (customerPackages && customerPackages.length <= 0) {
            handleReset()
        }
        list[index]["packagePrice"] = accumulatedPrice;
        setInputList(list);
        let allPackagePrice = 0;
        inputList &&
            inputList.map((rowItem: any) => {
                console.log(rowItem, "rowItem===>rowItem")
                allPackagePrice = allPackagePrice + rowItem?.packagePrice;
            });
        console.log(allPackagePrice, "======?")
        setTotalPrice(Math.floor(allPackagePrice));
    };

    return (
        <Grid container spacing={3} width='100%' marginLeft={0.3} marginRight={0.4} marginTop={6}>
            <Grid container spacing={3} style={{ margin: "1rem" }} alignItems="flex-end">
                <h4>Edit CAMP</h4>
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                spacing={2}
                paddingRight={2}
                paddingBottom={2}
                marginLeft={0} marginRight={0.4}
                style={{ background: "#eff" }}
            >
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className="input"
                            name="create_data"
                            label="Camp Update Date"
                            type="date"
                            required
                             disabled
                            style={{ width: "100%", margin: "0", height: "40px" }}
                            value={moment(campData?.camp_date).format("YYYY-DD-MM")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        className="input"
                        name="campName"
                        type="text"
                        label="Camp Name"
                        value={campName}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        helperText={error} // error message
                        error={!!error}
                        onChange={(e) => setCampName(e.target.value)}
                        required
                    />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            id="coupon"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(
                                        JSON.stringify(newValue, null, " ")
                                    );
                                    setCampAreaPincode(obj?.pincode)
                                    setCampState(obj?.state)
                                    setCampCity(obj?.district)
                                    setPincode(obj)
                                }
                            }}
                            options={activePincodeList && activePincodeList?.results || []}

                            style={{ width: "100%", margin: "0", padding: "0px" }}
                            freeSolo
                            blurOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                setPincode(newInputValue);
                                if (newInputValue !== "")
                                    getActivePinCode(`?pincode=${newInputValue}`)

                            }}
                            getOptionLabel={(option: any) =>
                                "Pincode: " + option.pincode + " Distict: " + option.district
                            }
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Select Pincode"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0" }}
                                />
                            )}
                        />
                    </Grid> */}
                </>
            </Grid>
        </Grid>
    )
}
export default BasicDetails