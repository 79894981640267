import { ACTIONS, ValidationDashboarType } from "../interfaces/phleboAdminType";

type InitialState = {
  loading: boolean;
  sendOtploading: boolean;
  otpValidateLoding: boolean;
  mobileDetailsLoading: boolean;
  EmailDetailsLoading: boolean;
  BankDetailsLoading: boolean;
  panLoader: boolean;
  panLinkLoader: boolean;
  faceMatchLoading: boolean;
  ocrKycLoading: boolean;
  otpRequestData: Array<any>;
  validateReqData: Array<any>;
  MobileDetailsData: Array<any>;
  EmailDetailsData: Array<any>;
  BankDetailsData: Array<any>;
  panDetails: Array<any>;
  panLinkDetail: Array<any>
  faceMatchingResults: Array<any>
  ocrKycdata: Array<any>
  userList: Array<any>
  packageDetails: Array<any>
  doctorDetails: Array<any>
  getActivePincode: Array<any>
  phleboDetails:Array<any>
  campList:Array<any>
  updatedCampData:Array<any>
};

const initialState: InitialState = {
  loading: false,
  sendOtploading: false,
  otpValidateLoding: false,
  mobileDetailsLoading: false,
  EmailDetailsLoading: false,
  BankDetailsLoading: false,
  panLoader: false,
  panLinkLoader: false,
  faceMatchLoading: false,
  ocrKycLoading: false,
  otpRequestData: [],
  validateReqData: [],
  MobileDetailsData: [],
  EmailDetailsData: [],
  BankDetailsData: [],
  panDetails: [],
  panLinkDetail: [],
  faceMatchingResults: [],
  ocrKycdata: [],
  userList: [],
  doctorDetails: [],
  packageDetails: [],
  getActivePincode: [],
  phleboDetails:[],
  campList:[],
  updatedCampData:[],
};

const PhleboAdminReducer = (state = initialState, action: ValidationDashboarType) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_OTP_LOADING:
      return {
        ...state,
        sendOtploading: action.payload,
      };
    case ACTIONS.SET_MOBILE_DETAILS_LOADING:
      return {
        ...state,
        mobileDetailsLoading: action.payload,
      };
    case ACTIONS.SET_VALIDATE_OTP_LOADING:
      return {
        ...state,
        otpValidateLoding: action.payload,
      };
    case ACTIONS.SET_EMAIL_AUTH_LOADING:
      return {
        ...state,
        EmailDetailsLoading: action.payload,
      };
    case ACTIONS.SET_PAN_LOADING:
      return {
        ...state,
        panLoader: action.payload,
      };
    case ACTIONS.SET_PAN_LINK_LOADING:
      return {
        ...state,
        panLinkLoader: action.payload,
      };
    case ACTIONS.SET_BANK_AUTH_LOADING:
      return {
        ...state,
        BankDetailsLoading: action.payload,
      };
    case ACTIONS.SET_OCR_KYC_LOADING:
      return {
        ...state,
        ocrKycLoading: action.payload,
      };
    case ACTIONS.SET_FACE_MATCHING_LOADING:
      return {
        ...state,
        faceMatchLoading: action.payload,
      };
    case ACTIONS.GET_MOBILE_OTP:
      return {
        ...state,
        otpRequestData: action.payload,
        sendOtploading: false,
      };
    case ACTIONS.POST_VALIDATE_OTP_INPUT:
      return {
        ...state,
        validateReqData: action.payload,
        otpValidateLoding: false,
      };
    case ACTIONS.POST_MOBILE_DETAILS_DATA:
      return {
        ...state,
        MobileDetailsData: action.payload,
        mobileDetailsLoading: false,
      };
    case ACTIONS.POST_EMAIL_AUTHENTICATION_DATA:
      return {
        ...state,
        EmailDetailsData: action.payload,
        EmailDetailsLoading: false,
      };
    case ACTIONS.POST_BANK_AUTHENTICATION_DATA:
      return {
        ...state,
        BankDetailsData: action.payload,
        BankDetailsLoading: false,
      };
    case ACTIONS.POST_PAN_AUTHENTICATION_DATA:
      return {
        ...state,
        panDetails: action.payload,
        panLoader: false,
      };
    case ACTIONS.POST_PAN_LINK_WITH_AADHAAR_AUTHENTICATION_DATA:
      return {
        ...state,
        panLinkDetail: action.payload,
        panLinkLoader: false,
      };
    case ACTIONS.VALIDATE_FACE_MATCHING:
      return {
        ...state,
        faceMatchingResults: action.payload,
        faceMatchLoading: false,
      };
    case ACTIONS.VALIDATE_OCR_KYC:
      return {
        ...state,
        ocrKycdata: action.payload,
        ocrKycLoading: false,
      };
    case ACTIONS.GET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DOCTOR_DETAILS:
      return {
        ...state,
        doctorDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ACTIVE_PINCODE:
      return {
        ...state,
        activePincodeList: action.payload,
        loading: false,
      };
      case ACTIONS.GET_PHLEBO_DETAILS:
        return {
          ...state,
          phleboDetailsList: action.payload,
          loading: false,
        };
        case ACTIONS.GET_CAMP_DETAILS:
          return {
            ...state,
            campList: action.payload,
            loading: false,
          };
          case ACTIONS.UPDATE_CAMP:
          return {
            ...state,
            updatedCampData: action.payload,
            loading: false,
          };
    default:
      return state;
  }
};

export default PhleboAdminReducer;