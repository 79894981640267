import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Grid, TextField, Button } from "@mui/material";
import CampTable from "./campTable";
import { getCampDetails, deleteCreatedCamp, getCampDetailsById,updateCamp } from "../../action/phleboAdminAction";
import { generateCampFilter } from "../../../helpers/generateUrl";
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
})
interface Props {
    getCampDetails:any;
    campList:any;
    deleteCreatedCamp:any;
    getCampDetailsById:any;
    updateCamp:any;
}
const CampFilter: React.FC<Props> = ({
    getCampDetails,
    campList,
    deleteCreatedCamp,
    getCampDetailsById,
    updateCamp,
}) => {
    const classes = useStyles();
    const [inputList, setInputList] = useState<any>([
        {
            campCode: "",
            campName: "",
            packageName: "",
            campCity: "",
            doctorName: "",
            phleboName: "",
        },
    ]);

    useEffect(() => {
        getCampDetails()
    }, [])

// console.log(comments)
//     const onKeyDown = (e: any) => {
//         if (e.key === "Enter") {
//             //  filterBookings(e);
//         }
//     };
    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    }
    const handleSubmit = async (e: any) => {
        const body = {
            camp_code: inputList[0].campCode,
            camp_name: inputList[0].campName,
            phone: inputList[0].packageName,
            booking_date: inputList[0].campCity,
            doctor_name: inputList[0].doctorName,
            phlebo_name: inputList[0].phleboName,
        }
        const url = generateCampFilter(body).substring(2);
        getCampDetails(url ? `?${url}` : "")
    }

    const handle = (e: any, index: any) => {
        handleInputChange(e, index)
        handleSubmit(e)
    }

    const handleReset = () => {
        const resertData = { campCode: "", campName: "", packageName: "", campCity: "", doctorName: "", phleboName: ""}
        setInputList([{ campCode: "", campName: "", packageName: "", campCity: "", doctorName: "",phleboName: ""}])
        getCampDetails()
    }
    console.log("inputList",inputList)
    
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            {inputList.map((x: any, index: number) => {
                return (
                    <Grid container direction="row" spacing={2} >
                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                className="input"
                                label="Camp Code"
                                name="campCode"
                                id="outlined-start-adornment"
                                value={x.campCode || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                 onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    const inputVal = e.target.value;
                                    (inputVal.replace(/[^0-9]/g, ''))
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                       {/*   <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                label="Name"
                                name="campName"
                                value={x.campName || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                 onChange={(e) => handle(e, index)}
                                id="outlined-start-adornment"
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                       <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                label="Camp City"
                                name="campCity"
                                id="outlined-start-adornment"
                                value={x.campCity || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                 onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    const inputVal = e.target.value;
                                    (inputVal.replace(/[^0-9]/g, ''))
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                label="Package Name"
                                name="packageName"
                                id="outlined-start-adornment"
                                value={x.packageName || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                 onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    const inputVal = e.target.value;
                                    (inputVal.replace(/[^0-9]/g, ''))
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                className="input"
                                label="Doctor Name"
                                name="doctorName"
                                id="outlined-start-adornment"
                                value={x.doctorName || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                 onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    const inputVal = e.target.value;
                                    (inputVal.replace(/[^0-9]/g, ''))
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                className="input"
                                label="Phlebo Name"
                                name="phleboName"
                                id="outlined-start-adornment"
                                value={x.phleboName || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                 onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    const inputVal = e.target.value;
                                    (inputVal.replace(/[^0-9]/g, ''))
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                    </Grid>
                    
                )
            })}
            <Grid container direction="row" spacing={2} justifyContent="center" marginTop={1}>
                {/* <Grid item xs={12} sm={12} md={6}>
                    <Button
                        color="success"
                        style={{ width: '100%', margin: "0px", padding: "5px" }}
                        variant="contained"
                        onClick={(e: any) => handleSubmit(e)}
                    >
                        Filter
                    </Button>
                </Grid> */}
                <Grid item xs={12} sm={12} md={6}>
                    <Button
                        color="error"
                        style={{ width: '100%', margin: "0px", padding: "5px" }}
                        variant="contained"
                         onClick={handleReset}
                    >
                        RESET
                    </Button>
                </Grid>
            </Grid>
            <CampTable
                campList={campList}
                getCampDetails={getCampDetails}
                deleteCreatedCamp={deleteCreatedCamp}
                getCampDetailsById={getCampDetailsById}
                updateCamp={updateCamp}
            />
        </main>
    )
}
const mapStateToProps = (state: any) => ({
    campList: state.PhleboReducer.campList,
});
export default connect(mapStateToProps, {
    getCampDetails,
    deleteCreatedCamp,
    getCampDetailsById,
    updateCamp
})(CampFilter);