import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Paper, Grid, Autocomplete, TextField, Button } from "@mui/material";
import InvoicePage from './invoice'
const theme = createTheme();
const useStyles = makeStyles({
    root: {
        flexGrow: 0,
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    form: {
        width: "100%"
    },
    paper: {
        marginTop: "1rem",
        padding: "1rem 0.5rem 0.5rem 0.5rem",
        width: "100%",
    },
    hoveItem: {
        cursor: "pointer",
    },
    input: {
        "& .MuiOutlinedInput-input": {
            color: "green",
            padding: "9px"
        },
    },
    child1: {
        display: "inline-block",
        verticalAlign: "middle",
        alignItems: "right"
    },
    child2: {
        display: "inline-block",
        verticalAlign: "middle",
        alignItems: "left"
    },
    AutoCompleteinput: {
        "& .MuiOutlinedInput-input": {
            color: "green",
            padding: "6px",
            marginTop: "1px"
        },
    },

})
interface Props {

}


const InvoiceMaster: React.FC<Props> = ({ }) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [patientName,setPatientName]=useState<any>("")
    const [testCode,setTestCode]=useState<any>("")
    const [packageName,setPackageName]=useState<any>("")
    const [bookingId,setBookingId]=useState<any>("")
    const [age,setAge]=useState<any>("")
    const [invoiceNumber,setInvoiceNumber]=useState<any>("")
    const [billDate,setBillDate]=useState<any>("")
    const [packagePrice,setpackagePrice]=useState<number>(0)
    const [discount,setDiscount]=useState<number>(0)
    const [grandTotal,setGrandTotal]=useState<number>(0)

    const handleReset = () => {

    }
    const handleSubmit = () => {
    
    }
    useEffect(()=>{
        setGrandTotal(packagePrice-discount)
    },[packagePrice,discount])
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Paper
                elevation={5}
                className={classes.paper}
                sx={{ maxHeight: 250 }}
            >
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    style={{ alignItems: "left" }}
                >
                    <Grid item xs={3} sm={3} md={3}>
                        <TextField
                            id="outlined-required"
                            className={classes.input}
                            value={testCode}
                            onChange={(e:any)=>setTestCode(e.target.value)}
                            label="Test Code"
                            fullWidth
                            type="text"
                            autoComplete="outlined-required"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <TextField
                            id="outlined-required"
                            className={classes.input}
                            value={packageName}
                            onChange={(e:any)=>setPackageName(e.target.value)}
                            label="Package Name"
                            fullWidth
                            type="text"
                            autoComplete="outlined-required"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <TextField
                            id="outlined-required"
                            className={classes.input}
                            value={patientName}
                            onChange={(e:any)=>setPatientName(e.target.value)}
                            label="Patient Name"
                            fullWidth
                            type="text"
                            autoComplete="outlined-required"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <TextField
                            id="outlined-required"
                            className={classes.input}
                            value={age}
                            onChange={(e:any)=>setAge(e.target.value)}
                            label="Patient Age"
                            fullWidth
                            type="text"
                            autoComplete="outlined-required"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <TextField
                            id="outlined-required"
                            className={classes.input}
                            value={bookingId}
                            onChange={(e:any)=>setBookingId(e.target.value)}
                            label="Booking Id"
                            fullWidth
                            type="number"
                            autoComplete="outlined-required"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <TextField
                            id="outlined-required"
                            className={classes.input}
                            value={billDate}
                            onChange={(e:any)=>setBillDate(e.target.value)}
                            label="Bill Date"
                            fullWidth
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="outlined-required"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <TextField
                            id="outlined-required"
                            className={classes.input}
                            value={invoiceNumber}
                            onChange={(e:any)=>setInvoiceNumber(e.target.value)}
                            label="Invoice Number"
                            fullWidth
                            type="text"
                            autoComplete="outlined-required"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <TextField
                            id="outlined-required"
                            className={classes.input}
                            value={packagePrice}
                            onChange={(e:any)=>setpackagePrice(e.target.value)}
                            label="Package Price"
                            fullWidth
                            type="number"
                            autoComplete="outlined-required"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <TextField
                            id="outlined-required"
                            className={classes.input}
                            value={discount}
                            onChange={(e:any)=>setDiscount(e.target.value)}
                            label="Discount"
                            fullWidth
                            type="number"
                            autoComplete="outlined-required"
                        />
                    </Grid>
                
                    <Grid item xs={3} sm={3} md={3}>
                        <Button variant="contained" fullWidth color="success" onClick={handleSubmit}>
                            Update
                        </Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <Button variant="contained" fullWidth color="error" onClick={handleReset}>
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <InvoicePage 
                patientName={patientName}
                bookingId={bookingId}
                age={age}
                invoiceNumber={invoiceNumber}
                billDate={billDate}
                packagePrice={packagePrice}
                discount={discount}
                grandTotal={grandTotal}
                packageName={packageName}
                testCode={testCode}
            />

        </main>
    )
}

const mapStateToProps = (state: any) => ({

})

export default connect(mapStateToProps, {

})(InvoiceMaster)