import React, { useRef } from "react";
import { Grid, Autocomplete, TextField, Checkbox, MenuItem, Select } from "@mui/material";
import { error } from "console";
import { GENDER_CHOICE } from "../../../../constant";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './Index.sass'
interface Props {
    error: any;
    setError: any;
    inputList: any;
    setInputList: any;
    salulationList: any;
    packageDetails: any;
    totalPrice: any;
    setTotalPrice: any;
    collectionDate: any;
    setCollectionDate: any;
    activePincodeList: any;
    pincode: any;
    setPincode: any
    setCustomerAreaPincode: any;
    setCustomerState: any;
    setCustomerCity: any;
    getActivePinCode:any;
    getPackageDetails:any;
    handleReset:any;

}
const BasicDetails: React.FC<Props> = ({
    error,
    setError,
    inputList,
    setInputList,
    salulationList,
    packageDetails,
    totalPrice,
    setTotalPrice,
    collectionDate,
    setCollectionDate,
    activePincodeList,
    pincode,
    setPincode,
    setCustomerAreaPincode,
    setCustomerState,
    setCustomerCity,
    getActivePinCode,
    getPackageDetails,
    handleReset

}) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const timer = useRef<any>(0);
    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        if (name === "customerName") {
            const newValue = e.target.value;

            if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
                setError("");
            } else {
                setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
            }
        }
        const list = [...inputList];
        if (name === "passport") {
            // const result = value.replace(/[^A-Z0-9]/, "");

            list[index][name] = value;
        } else {
            list[index][name] = value;
        }
        if (e.target.name === "designation") {
            const findGender = GENDER_CHOICE.filter(
                (el: any) => el.display_name === e.target.value
            );
            list[index]["customerGender"] = findGender[0]?.gender;
            list[index]["customerDesignation"] = findGender[0]?.db_name;
        }
        if (e.target.name === "dateOfBirth") {
            let today = new Date();
            let birthDate = new Date(e.target.value);
            let age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            list[index]["customerAge"] = age;
        }
        setInputList(list);
    };
    const handlePackage = (
        index: number,
        customerPackages: any,
        lastPack: any
    ) => {
        const list = [...inputList];

        list[index]["customerPackage"] = customerPackages;
        setInputList(list);

        let accumulatedPrice: any = 0;
        if (customerPackages && customerPackages.length > 0) {
            customerPackages.map((item: any) => {

                accumulatedPrice = accumulatedPrice + item.mbt_price;
                // }
            });
        }
        else if(customerPackages && customerPackages.length <= 0){
            handleReset()
        }
        list[index]["packagePrice"] = accumulatedPrice;
        setInputList(list);
        let allPackagePrice = 0;
        inputList &&
            inputList.map((rowItem: any) => {
                console.log(rowItem,"rowItem===>rowItem")
                allPackagePrice = allPackagePrice + rowItem?.packagePrice;
            });
        console.log(allPackagePrice,"======?")
        setTotalPrice(Math.floor(allPackagePrice));
    };

    console.log(inputList,"inputList")
    return (
        <Grid container spacing={3} style={{ margin: "2rem auto" }}>
            <Grid  container spacing={3} style={{margin:"1rem"}} alignItems="flex-end">
                <h4>CREATE BOOKING</h4>
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                spacing={3}
                style={{ margin: "1rem auto", background: "#eff" }}
            >
                <>
                    <Grid item md={3}>
                        <TextField
                            className="input"
                            name="booking_data"
                            label="Booking Date"
                            type="date"
                            required
                            disabled
                            style={{ width: "100%", margin: "0", height: "40px" }}
                            value={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                ? `0${new Date().getMonth() + 1}`
                                : new Date().getMonth() + 1
                                }-${new Date().getDate() < 10
                                    ? `0${new Date().getDate()}`
                                    : new Date().getDate()
                                }`}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            className="input"
                            name="collection_date"
                            label="Collection Date"
                            type="date"
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", margin: "0", height: "40px" }}
                            value={collectionDate}
                            onChange={(e: any) => setCollectionDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Autocomplete
                            id="coupon"
                            className="input"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(
                                        JSON.stringify(newValue, null, " ")
                                    );
                                    setCustomerAreaPincode(obj?.pincode)
                                    setCustomerState(obj?.state)
                                    setCustomerCity(obj?.district)
                                    setPincode(obj)
                                    // setCoupon(obj.id as Number);
                                    // setCouponCode(obj.code as string);
                                    // setCouponType(obj.is_red_cash);
                                    // setRedCouponDiscount(
                                    //   obj.discount as number
                                    // );
                                }
                            }}
                            // inputValue={couponCode}
                            options={activePincodeList&&activePincodeList?.results || []}

                            style={{ width: "100%", margin: "0", padding: "0px" }}
                            freeSolo
                            blurOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                setPincode(newInputValue);
                                if(newInputValue!=="")
                               getActivePinCode(`?pincode=${newInputValue}`)
                               
                            }}
                            getOptionLabel={(option: any) =>
                                "Pincode: " + option.pincode + " Distict: " + option.district
                            }
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Select Pincode"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0" }}
                                />
                            )}
                        />
                    </Grid>
                </>
                {inputList.map((x: any, index: number) => {
                    return (
                        <>
                            <Grid item md={1}>
                                {/* {inputList.length !== 1 && (
                                    <HighlightOffIcon
                                        style={{
                                            marginBottom: "1rem",
                                            height: "40px",
                                            width: "40px",
                                            borderRadius: "50%",
                                        }}
                                        onClick={() => handleRemoveClick(index)}
                                    />
                                )}
                                {inputList.length - 1 === index && inputList.length < 5 && (
                                    <AddCircleIcon
                                        style={{
                                            marginBottom: "1rem",
                                            height: "40px",
                                            width: "40px",
                                            borderRadius: "50%",
                                        }}
                                        onClick={handleAddClick}
                                    />
                                )} */}
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                spacing={3}
                                style={{ margin: "1rem auto", background: "#e1f5fe" }}
                            >
                                <Grid item md={3}>
                                    <Select
                                        className="input"
                                        id="demo-simple-select-standard"
                                        name="designation"
                                        variant="outlined"
                                        label="Designation"
                                        value={x.designation}
                                        style={{ width: "100%", margin: "0", height: "40px" }}
                                        onChange={(e: any) => handleInputChange(e, index)}
                                        required
                                    // displayEmpty={true}
                                    // value={x.designation !== "" ? undefined : () => "Select Title"}
                                    >
                                        <MenuItem value=""><em>Designation</em></MenuItem>
                                        <MenuItem value="Mr">Mr</MenuItem>
                                        <MenuItem value="Ms">Ms (Mrs/Miss)</MenuItem>
                                        <MenuItem value="other">Others</MenuItem>
                                        {/* <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                      <MenuItem value="Baby Girl">Baby Girl</MenuItem> */}
                                    </Select>
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="customerName"
                                        type="text"
                                        label="Customer Name"
                                        value={x.customerName || ""}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%", margin: "0" }}
                                        helperText={error} // error message
                                        error={!!error}
                                        onChange={(e) => handleInputChange(e, index)}
                                        required
                                    />
                                </Grid>
                                <Grid item md={3}>

                                    <TextField
                                        className="input"
                                        name="dateOfBirth"
                                        label="DOB"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%", margin: "0", height: "40px" }}
                                        value={x.dateOfBirth}
                                        onChange={(e) => handleInputChange(e, index)}
                                        InputProps={{
                                            inputProps:
                                            {
                                                min: `${new Date().getFullYear() - 150}-${new Date().getMonth() + 1 < 10
                                                    ? `0${new Date().getMonth() + 1}`
                                                    : new Date().getMonth() + 1
                                                    }-${new Date().getDate() < 10
                                                        ? `0${new Date().getDate()}`
                                                        : new Date().getDate()
                                                    }`,
                                                max: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                                    ? `0${new Date().getMonth() + 1}`
                                                    : new Date().getMonth() + 1
                                                    }-${new Date().getDate() < 10
                                                        ? `0${new Date().getDate()}`
                                                        : new Date().getDate()
                                                    }`
                                            }
                                        }}

                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="customerAge"
                                        type="number"
                                        label="Age"
                                        value={x.customerAge}
                                        disabled={x.dateOfBirth !== ""}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0" }}
                                        inputProps={{ min: 0, max: 100 }}
                                        onChange={(e) => {
                                            let ageValue = parseInt(e.target.value);
                                            if (ageValue > 150) ageValue = 150;
                                            if (ageValue < 0) ageValue = 0;
                                            e.target.value = ageValue.toString();
                                            handleInputChange(e, index);
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="customerGender"
                                        variant="outlined"
                                        label="Gender"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={
                                            x.customerGender === "male"
                                                ? "Male"
                                                : x.customerGender === "female"
                                                    ? "Female"
                                                    : x.customerGender === "other" ? "Others" : ""
                                        }
                                        style={{ width: "100%", margin: "0", height: "40px" }}
                                        onChange={(e) => handleInputChange(e, index)}
                                        disabled={true}
                                        required={true}
                                    />
                                    <p style={{ color: "red" }}>
                                        {inputList[0]?.customerGender === null
                                            ? "Please select gender"
                                            : ""}
                                    </p>
                                </Grid>

                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="passport"
                                        type="text"
                                        label="Passport Number"
                                        value={x.passport || ""}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%", margin: "0" }}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.toString().slice(0, 15);
                                        }}
                                        onChange={(e) => handleInputChange(e, index)}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        className="input"
                                        name="aadhaar"
                                        type="text"
                                        label="Aadhaar Number"
                                        value={x.aadhaar || ""}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%", margin: "0" }}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.toString().slice(0, 15);
                                        }}
                                        onChange={(e) => handleInputChange(e, index)}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let packs: any = [];
                                                newValue.map((val: any) => {
                                                    let obj = JSON.parse(
                                                        JSON.stringify(val, null, " ")
                                                    );
                                                    packs.push(obj);
                                                });
                                                let len = packs.length;
                                                handlePackage(index, packs, packs[len - 1]);
                                            }
                                            // setCValue("")
                                        }}
                                        options={packageDetails&&packageDetails?.results||[]}
                                        disableCloseOnSelect
                                        getOptionLabel={(option: any) => option?.test_name}
                                        // onInputChange={(event, newInputValue) => {
                                        //     clearTimeout(timer.current);
                                        //     setPincode(newInputValue);
                                        //     if(newInputValue!=="")
                                        //     getPackageDetails(`?pincode=${newInputValue}`)
                                           
                                        // }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.test_name}
                                            </li>
                                        )}
                                        style={{ width: "100%", margin: "0" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Package"
                                            />
                                        )}
                                    />
                                </Grid>
                                {+inputList[0]?.customerAge < 5 && inputList[0]?.customerAge !== "" && <p style={{ textAlign: 'center', width: '100%', color: 'red' }}>Age cannot be less than 5 years.</p>}
                            </Grid>

                        </>
                    );
                })}
            </Grid>
        </Grid>
    )
}
export default BasicDetails