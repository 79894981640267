import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MobileHeaderPage from "./MobileHeader";
import DesktopHeaderPage from "./DesktopHeader";
import { connect } from "react-redux";
import { Autocomplete, Button, Grid, MenuItem, Paper, TextareaAutosize, TextField } from "@mui/material";
import { createNewUser } from '../../action/adminAction'
import './Index.sass';
const theme = createTheme();
const useStyles = makeStyles({
    root: {
        flexGrow: 0,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    paper: {
        marginTop: "1rem",
        padding: "1rem 0.5rem 0.5rem 0.5rem",
        width: "100%",
    },
    hoveItem: {
        cursor: "pointer",
    },
    input: {
        "& .MuiOutlinedInput-input": {
            color: "green",
            padding: "9px"
        },
    },
    AutoCompleteinput: {
        "& .MuiOutlinedInput-input": {
            color: "green",
            padding: "6px",
            marginTop: "1px"
        },
    },

})
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
interface Props {
    createNewUser: any;
}

const PhleboOnboardingDash: React.FC<Props> = ({
    createNewUser
}) => {
    const classes = useStyles();
    const [formShow, setFormShow] = useState<boolean>(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [fullname, setFullName] = useState<any>("")
    const [email, setEmail] = useState<any>("")
    const [userGroup, setUserGroup] = useState<any>("")
    const [mobile, setMobile] = useState<any>("")
    const [isActive, setIsActive] = useState<any>("")

    //Windows Size detection start
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    //Windows Size detection End
    //Submit Function
    const handleAggregatorSubmit = async (e: any) => {
        e.preventDefault()
        const body = {
            "fullname": fullname,
            "email": email,
            "password": "01pass1234",
            "password_change": false,
            "is_active": isActive,
            "usergroup": userGroup,
            "pilot_user": false,
            number:mobile,
        }
        console.log(body)
        await createNewUser(body)

    }
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />

            <Paper
                elevation={5}
                className={classes.paper}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                {windowSize.innerWidth && windowSize.innerWidth < 600 ? (
                    <MobileHeaderPage formShow={formShow} setFormShow={setFormShow} />
                ) : (
                    <DesktopHeaderPage formShow={formShow} setFormShow={setFormShow} />
                )}
                {formShow ? (
                    <>
                        <form onSubmit={handleAggregatorSubmit}>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                style={{ alignItems: "left" }}
                            >
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        className="input"
                                        name="title"
                                        type="text"
                                        label="Full Name"
                                        value={fullname}
                                        required
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setFullName(e.target.value)}
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        className="input"
                                        name="email"
                                        type="email"
                                        label="Email"
                                        value={email}
                                        required
                                        variant="outlined"
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        className="input"
                                        name="mobile"
                                        type="number"
                                        label="Mobile Number"
                                        value={mobile}
                                        required
                                        variant="outlined"
                                        onChange={(e: any) => setMobile(e.target.value)}
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        className={classes.input}
                                        name="usergroup"
                                        type="text"
                                        select
                                        label="User Group"
                                        value={userGroup}
                                        required
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setUserGroup(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="ADMIN">Admin</MenuItem>
                                        <MenuItem value="CE">Customer Executive</MenuItem>
                                        <MenuItem value="PHA">Phlebo Admin</MenuItem>
                                        <MenuItem value="RM">Route Manager</MenuItem>
                                        <MenuItem value="PH">Phlebos</MenuItem>
                                        <MenuItem value="DR">Doctors</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        className={classes.input}
                                        name="isactive"
                                        type="text"
                                        select
                                        label="Is Active"
                                        value={isActive}
                                        required
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setIsActive(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        <MenuItem value="true">Active</MenuItem>
                                        <MenuItem value="false">Deactive</MenuItem>
                                    </TextField>
                                </Grid>
                                {/* <Grid item xs={12} sm={6} md={3}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        className="input"
                                        name="aggregatorNumber"
                                        type="text"
                                        label="Aggregator Number"
                                        value={aggregatiorMobileNumber}
                                        required
                                        variant="outlined"
                                        onChange={(e) => setAggregatorMobileNumber(e.target.value)}
                                        style={{ width: "100%" }}
                                    />
                                    </Grid>*/}
                                {/* <Grid item xs={12} sm={6} md={3}>
                                    <Autocomplete
                                        id="identityproof"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let obj = JSON.parse(
                                                    JSON.stringify(newValue, null, " ")
                                                );
                                                setAggregatorType(obj?.value);
                                            }
                                        }}
                                        defaultValue={
                                            AggregatorTypeData &&
                                            AggregatorTypeData.find((code: any) => {
                                                return code?.id === Number(aggregatiorType);
                                            })
                                        }
                                        options={AggregatorTypeData || []}
                                        freeSolo
                                        blurOnSelect
                                        getOptionLabel={(option: any) => `${option?.text}`}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Aggregator Type"
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                                className={classes.AutoCompleteinput}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>  */}

                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                style={{ alignItems: "left", marginTop: "0px" }}
                            >
                                <Grid item xs={12} sm={6} md={3}>
                                    <Button type="submit" fullWidth variant="contained" color="success">
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Button fullWidth variant="contained" color="error">
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </>
                ) : ""}
            </Paper>

        </main>
    )
}
const mapStateToProps = (state: any) => ({

});
export default connect(mapStateToProps, {
    createNewUser
})(PhleboOnboardingDash);