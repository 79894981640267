import {
  combineReducers
} from 'redux';

import modalReducer from './modalReducer';
import secondModalReducer from './secondModalReducer';
import snackBarReducer from './snackBarReducer';
import loginReducer from './loginReducer';
import AdminReducer from '../Admin/reducers/adminReducer'
import PhleboAdminReducer from '../PhleboAdmin/reducers/phleboAdminReducer'
import RouteManagerReducer from '../RouteManager/reducers/RouteManagerReducer'
import CustomerExecutiveReducer from '../CustomerExecutive/reducers/CustomerexecutiveReducer'
import PhleboReducer from '../Phlebo/reducers/phleboReducer';

// import notificationReducer from './notificationReducer';

const rootReducer = combineReducers({
  modalReducer,
  secondModalReducer,
  snackBarReducer,
  loginReducer,
  AdminReducer,
  PhleboAdminReducer,
  CustomerExecutiveReducer,
  RouteManagerReducer,
  PhleboReducer
});

export default rootReducer;