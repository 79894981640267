import { ACTIONS } from "../interfaces/phleboAdminType";
import AXIOS from "../../config/Axios";
import Prefix from "../../config/ApiPrefix";
// import SecureStorage from "../../config/SecureStorage";
import snackBarUpdate from "../../actions/snackBarActions";
import SecureStorage from "../../config/SecureStorage";

export const GetMobileOtp =(body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_OTP_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/karza/mobile/otp`,body);
      dispatch({
        type: ACTIONS.GET_MOBILE_OTP,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message:data['status-code']==="104"?"Max Retries Exceeded": "Otp has been sent to your mobile number",
          status: true,
          type: data['status-code']==="104"?"error":"success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.error);
      } else {
        title = "Some unexpected error occurred please refresh the page!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Some unexpected error occurred please refresh the page",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_OTP_LOADING, payload: false });
      throw err;
    }
  };
  export const validateOtpInput =(body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_VALIDATE_OTP_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/karza/mobile/status`,body);
      dispatch({
        type: ACTIONS.POST_VALIDATE_OTP_INPUT,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "OTP Validate Successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.error);
      } else {
        title = "Some unexpected error occurred please refresh the page!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Some unexpected error occurred please refresh the page",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_VALIDATE_OTP_LOADING, payload: false });
      throw err;
    }
  };
  export const PostMobileDetails =(body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_MOBILE_DETAILS_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/karza/mobile/details`,body);
      dispatch({
        type: ACTIONS.POST_MOBILE_DETAILS_DATA,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Mobile Details fetch successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.error);
      } else {
        title = "Some unexpected error occurred please refresh the page!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Some unexpected error occurred please refresh the page",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_MOBILE_DETAILS_LOADING, payload: false });
      throw err;
    }
  };
  export const PostEmailAuthentication =(body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_EMAIL_AUTH_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/karza/email/email-fraud`,body);
      dispatch({
        type: ACTIONS.POST_EMAIL_AUTHENTICATION_DATA,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Email Details fetch successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.error);
      } else {
        title = "Some unexpected error occurred please refresh the page!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Some unexpected error occurred please refresh the page",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_EMAIL_AUTH_LOADING, payload: false });
      throw err;
    }
  };
  export const PostBankAuthentication =(body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_BANK_AUTH_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/karza/bankacc-verification`,body);
      dispatch({
        type: ACTIONS.POST_BANK_AUTHENTICATION_DATA,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Bank Transection successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.error);
      } else {
        title = "Some unexpected error occurred please refresh the page!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Some unexpected error occurred please refresh the page",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_BANK_AUTH_LOADING, payload: false });
      throw err;
    }
  };

  export const PostPANAuthentication =(body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_PAN_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/karza/pan`,body);
      dispatch({
        type: ACTIONS.POST_PAN_AUTHENTICATION_DATA,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "PAN Verified successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.error);
      } else {
        title = "Some unexpected error occurred please refresh the page!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Some unexpected error occurred please refresh the page",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_PAN_LOADING, payload: false });
      throw err;
    }
  };
  
  export const PostPANLinkWithAadhaarAuthentication =(body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_PAN_LINK_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/karza/pan-link`,body);
      dispatch({
        type: ACTIONS.POST_PAN_LINK_WITH_AADHAAR_AUTHENTICATION_DATA,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "PAN Verified successfully!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.error);
      } else {
        title = "Some unexpected error occurred please refresh the page!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Some unexpected error occurred please refresh the page",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_PAN_LINK_LOADING, payload: false });
      throw err;
    }
  };
  
  export const validateFaceMatching =(body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_FACE_MATCHING_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/karza/facesimilarity`,body);
      dispatch({
        type: ACTIONS.VALIDATE_FACE_MATCHING,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message:data?.statusCode===102?"Face image is not cleared please try again": "face Verification result fetched!",
          status: true,
          type: data?.statusCode===102?"error":"success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.error);
      } else {
        title = "Some unexpected error occurred please refresh the page!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Some unexpected error occurred please refresh the page",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_FACE_MATCHING_LOADING, payload: false });
      throw err;
    }
    
  };
  
  export const validateOcrKyc =(body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_OCR_KYC_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/karza/ocr/kyc`,body);
      dispatch({
        type: ACTIONS.VALIDATE_OCR_KYC,
        payload: data,
      });
      console.log(data,"data")
      snackBarUpdate({
        payload: {
          message:data?.statusCode===102?"Face image is not creared please try again!!!": "face Verification result fetched!!!",
          status: true,
          type: data?.statusCode===102?"error":"success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = String(err.response.data.error);
      } else {
        title = "Some unexpected error occurred please refresh the page!";
      }
  
      snackBarUpdate({
        payload: {
          message: title || "Some unexpected error occurred please refresh the page",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_OCR_KYC_LOADING, payload: false });
      throw err;
    }
  };

  export const getUserList = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/users`,
  
        { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_USER_LIST, payload: data });
    } catch (err: any) {
      console.log(err)
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const createNewUser = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(`${Prefix.api}/users/create`,
            body,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        snackBarUpdate({
          payload: {
              message: "User Created Successfully!",
              status: true,
              type: "success",
          },
      })(dispatch);

        dispatch({ type: ACTIONS.CREATE_NEW_USER, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = String(err.response.data.message);
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const updateUserProfile =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/users/update/${id}`,
        body,
        {
          headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` },
        }
      );

      dispatch({
        type: ACTIONS.UPDATE_USER_PROFILE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "User Profile Updated successfully!!!",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

    
  export const getCampDetails = (filter: string,url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `/camp${filter?filter:""}`,
  
        { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_CAMP_DETAILS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.error;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  
export const createNewCustomer = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
      const { data } = await AXIOS.post(`/camp/save-camp-data`,
          body,
          { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
        payload: {
            message: "Customer Created Successfully!",
            status: true,
            type: "success",
        },
    })(dispatch);

      dispatch({ type: ACTIONS.CREATE_NEW_CUSTOMER, payload: data });
  } catch (err: any) {
      let title = "";
      if (err.response) {
          title = String(String(err.response.data.error));
      } else {
          title = "Something went wrong!";
      }
      snackBarUpdate({
          payload: {
              message: title || "Something went wrong!",
              status: true,
              type: "error",
          },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
  }
};


export const getCustomerDetails = (campId: any,filter:any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `/camp/camp-patients/${campId}${filter?filter:''}`,
      { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CUSTOMER_DETAILS, payload: data });
  } catch (err: any) {
    console.log(err)
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title|| "Data Not Found",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getCustomerDetailsById = (url: string,filter:any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `/camp/camp-patients${url?url:''}${filter?filter:''}`,

      { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CUSTOMER_DETAILS, payload: data });
  } catch (err: any) {
    console.log(err)
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const updateCustomerDetails = (body: any,url: string,filter:any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
      const { data } = await AXIOS.patch(`/camp/update-camp-patients${url?url:''}${filter?filter:''}`,
          body,
          { headers: { Authorization: `Bearer ${SecureStorage.getItem("token")}` } }
      );
      snackBarUpdate({
        payload: {
            message: "Customer Updated Successfully!",
            status: true,
            type: "success",
        },
    })(dispatch);

      dispatch({ type: ACTIONS.UPDATE_CUSTOMER_DETAILS, payload: data });
  } catch (err: any) {
      let title = "";
      if (err.response) {
          title = String(err.response.data.error);
      } else {
          title = "Something went wrong!";
      }
      snackBarUpdate({
          payload: {
              message: title || "Something went wrong!",
              status: true,
              type: "error",
          },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
  }
};