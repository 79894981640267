import { ACTIONS, ValidationDashboarType } from "../interfaces/adminType";

type InitialState = {
  loading: boolean;
  userList: Array<any>
  timeSlots: Array<any>
  packageDetails: Array<any>
  bookingList: Array<any>
  activePincodeList: Array<any>
  leadDetails: Array<any>
  timeSlotsList: Array<any>
  createDBooking: Array<any>
  phleboList: Array<any>
  pincodeList: Array<any>
  updateDBooking: Array<any>
  discountPrice: Array<any>
  comments:Array<any>
  createDComment:Array<any>
};

const initialState: InitialState = {
  loading: false,
  userList: [],
  timeSlots: [],
  packageDetails: [],
  bookingList: [],
  activePincodeList: [],
  leadDetails: [],
  timeSlotsList: [],
  createDBooking: [],
  phleboList: [],
  pincodeList: [],
  updateDBooking: [],
  discountPrice: [],
  comments:[],
createDComment:[],
};

const AdminReducer = (state = initialState, action: ValidationDashboarType) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.GET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_TIMESLOTS:
      return {
        ...state,
        timeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_BOOKING:
      return {
        ...state,
        bookingList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_PINCODE:
      return {
        ...state,
        pincodeList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ACTIVE_PINCODE:
      return {
        ...state,
        activePincodeList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        leadDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_TIMESLOTS:
      return {
        ...state,
        timeSlotsList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createDBooking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_BOOKING:
      return {
        ...state,
        updateDBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISCOUNT_PRICE:
      return {
        ...state,
        discountPrice: action.payload,
        loading: false,
      };
      case ACTIONS.GET_COMMENTS:
        return {
          ...state,
          comments: action.payload,
          loading: false,
        };
        case ACTIONS.CREATE_COMMENTS:
          return {
            ...state,
            createDComment: action.payload,
            loading: false,
          };
    default:
      return state;
  }
};

export default AdminReducer;