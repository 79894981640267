import React from 'react'
import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createTheme } from '@mui/material/styles';
import moment from 'moment';
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    paper: {
        marginTop: "2rem",
        width: "100%",
    },
    tableHeadCell: {
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
    },
    table: {
        maxWidth: "100%",
    },
    tableHead: {
        background: "rgb(146, 74, 145)!important",
    },
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0A1929",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
interface Props {
    leadDetails:any
}

const LeadsDetailsTable: React.FC<Props> = ({
    leadDetails
}) => {
    const classes = useStyles();
    return (
        <div style={{ width: "100%" }} className="data-table">
            <TableContainer className={classes.tableContainer}
                component={Paper}
                style={{ maxHeight: "550px" }}
            >
                <Table stickyHeader aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                        <StyledTableRow>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Lead Id</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Name</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Email</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>City</StyledTableCell>
                            <StyledTableCell align="center" className={classes.tableHeadCell}>Mobile Number</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {leadDetails&&leadDetails?.results&&leadDetails?.results.length>0&&leadDetails?.results.map((data:any,index:any)=>{
                            return(
                                <StyledTableRow key={index}>
                                <StyledTableCell align="center">{data?.lead}</StyledTableCell>
                                <StyledTableCell align="center">{data?.patient_name}</StyledTableCell>
                                <StyledTableCell align="center">{data?.email}</StyledTableCell>
                                <StyledTableCell align="center">{data?.patient_city||"NA"}</StyledTableCell>
                                <StyledTableCell align="center">{data?.mobile}</StyledTableCell>

                                </StyledTableRow>
                            )
                        })}
                  
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default LeadsDetailsTable