import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Grid, TextField, Button } from "@mui/material";
import BookingTable from "./BookingTable";
import { getAllBooking, updateBooking, getBookingComments, createComments } from '../../action/adminAction'
import { genrateAdminFilter } from '../../../helpers/generateUrl';
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
})
interface Props {
    getAllBooking:any;
    bookingList:any;
    updateBooking:any;
    getBookingComments:any;
    comments:any;
    createComments:any;
}
const BookingFilter: React.FC<Props> = ({
    getAllBooking,
    bookingList,
    updateBooking,
    getBookingComments,
    comments,
    createComments
}) => {
    const classes = useStyles();
    const [inputList, setInputList] = useState<any>([
        {
            bookingId: "",
            customerName: "",
            contactNumber: "",
            bookingDate: "",
            collectionDate: "",
            startTime: "",
            endTime: ""
        },
    ]);

    useEffect(() => {
        getAllBooking()
    }, [])

    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            //  filterBookings(e);
        }
    };
    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    }

    const handleSubmit = async (e: any) => {
        const body = {
            booking_id: inputList[0].bookingId,
            full_name: inputList[0].customerName,
            phone: inputList[0].contactNumber,
            booking_date: inputList[0].bookingDate,
            start_time: inputList[0].startTime,
            end_time: inputList[0].endTime,
            collectionDate: inputList[0].collection_date,
        }
        const url = genrateAdminFilter(body).substring(2);
        getAllBooking(url ? `?${url}` : "")
    }

    const handle = (e: any, index: any) => {
        handleInputChange(e, index)
        handleSubmit(e)
    }

    const handleReset = () => {
        const resertData = { bookingId: "", customerName: "", contactNumber: "", bookingDate: "", startTime: "", endTime: "", collectionDate: "" }
        setInputList([{ bookingId: "", customerName: "", contactNumber: "", bookingDate: "", startTime: "",endTime: "", contactNuendTimember: "", collectionDate: "" }])
        getAllBooking()
    }
    
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            {inputList.map((x: any, index: number) => {
                return (
                    <Grid container direction="row" spacing={2} >
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                label="Booking Id"
                                name="bookingId"
                                id="outlined-start-adornment"
                                value={x.bookingId || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    const inputVal = e.target.value;
                                    (inputVal.replace(/[^0-9]/g, ''))
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                label="Name"
                                name="customerName"
                                value={x.customerName || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handle(e, index)}
                                id="outlined-start-adornment"
                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                label="Phone"
                                id="outlined-start-adornment"
                                name="contactNumber"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={x.contactNumber || ""}
                                onChange={(e) => handle(e, index)}
                                type="number"
                                onInput={(e: any) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                }}
                                inputProps={{ min: 1, max: 9999999999 }}

                                style={{ width: '100%', margin: "0px", padding: "5px" }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                name="bookingDate"
                                type="date"
                                id="noTyping"
                                value={x.startTime === "" || x.endTime === "" ? x.bookingDate : ""}
                                InputLabelProps={{ shrink: true }}
                                label="Booking Date"
                                variant="outlined"
                                // onKeyDown={onKeyDown}
                                disabled={x.startTime !== "" || x.startTime !== ""}
                                onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    e.target.value = String(e.target.value).length <= 10 ? e.target.value as string : ""
                                }}
                                style={{ width: "100%", margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                name="startTime"
                                id="noTyping"
                                type="date"
                                label="Start Date"
                                InputLabelProps={{ shrink: true }}
                                value={
                                    x.bookingDate === "" || x.collectionDate === ""
                                        ? x.startTime
                                        : ""
                                }
                                variant="outlined"
                                disabled={x.bookingDate !== "" || x.collectionDate !== ""}
                                onKeyDown={onKeyDown}
                                onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    e.target.value = String(e.target.value).length <= 10 ? e.target.value as string : ""
                                }}
                                style={{ width: "100%", margin: "0px", padding: "5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                className="input"
                                name="endTime"
                                type="date"
                                id="noTyping"
                                label="End Date"
                                InputLabelProps={{ shrink: true }}
                                value={
                                    x.bookingDate === "" || x.collectionDate === ""
                                        ? x.endTime
                                        : ""
                                }
                                variant="outlined"
                                disabled={x.bookingDate !== "" || x.collectionDate !== ""}
                                onKeyDown={onKeyDown}
                                onChange={(e) => handle(e, index)}
                                onInput={(e: any) => {
                                    e.target.value = String(e.target.value).length <= 10 ? e.target.value as string : ""
                                }}
                                style={{ width: "100%" , margin: "0px", padding: "5px"}}
                            />
                        </Grid>
                    </Grid>
                )
            })}
            <Grid container direction="row" spacing={2} justifyContent="center" marginTop={1}>
                {/* <Grid item xs={12} sm={12} md={6}>
                    <Button
                        color="success"
                        style={{ width: '100%', margin: "0px", padding: "5px" }}
                        variant="contained"
                        onClick={(e: any) => handleSubmit(e)}
                    >
                        Filter
                    </Button>
                </Grid> */}
                <Grid item xs={12} sm={12} md={6}>
                    <Button
                        color="error"
                        style={{ width: '100%', margin: "0px", padding: "5px" }}
                        variant="contained"
                        onClick={handleReset}
                    >
                        RESET
                    </Button>
                </Grid>
            </Grid>
            <BookingTable
                bookingList={bookingList}
                updateBooking={updateBooking}
                comments={comments}
                getBookingComments={getBookingComments}
                createComments={createComments}
            />
        </main>
    )
}
const mapStateToProps = (state: any) => ({
    bookingList: state.AdminReducer.bookingList,
    comments: state.AdminReducer.comments
});
export default connect(mapStateToProps, {
    getAllBooking,
    updateBooking,
    getBookingComments,
    createComments
})(BookingFilter);